import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpCommonService } from './http-common.service';
import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { EventsView, PublicMeetingInfo } from '@app/models';
import { environment } from '@environments/environment';

export interface MediaResource {
    isVideoFile: boolean;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class ProjectEventService {
    constructor(
        private http: HttpClient,
        private httpCommonService: HttpCommonService
    ) {}

    private results: string;
    private projectEvent: EventsView;

    getProjectEventAsObservable(pm: PublicMeetingInfo): Observable<EventsView> {
        const url = `${endpoints.API_METHODS.PROJECT_EVENT_GET}`;
        const options = {
            params: this.httpCommonService.toHttpParams(pm),
            headers: this.httpCommonService.httpOptions.headers
        };
        return this.http.get<EventsView>(url, options).pipe(
            map(res => res),
            tap(results => {
                if (!results) {
                    catchError(this.httpCommonService.handleError<EventsView>('ProjectEventService: getProjectEventAsObservable returned nothing'));
                }
                // setup media path
                const resource = this.getResourceUrl(results, pm);
                if (resource.isVideoFile) {
                    results.VIDEO_PATH = resource.url;
                } else {
                    results.ONLINE_RESOURCE = resource.url;
                }
                console.log('Fetched Project Event');
                if (environment.isLocalHost){
                    console.log('Project Event ID, aka Online Meeting Id:', results.PE_ID);
                }
                return results;
            }),
            catchError(this.httpCommonService.handleError<EventsView>('ProjectEventService: getProjectEventAsObservable'))
        );
    }

   getPresentation(params: any): Promise <any> {
        const options = {
            headers: this.httpCommonService.httpOptions.headers,
            params: this.httpCommonService.toHttpParams(params)
        };
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.PROJECT_EVENT_GET;
            this.http.get(url, options)
                .toPromise().then(res => {
                    if (res && (res as any[]).length > 1) {
                        reject('Not Valid Event');
                    } else {
                        // Save Event Info
                        this.projectEvent = res[0];

                        if (this.projectEvent.PE_S3_PATH) {
                        // Get Video Path
                        let videoID = res[0].PE_S3_PATH;

                        if (videoID.indexOf('/') !== -1) {
                            videoID = videoID.substr(videoID.indexOf('/') + 1, videoID.length - (videoID.indexOf('/') + 1));
                        }

                        this.results = `${endpoints.MEDIA_URL}/${videoID}`;
                        } else if (this.projectEvent.ONLINE_RESOURCE) {
                            this.results = res[0].ONLINE_RESOURCE;
                        } else {
                            reject('API call failed. Video Not Found.');
                        }
                        resolve(this.results);
                    }
                }).catch(error => {
                    console.log(error);
                    reject('API call failed. Video Not Found.');
                });
          });
          return promise;
    }

    getResourceUrl(response: EventsView, params): MediaResource {
        if (!response) {
            throw new Error('Not Valid Event');
        } else {
            // Save Event Info
            this.projectEvent = response;

            const results: MediaResource = {
                isVideoFile: false,
                url: null
            };

            if (this.projectEvent.PE_S3_PATH) {
                // Get Video Path from hosted in S3
                let videoID = this.projectEvent.PE_S3_PATH;
                if (videoID.indexOf('/') !== -1) {
                    videoID = videoID.substr(videoID.indexOf('/') + 1, videoID.length - (videoID.indexOf('/') + 1));
                }
                results.url = `${endpoints.MEDIA_URL}/${videoID}`;
                results.isVideoFile = true;
            } else if (this.projectEvent.ONLINE_RESOURCE) {
                // reference 3rd party url (i.e. YouTube)
               results.url = this.projectEvent.ONLINE_RESOURCE;
            } else {
                throw new Error('API call failed. Video Not Found.');
            }
            return results;
        }
    }


}
