import { Component, ViewChild, AfterViewInit, Input} from '@angular/core';
import { MatSlider } from '@angular/material/slider';

import { StakeholderInput, MeetingDetails, StakeholderComment} from '@app/models';
import { StakeholderService } from '@app/services';

@Component({
    selector: 'tab-content--leave-a-comment',
    templateUrl: './leave-a-comment.component.html',
    styleUrls: ['./leave-a-comment.component.scss'],
    providers: [StakeholderService],
})
export class LeaveACommentComponent implements AfterViewInit {
    constructor(private stakeholderService: StakeholderService) {}
    @Input() selections: StakeholderInput;
    @Input() meetingDetails: MeetingDetails;
    step = 0;
    mapX?: number = null;
    mapY?: number = null;
    submitMessage = '';
    submitStyle = '';

    @ViewChild('ratingSlider') ratingSlider: MatSlider;


    handleYChanged(num) {
        this.mapY = num;
    }

    handleXChanged(num) {
       this.mapX = num;
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        switch (this.step) {
            case 3: {
                // Must put before since step 2 goes to step 3.
                this.step = 0;
                this.selections.comment = '';
                this.selections.ratingsValue = -3;
                this.ratingSlider.value = -3;
                this.selections.responseType = 4;
                this.selections.topics.forEach(function (x) {
                    x.IS_CHECKED = false;
                });
                break;
            }
            case 2: {
                this.submitMessage = 'Submitting...';
                this.submitStyle = 'green';
                const online = this.meetingDetails.sources.filter(x => x.value.toLocaleLowerCase() === 'online')[0];
                const cat = this.meetingDetails.categories[0]; // .filter(x => x.value.toLocaleLowerCase() === 'iowa dot projects')[0];
                const parms = {stake_id: this.meetingDetails.stakeholder.STAKE_ID, person_id: null};
                const topics = this.selections.topics.filter(x => x.IS_CHECKED);
                const submission: StakeholderComment = new StakeholderComment();

                submission.STAKE_COMMENT = this.selections.comment; // done
                submission.COMMENT_SOURCE_ID = online.key; // done
                submission.STAKEHOLDER_SUPPORT_ID = this.ratingSlider.value * -1; // done

                if (this.selections.responseType && this.selections.responseType != null) {
                    submission.RESPONSE_REQUEST_TYPE_ID = this.selections.responseType;
                    submission.IS_RESPONSE_NEEDS_APPROVAL = submission.RESPONSE_REQUEST_TYPE_ID > 0 ? 1 : 0;
                } else {
                    submission.RESPONSE_REQUEST_TYPE_ID = 4;
                    submission.IS_RESPONSE_NEEDS_APPROVAL = 0;
                }

                submission.STAKE_ID = this.meetingDetails.stakeholder.STAKE_ID; // done
                submission.COMMENT_LAT = this.mapY; // done
                submission.COMMENT_LON = this.mapX; // done
                submission.COMMENT_CATEGORY_ID = cat.key; // done
                submission.PROJECT_ID = this.meetingDetails.eventDetails.PROJECT_ID; // done

                if (this.meetingDetails.eventDetails.PE_ID != null) {
                    submission.PE_ID = this.meetingDetails.eventDetails.PE_ID; // done
                } else {
                    console.log('PE_ID is undefined.');
                    this.submitMessage = 'Submit Failed: Project Event ID is undefined. Please refresh the page and try again.';
                    this.submitStyle = 'red';
                    return;
                }

                submission.CommentTopics = topics.map(a => a.COMMENT_TOPIC_ID); // done

                this.stakeholderService.postComment(submission, parms).then(acpt => {
                    this.step++;
                    this.submitMessage = '';
                    this.submitStyle = 'blue';
                }, rejected => {
                    console.log(rejected);
                    this.submitMessage = 'Submit Failed: ' + rejected.message;
                    this.submitStyle = 'red';
                });
                break;
            }
            case 0: {
                if (this.selections.comment == null || this.selections.comment === '') {
                    this.submitMessage = 'Comment is too short. Please enter a comment.';
                    this.submitStyle = 'red';
                } else if (this.selections.comment && this.selections.comment != null && this.selections.comment.length > 4000) {
                    this.submitMessage = 'Comment is too long. Please keep your comment under 4000 characters. Your current comment is ' + this.selections.comment.length + ' characters long.';
                    this.submitStyle = 'red';
                } else {
                    this.submitMessage = '';
                    this.submitStyle = 'blue';
                    this.step++;
                }
                break;
            }
            default: {
                this.step++;
                break;
            }
        }
    }

    prevStep() {
        this.step--;
        this.submitMessage = '';
    }

    ngAfterViewInit() {
        const n = this.ratingSlider._elementRef.nativeElement;
        const labelTextEl = n.getElementsByClassName('mat-slider-thumb-label-text')[0];
        const setRatingText = () => {
            const a = this.selections.ratings.filter(x => x.key === this.ratingSlider.value)[0];
            labelTextEl.innerHTML = a.value;
        };
        setRatingText();
        this.ratingSlider.input.subscribe(() => setRatingText());
    }

    maximizeMap() {
        document.getElementById('minimize-map-btn').style.display = 'block';
        const el: any = document.querySelector('.image-placeholder');
        el.style.position = 'absolute';
        el.style.top = '10vh';
        el.style.bottom = '10vh';
        el.style.right = '10vw';
        el.style.left = '10vw';
    }
}
