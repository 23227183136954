import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { MaterialModule } from './submodules/material.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { reducers, metaReducers } from './state';
import { reducer } from './state/app.reducers';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { EsriMapComponent } from './map/esri-map.component';
import { LeaveACommentComponent } from './shared/leave-a-comment/leave-a-comment.component';
import { MeetingInfoComponent } from './shared/meeting-info/meeting-info.component';
import { BasicDialogComponent } from './dialogs/basic-dialog/basic-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { QuestionsComponent } from './questions/questions.component';
import { PublicCommentComponent } from './questions/public-comment/public-comment.component';
import { PublicMapComponent } from './questions/public-map/public-map.component';
import { PublicChoicesComponent } from './questions/public-choices/public-choices.component';
import { PublicTopicsComponent } from './questions/public-topics/public-topics.component';
import { PublicCaptchaComponent } from './questions/public-captcha/public-captcha.component';
import { PublicResponseComponent } from './questions/public-response/public-response.component';
import { PublicSliderComponent } from './questions/public-slider/public-slider.component';
import { PublicBaseComponent } from './questions/public-base/public-base.component';
import { CommentSubmissionComponent } from './questions/comment-submission/comment-submission.component';
import { CommentValidationService } from './services/comment-validation.service';
import { ChangeLogComponent } from './dialogs/change-log/change-log.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ModeratorViewComponent } from './online-hearings/moderator/moderator-view/moderator-view.component';
import { StakeholderViewComponent } from './online-hearings/stakeholder/stakeholder-view/stakeholder-view.component';
import { IndexComponent } from './index/index.component';
import { EmbedComponent } from './shared/embed/embed.component';
import { PodiumComponent } from './online-hearings/stakeholder/podium/podium.component';
import { PodiumDialogComponent } from './dialogs/podium-dialog/podium-dialog.component';
import { LeftPaneComponent } from './online-hearings/moderator/left-pane/left-pane.component';
import { RightPaneComponent } from './online-hearings/moderator/right-pane/right-pane.component';
import { PodiumCommentFeedComponent } from './shared/podium-comment-feed/podium-comment-feed.component';
import { SecondsToMinutesPipe } from './pipes/seconds-to-minutes.pipe';
import { PageUnauthorizedComponent } from './guards/page-unauthorized.component';
import { AuthGuard } from './guards/auth.guard';
import { ModeratorMsgComponent } from './online-hearings/stakeholder/moderator-msg/moderator-msg.component';
import { LoginComponent } from './shared/login/login.component';
import { CommentItemComponent } from './shared/podium-comment-feed/comment-item/comment-item.component';
import { HelpDialogComponent } from './dialogs/help-dialog/help-dialog.component';
import { HelpComponent } from './shared/help/help.component';
import { ConnectionStatusComponent } from './shared/connection-status/connection-status.component';
import { DisconnectModeratorDialogComponent } from './dialogs/disconnect-moderator-dialog/disconnect-moderator-dialog.component';
import { DisconnectedDialogComponent } from './dialogs/disconnected-dialog/disconnected-dialog.component';
import { DisconnectNetworkStatusDialogComponent } from './dialogs/disconnect-network-status-dialog/disconnect-network-status-dialog.component';
import { AnonymousStakeholderComponent } from './shared/anonymous-stakeholder/anonymous-stakeholder.component';
import { PollComponent } from './online-hearings/stakeholder/poll/poll.component';
import { PollControlComponent } from './online-hearings/moderator/poll-control/poll-control.component';
import { DocumentsComponent } from './shared/meeting-info/documents/documents.component';
import { LanguageSelectorComponent } from './shared/language-selector/language-selector.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    LeaveACommentComponent,
    MeetingInfoComponent,
    EsriMapComponent,
    BasicDialogComponent,
    ErrorDialogComponent,
    QuestionsComponent,
    PublicCommentComponent,
    PublicMapComponent,
    PublicChoicesComponent,
    PublicTopicsComponent,
    PublicCaptchaComponent,
    PublicResponseComponent,
    PublicSliderComponent,
    PublicBaseComponent,
    CommentSubmissionComponent,
    ChangeLogComponent,
    SafeHtmlPipe,
    ModeratorViewComponent,
    StakeholderViewComponent,
    IndexComponent,
    EmbedComponent,
    PodiumComponent,
    PodiumDialogComponent,
    LeftPaneComponent,
    RightPaneComponent,
    PodiumCommentFeedComponent,
    SecondsToMinutesPipe,
    PageUnauthorizedComponent,
    ModeratorMsgComponent,
    LoginComponent,
    CommentItemComponent,
    HelpDialogComponent,
    HelpComponent,
    ConnectionStatusComponent,
    DisconnectModeratorDialogComponent,
    DisconnectNetworkStatusDialogComponent,
    DisconnectedDialogComponent,
    AnonymousStakeholderComponent,
    PollComponent,
    PollControlComponent,
    DocumentsComponent,
    LanguageSelectorComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    StoreModule.forRoot(
      //reducers, {metaReducers}
      {home:reducer}
      ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  providers: [
    Title,
    CommentValidationService,
    SecondsToMinutesPipe,
    AuthGuard,
    // useValue aka GTM ID is set from AppSettings Service
    // must provide an id, even though it gets switched out
    {provide: 'googleTagManagerId', useValue: 'GTM-999999'}
],
  bootstrap: [AppComponent],
  entryComponents: [
    ErrorDialogComponent,
    BasicDialogComponent,
    ChangeLogComponent,
    PodiumDialogComponent
  ]
})
export class AppModule {}
