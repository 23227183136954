class Release {
    upcoming?: boolean;
    version: string;
    date: string;
    completed: string[];
  }

  export const CHANGELOG: Release[] = [
    {
      upcoming:  false,
      version: '1.99',
      date: '4/6/2020',
      completed: [
        'Mobile Styling',
        'Dynamic Questions Loaded from Meeting Setup'
      ]
    }
];
