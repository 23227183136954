import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { PollQuiz } from '@app/models/poll-quiz';
import { DomSanitizerService } from '@app/services/dom-sanitizer.service';

import {
  NTRC_SLIDO_POLLS3,
  NTRC_SLIDO_07_15_2021
 } from '@app/models/mock-poll-quiz';

@Injectable({
  providedIn: 'root'
})
export class PollQuizService {

  constructor(
    private domSanitizerService: DomSanitizerService
  ) {}

  private _slidoPolls = new BehaviorSubject<PollQuiz[]>(null);
  private _toggledPollVisible = new BehaviorSubject<boolean>(false);
  private visiblePoll: PollQuiz = null;

  slidoPolls$ = this._slidoPolls.asObservable();
  toggledPollVisible$ = this._toggledPollVisible.asObservable();

  getSlidoPolls(): Observable<PollQuiz[]>{
    // NOTE - this will need to be switched to the dated polls above and published based on certain meeting dates
    const currentPolls = NTRC_SLIDO_07_15_2021;
    const safePolls = this._sanitizeUrls(currentPolls);
    const visPollSet = this.setCurrentVisiblePoll(safePolls);
    const polls = of(visPollSet);
    this._slidoPolls.next(visPollSet);
    return polls;
  }

  setVisibleSlidoPoll(poll: PollQuiz): void{
    const polls = this._slidoPolls.value;
    // if this check fails, the default visible gets used from results of getSlidoPolls()
    if (poll && poll.hash){
      this.visiblePoll = poll;
    }
    if (polls && this.visiblePoll){
      const safePolls = this._sanitizeUrls(polls);
      if (safePolls){
        safePolls.forEach(p =>{
          if (p.hash === poll.hash){
            p.visible = true;
          } else{
            p.visible = false;
          }
        });
        this._slidoPolls.next(safePolls);
      }
    }
  }

  setTogglePollVisibility(visible: boolean): void{
    this._toggledPollVisible.next(visible);
  }

  private setCurrentVisiblePoll(polls: PollQuiz[]){
    if (this.visiblePoll){
      return polls.map(p =>{
        if (p.hash === this.visiblePoll.hash){
          p.visible = true;
        } else{
          p.visible = false;
        }
        return p;
      })
    } else {
      return polls;
    }
  }


  private _sanitizeUrls(polls: PollQuiz[]): PollQuiz[]{
    const safePolls:PollQuiz[] = [];
    polls.forEach(p =>{
      p.safeUrl = this.domSanitizerService.getSafeUrl(p.embedUrl);
      safePolls.push(p);
    });
    return safePolls;
  }


}
