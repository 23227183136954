import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription} from 'rxjs';
import { ValidatorFn, FormArray, FormControl, FormGroup } from '@angular/forms';

import { PublicQuestion } from '@app/models';

@Component({
  selector: 'app-public-base',
  templateUrl: './public-base.component.html',
  styleUrls: ['./public-base.component.scss'],
})
export class PublicBaseComponent implements OnInit, OnDestroy {
  constructor() {}

  @Input() question: PublicQuestion;
  @Input() questionIndex = 0; // default
  @Input() showQuestionNumber = true; // defaults to showing question numbering

  errorMessage = '';
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectionCount = 0;
  maxSelection = 0;
  subs: Subscription[] = [];

  // validation errors
  readonly errorRequired = { required: true };
  readonly errorMaxSelection = { maxSelection: true };

  ngOnInit(): void {
  }

  // individual question components will set comments
  setStakeholderComment() {}

  // individual question components will run their own validation
  validateInputs(): void {}

  // individual question components will reset themselves
  resetSubmission(): void {}

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  minSelectedCheckboxes(min: number) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      const result = totalSelected >= min ? null : this.errorRequired;
      return result;
    };
    return validator;
  }

  maxSelectedCheckboxes(max: number) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is greater than the max, return the error message
      const result = totalSelected > max ? this.errorMaxSelection :  null;
      return result;
    };
    return validator;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  checkBoxChange(event) {
    if (event.checked) {
      this.selectionCount++;
    }
    if (!event.checked) {
      this.selectionCount--;
    }
    if (event.checked && this.selectionCount > this.maxSelection) {
      event.source.checked = false;
      this.selectionCount--;
    }
  }

}
