import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { PublicBaseComponent } from '../public-base/public-base.component';
import { ProjectView, CommentChoice } from '@app/models';
import { CommentValidationService, CommentService } from '@app/services';

@Component({
  selector: 'app-public-choices',
  templateUrl: './public-choices.component.html',
  styleUrls: ['./public-choices.component.scss']
})
export class PublicChoicesComponent extends PublicBaseComponent  implements OnInit {
  constructor(
    private commentValidationService: CommentValidationService,
    private commentService: CommentService,
    private formBuilder: FormBuilder
    ) {
    super();
  }

  @Input() projectView: ProjectView;
  form: FormGroup;
  choiceData: CommentChoice[];

  ngOnInit(): void {
    this.dataLoaded.next(true);
    // check for more than one option, throw error to console
    if (this.question.ModuleOptions.length > 1) {
      console.error('Public Topics - Got > 1 Module Option');
    }
    this.maxSelection = +this.question.ModuleOptions[0].VALUE;
    this.createForm();
    this.subs.push(
      this.commentValidationService.runValidation.subscribe(v => {
        // run validation
        if (v) {
          this.validateInputs();
        }
      }),
      this.commentValidationService.shouldSubmissionReset.subscribe(r => {
        // reset
        if (r) {
          this.resetSubmission();
        }
      })
    );
  }

  resetSubmission() {
    this.form.controls.choices.reset();
    this.commentValidationService.setValidation({choices: false});
  }

  // bind input to Stakeholder Comment
  setStakeholderComment() {
    const choices = this.getSelectedChoices();
    this.commentService.stakeholderComment.CommentChoices = choices;
  }

  // override
  validateInputs(): void {
    this.setStakeholderComment();
    this.validateAllFormFields(this.form);
    const inputValid = this.form.valid;
    this.commentValidationService.setValidation({choices: inputValid});
  }

  private createForm() {
    this.choiceData = this.projectView.SelectedCommentChoices;
    const choiceOptions = this.question.IS_REQUIRED ?
    new FormArray([], [this.maxSelectedCheckboxes(this.maxSelection), this.minSelectedCheckboxes(1)]) :
    new FormArray([], [this.maxSelectedCheckboxes(this.maxSelection)]);
    this.form = this.formBuilder.group({
      choices: choiceOptions
    });
    this.addControls();
  }

  private addControls() {
    this.choiceData.forEach(_ => {
      const control = new FormControl();
      (this.form.controls.choices as FormArray).push(control);
    });
  }

  private getSelectedChoices(): number[] {
    const choices: number[] = [];
    this.form.value.choices.map((selected, i) => {
      if (selected) {
        choices.push(this.choiceData[i].COMMENT_CHOICE_ID);
      }
    });
    return choices;
  }

}
