import { Component, OnInit } from '@angular/core';
import { PublicBaseComponent } from '../public-base/public-base.component';

@Component({
  selector: 'app-public-captcha',
  templateUrl: './public-captcha.component.html',
  styleUrls: ['./public-captcha.component.scss']
})
export class PublicCaptchaComponent extends PublicBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.dataLoaded.next(true);
  }

}
