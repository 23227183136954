import { Component, OnInit} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { PublicBaseComponent } from '../public-base/public-base.component';
import { CommentValidationService } from '@app/services/comment-validation.service';
import { CommentService } from '@app/services/comment.service';
import { DomSanitizerService } from '@app/services/dom-sanitizer.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-public-comment',
  templateUrl: './public-comment.component.html',
  styleUrls: ['./public-comment.component.scss']
})
export class PublicCommentComponent extends PublicBaseComponent implements OnInit {
  constructor(
    private commentValidationService: CommentValidationService,
    private commentService: CommentService,
    private domSanitizerService: DomSanitizerService,
  ) {
    super();
  }

  commentFormControl: FormControl;
  matcher = new MyErrorStateMatcher();
  minLength = 4;
  maxLength = 4000;

  ngOnInit(): void {
    this.dataLoaded.next(true);
    if (this.question.IS_REQUIRED) {
      this.commentFormControl = new FormControl('', [
        //Validators.required,
        Validators.minLength(this.minLength),
        Validators.maxLength(this.maxLength)
      ]);
    } else {
      this.commentFormControl = new FormControl('', [
        Validators.minLength(this.minLength),
        Validators.maxLength(this.maxLength)
      ]);
    }
    this.subs.push(
      this.commentValidationService.runValidation.subscribe(v => {
        // run validation
        if (v) {
          this.validateInputs();
        }
      }),
      this.commentValidationService.shouldSubmissionReset.subscribe(r => {
        // reset
        if (r) {
          this.resetSubmission();
        }
      })
    );
  }

  resetSubmission() {
    this.commentFormControl.reset();
    this.commentValidationService.setValidation({comment: false});
  }

  // bind input to Stakeholder Comment
  setStakeholderComment() {
    const comment = this.domSanitizerService.sanitizeInput(this.commentFormControl.value);
    this.commentService.stakeholderComment.STAKE_COMMENT = comment;
  }

  // override
  validateInputs(): void {
    this.setStakeholderComment();
    this.commentFormControl.markAllAsTouched();
    const inputValid = this.commentFormControl.valid;
    this.commentValidationService.setValidation({comment: inputValid});
  }

}
