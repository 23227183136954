import { environment } from 'environments/environment';

const API_URL = environment.apiUrl;
const APP_URL = environment.siteUrl;
const AUTH_URL = environment.authUrl;

export const ENDPOINTS = {
    MEDIA_URL: environment.mediaUrl,
    API_URL: environment.apiUrl,
    SITE_URL: environment.siteUrl,
    AUTH_URL: environment.authUrl,
    API_METHODS: {
        AWS_GET_MEETING_INFO: `${environment.gatewayUrl}/getmeetingcomments/`, // add peId - TODO: update route name to getmeetinginfo as it returns more data than comments
        AWS_GET_VISIBLE_COMMENTS: `${environment.gatewayUrl}/getVisibleComments/`, // add peId
        AWS_POST_SQS_PODIUM_COMMENT: `${environment.gatewayUrl}/postpodiumcomment/`, // add stake_guid
        GEOMETRY_GET: `${API_URL}/ProjectGeometry/Get`,
        EVENT_DOCUMENT_GET: `${API_URL}/project_event_document/GetPublicDocuments`,
        PUBLIC_QUESTION_GET: `${API_URL}/PublicQuestion/Get`,
        PROJECT_GET: `${API_URL}/Project/Get`,
        PROJECT_DOCUMENT_GET: `${API_URL}/CommentFormImage/Get`,
        PROJECT_EVENT_GET: `${API_URL}/Project_Event/GetByGuid`,
        RESPONSE_REQUEST_TYPE_LU_GET: `${API_URL}/LU_RESPONSE_REQUEST_TYPE/Get`,
        STAKEHOLDER_GET: `${API_URL}/STAKEHOLDER/GetBy`,
        STAKEHOLDER_COMMENT_GET: `${API_URL}/StakeholderCommentFilters/Get`,
        STAKEHOLDER_COMMENT_POST: `${API_URL}/STAKEHOLDER_COMMENT/Post`,
        STAKEHOLDER_PODIUM_COMMENT_POST: `${API_URL}/stakeholder_comment/PostPodiumComment`,
        STAKEHOLDER_PODIUM_COMMENT_GET: `${API_URL}/stakeholder_comment/GetPodiumComments`,
        STAKEHOLDER_SUPPORT_LU_GET: `${API_URL}/LU_STAKEHOLDER_SUPPORT/Get`,
        LOGIN: `${AUTH_URL}/connect/token`,
        USER_GET: `${AUTH_URL}/connect/userinfo`
    }
};
