import { Injectable } from '@angular/core';

import { CommentService, MqttService } from '@app/services';
import { MqttModeratorMessage, MqttMessage, MqttOnlineMeeting } from '@app/models/mqtt';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  constructor(
    private commentService: CommentService,
    private mqttService: MqttService
  ) {
  }
  public meetingInitialState: BehaviorSubject<any> = new BehaviorSubject(null);

  isPodiumEnabledState(msg: MqttMessage): boolean {
    if (msg && (msg.payload === 'true' || msg.payload === true)) {
      return true;
    } else {
      return false;
    }
  }

  async getInitialState(peId): Promise<MqttOnlineMeeting> {
    const res: MqttOnlineMeeting = await this.commentService.pomGetInitialMeetingState(peId); // TODO: can we rewrite this to avoid making this GET 3x?
    if (!this.meetingInitialState.value) {
      this.meetingInitialState.next(res); // set to local state to avoid making another GET req.
    }
    return res;
  }

  public setInitialConnectedStakeholders() {
    if (this.meetingInitialState.value && this.meetingInitialState.value.connectedClients.length) {
      this.mqttService.subStakeConnections.next(
        this.meetingInitialState.value.connectedClients.length // initial val from dynamo
      );
    } else {
      this.mqttService.subStakeConnections.next(0);
    }
    this.mqttService.incrementConnectedStakeholders(); // add self
  }

  isNullOrUndefined(obj:Object, key: string){
    if (typeof obj[key] !== undefined || typeof obj[key] !== null){
      return false;
    } else{
      return true;
    }
  }

  // these were redundant calls

  /*
  async getIsPodiumEnabledState(peId): Promise<boolean> {
    const res = await this.commentService.pomGetInitialMeetingState(peId); // TODO: can we rewrite this to avoid making this GET 3x?
    if (!this.meetingInitialState.value) {
      this.meetingInitialState.next(res); // set to local state to avoid making another GET req.
    }
    if (res && typeof res.isPodiumEnabled === 'boolean') {
      return res.isPodiumEnabled;
    }
  }*/

  /*
  async getModeratorMsgState(peId) {
    const res = await this.commentService.pomGetInitialMeetingState(peId); // TODO: can we rewrite this to avoid making this GET 3x?
    // moderator message
    if (res && typeof res.isModMsgEnabled === 'boolean' && typeof res.moderatorMsg === 'string') {
      const msg: MqttModeratorMessage = {
        onlineMeetingId: peId,
        moderatorMsg: res.moderatorMsg,
        isModMsgEnabled: res.isModMsgEnabled,
      };
      this.mqttService.pubModeratorToggledMsg(msg);
    }
  }
  */

  /*
  async getModeratorMsg(peId): Promise<MqttModeratorMessage> {
    const res: any = await this.commentService.pomGetInitialMeetingState(peId); // TODO: can we rewrite this to avoid making this GET 3x?
    // moderator message
    if (res && typeof res.isModMsgEnabled === 'boolean' && typeof res.moderatorMsg === 'string') {
      return res as MqttModeratorMessage;
    }
  }
  */

}
