import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

import {  DialogNoticeService } from '@app/services';
import { CombinedEventInfo } from '@app/models/combined-event-info';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  @Input() combinedEventInfo: CombinedEventInfo;

  constructor(
    private dialogNoticeService: DialogNoticeService,
    private dom: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  displayHelp() {
    const htmlContent = this.combinedEventInfo.projectView.PROJECT_FOOTER;
    const dialogRef = this.dialogNoticeService.help(
      {
        title: 'Help',
        message: null,
        combinedEventInfo: this.combinedEventInfo,
        width: 600,
        height: 400
      }
    );
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
  }

}
