
export interface ModuleOptions {
    EXPRESSION: string;
    IsEnabled: boolean;
    NAME: string;
    PQ_SUB_MODULE_OPTION_ID: Number;
    VALUE: string;
}

export const MODULE_TYPES = {
    CAPTCHA: 'Captcha',
    CHOICES: 'Choices',
    COMMENT: 'Comment',
    MAP: 'Map',
    RESPONSE: 'Response',
    RATING: 'Rating',
    STAKEHOLDER: 'Stakeholder',
    TOPICS: 'Topics',
};

export interface PublicQuestion {
    EXPRESSION: string;
    IS_REQUIRED: boolean;
    IsQuestion: boolean;
    ModuleName: string;
    ModuleOptions: ModuleOptions[];
    ORDER_NUM: number;
    PAGE_NUM: number;
    PQ_MODULE_ID: number;
    PQ_SUB_MODULE_ID: number;
    QUESTION_NUM: number;
    SECTION_NUM: number;
    TEXT: string;
}

export interface PublicQuestionGet {
    project_id: number;
    pe_id: number;
}
