import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { DialogNoticeService } from '@app/services';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpCommonService {
  constructor(
    private dialogNoticeService: DialogNoticeService
  ) { }

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'X-Api-Key': environment.gatewayApiKey
      },
    )
  };

  setHeader(name: string, value: string | string[]): HttpHeaders {
    this.httpOptions.headers = this.httpOptions.headers.set(name, value);
    return this.httpOptions.headers;
  }

  toHttpParams(obj) {
    let httpParams = new HttpParams();
    Object.keys(obj).forEach(key => {
        httpParams = httpParams.set(key, obj[key]);
    });
    return httpParams;
  }

     /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  handleError<T> (operation = 'operation', result?: T, showDialog = true, customErrorMsg = '') {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // show alert dialog
      if (showDialog) {
        this.dialogNoticeService.error({title: 'REQUEST ERROR', message: `${error.name}: ${customErrorMsg}`});
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

}
