import { SafeResourceUrl } from '@angular/platform-browser';

export enum PollQuizType {
    SlidoPoll = 'SlidoPoll',
    SlidoQuiz = 'SlidoQuiz'
}

export interface PollQuiz{
    desc: string;
    embedUrl: string;
    iFrameHeight: string;
    hash: string;
    title: string;
    type: PollQuizType
    visible: boolean;
    safeUrl?: SafeResourceUrl;
}