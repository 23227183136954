import { Component, OnInit, OnDestroy,  AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PollQuiz } from '@app/models/poll-quiz';
import { PollQuizService } from '@app/services/poll-quiz.service';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private pollQuizService: PollQuizService
  ) { }

  polls$ = new BehaviorSubject<PollQuiz[]>(null);
  visiblePoll$ = new BehaviorSubject<PollQuiz>(null);
  viewLoaded$ = new BehaviorSubject<boolean>(false);
  containerVisible = false;

  private subs: Subscription[] = [];

  ngOnInit(): void {
    this.getSlidoPolls();
    this.subs.push(
      this.pollQuizService.toggledPollVisible$.subscribe(tpv =>{
        this.setVisiblePolls();
        this.containerVisible = tpv;
      }),
      this.pollQuizService.slidoPolls$.subscribe(polls => {
        if (polls){
          this.polls$.next(polls);
          const visiblePoll = this.polls$.value.filter(p => p.visible);
          if (visiblePoll.length === 1){
            this.visiblePoll$.next(visiblePoll[0])
          } else{
            this.visiblePoll$.next(null);
          }
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subs.push(
      this.polls$.subscribe(p =>{
        if (p){
          this.setVisiblePolls();
        }
      })
    )
  }

  getSlidoPolls(): void{
    this.pollQuizService.getSlidoPolls().subscribe(polls => {
      if (polls){
        this.polls$.next(polls);
        const visiblePoll = polls.filter(p => p.visible)[0];
        this.visiblePoll$.next(visiblePoll);
      }
    });
  }

  private setVisiblePolls(){
    this.polls$.value.forEach(p =>{
      const node = document.getElementById(p.hash);
      if (node){
        if (p.visible){
          node.className = 'pq-visible';
        } else{
          node.className = 'pq-hidden';
        }
      }
    });
  }

  
}
