import { Injectable } from '@angular/core';

const PARAM_NAMES = {
  PEGUID: 'peGuid',
  STAKEGUID: 'stakeGuid'
};


@Injectable({
  providedIn: 'root'
})
export class UrlParamService {
  constructor() { }

  getStakeGuid(): string {
    return this.getParameterByName(PARAM_NAMES.STAKEGUID);
  }

  getEventGuid(): string {
    return this.getParameterByName(PARAM_NAMES.PEGUID);
  }

  private getParameterByName(name: string): string {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

}
