import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MeetingDetails, ProjectView, StakeholderView, EventsView, PublicQuestion, PublicMeetingInfo, MODULE_TYPES} from '@app/models';

import {
  QuestionsService,
  UrlParamService, StakeholderService, ProjectEventService,
  CommentService, CommentValidationService, ProjectService } from '@app/services';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [QuestionsService, ProjectEventService, ProjectService, CommentService, StakeholderService, UrlParamService, CommentValidationService]
})
export class QuestionsComponent implements OnInit, OnDestroy {
  constructor(
    private questionsService: QuestionsService,
    private projectEventService: ProjectEventService,
    private projectService: ProjectService,
    private commentService: CommentService,
    private stakeholderService: StakeholderService,
    private urlParamService: UrlParamService,
    private commentValidationService: CommentValidationService,
  ) { }

  @Input() publicMeetingInfo: PublicMeetingInfo;
  @Input() meetingDetails: MeetingDetails;

  // used in the template -> <ng-container [ngSwitch]=">
  readonly CAPTCHA = MODULE_TYPES.CAPTCHA;
  readonly CHOICES = MODULE_TYPES.CHOICES;
  readonly COMMENT = MODULE_TYPES.COMMENT;
  readonly MAP = MODULE_TYPES.MAP;
  readonly RATING = MODULE_TYPES.RATING;
  readonly RESPONSE = MODULE_TYPES.RESPONSE;
  readonly TOPICS = MODULE_TYPES.TOPICS;
  readonly STAKEHOLDER = MODULE_TYPES.STAKEHOLDER; // NOT BEING USED

  private subs: Subscription[] = [];

  publicQuestions: PublicQuestion[];
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectView: ProjectView;
  eventsView: EventsView;
  stakeholderView: StakeholderView;
  submitComplete = false;
  isAnonymous: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit() {
    const stakeGuid = this.urlParamService.getStakeGuid();
    // Combine Results
    const serviceInfo = combineLatest([
      // questions
      this.questionsService.getQuestions(this.publicMeetingInfo).pipe(catchError(err => {throw err; })),
      // Project Info
      this.projectService.getProjectAsObservable(this.publicMeetingInfo).pipe(catchError(err => { throw err; })),
      // Stakeholder Info
      this.stakeholderService.getStakeholderAsObservable(stakeGuid).pipe(catchError(err => {
        throw err;
      })),
      // Event Info
      this.projectEventService.getProjectEventAsObservable(this.publicMeetingInfo).pipe(catchError(err => { throw err; })),
      // this.projectEventService.projectEventSubject.pipe(catchError(err => { throw err; }))
    ]);
    this.subs.push(
      serviceInfo.subscribe(v => {
        const [publicQuestions, projectInfo, stakeholderView,  eventDetails] = v;
        // set results
        // set the question count, do this only once
        this.commentValidationService.setInitialQuestionCount(publicQuestions.length); // set the number of questions for validation
        this.projectView = projectInfo;
        this.meetingDetails.eventDetails = eventDetails;
        this.publicQuestions = publicQuestions;
        this.stakeholderView = stakeholderView;
        this.isAnonymous.next(this.stakeholderView.IS_ANONYMOUS);
        this.setStakeholderComments();
        this.dataLoaded.next(true);
      }),
    );
  }

  setSubmitComplete(event) {
    this.submitComplete = event;
  }

  // set common information for comment submission, this will be used by the Comment Service
  setStakeholderComments(): void {
    // general project info
    this.commentService.stakeholderComment.PE_ID = this.meetingDetails.eventDetails.PE_ID;
    this.commentService.stakeholderComment.PROJECT_ID = this.projectView.PROJECT_ID;
    this.commentService.stakeholderComment.STAKE_ID = this.stakeholderView.STAKE_ID;
    // comment source
    this.commentService.stakeholderComment.COMMENT_SOURCE_ID = 27; // 27 is default key for 'Collected at Meeting'

    // this can return undefined depending on the setup..
    /* NO LONGER USING 36 for the comment source
    try {
      const online = this.meetingDetails.sources.filter(x => x.value.toLocaleLowerCase() === 'online')[0];
      this.commentService.stakeholderComment.COMMENT_SOURCE_ID = online.key;
    } catch {
      this.commentService.stakeholderComment.COMMENT_SOURCE_ID = 36; // 36 is default key for online meeting
    }*/

    // comment category
    this.commentService.stakeholderComment.COMMENT_CATEGORY_ID = 1; // set a default for commentcategory

    /* LEFT FOR REFERENCE - NOT USING
    try {
      const cat = this.meetingDetails.categories[0];
      this.commentService.stakeholderComment.COMMENT_CATEGORY_ID = cat.key;
    } catch {
      this.commentService.stakeholderComment.COMMENT_CATEGORY_ID = 1; // set a default key for online meeting
    }
    */
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  emitValidation() {
    this.commentValidationService.validateAllResponses();
  }

}
