import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';

@Component({
  selector: 'app-disconnect-network-status-dialog',
  templateUrl: './disconnect-network-status-dialog.component.html',
  styleUrls: ['./disconnect-network-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisconnectNetworkStatusDialogComponent extends BasicDialogComponent {

  showActionButtons = true;
  htmlMessage: string;

  constructor(
    public dialogRef: MatDialogRef<DisconnectNetworkStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
  ) {
    super(dialogRef, data);
  }

}
