import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { StakeholderComment, PomComment } from '@app/models';
import { HttpCommonService } from './http-common.service';
import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { UrlParamService } from '.';
import { MqttOnlineMeeting } from '@app/models/mqtt';
import { CombinedEventInfo } from '@app/models/combined-event-info';

// used for testing comment model
const testingComments: any[] = [
  {
    id: '12345',
    name: 'Ian T.',
    comment: 'Old Comment model',
    visible: true
  }, {
    id: '44453',
    stakeId: 12345,
    comment: 'New Comment model',
    city: 'Stevens Point',
    displayName: 'Ian G.',
    firstName: 'Ian',
    lastName: 'Grasshoff',
    state: 'WI',
    topics: ['topic 1', 'topic 2', 'topic 3'],
    timeStamp: 1594832948874,
    visible: true
  }
];

export interface StakeholderCommentPost {
  stake_id: number;
  person_id: any;
}

export interface StakeholderPodiumPost {
  stake_guid: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  urlParamService: UrlParamService;
  constructor(
    private http: HttpClient,
    private httpCommonService: HttpCommonService
  ) {}

  public stakeholderComment: StakeholderComment = new StakeholderComment();
  public pomDisplayedComments: BehaviorSubject<PomComment[]> = new BehaviorSubject<PomComment[]>([]);
  unsubscribe$: Subject<boolean> = new Subject();

  getCommentDefaults(cI: CombinedEventInfo): StakeholderComment {
    const comment = new StakeholderComment();
    // general project info
    comment.PE_ID = cI.meetingDetails.eventDetails.PE_ID;
    comment.PROJECT_ID = cI.projectView.PROJECT_ID;
    comment.STAKE_ID = cI.stakeholderView.STAKE_ID;
    comment.PROJECT_NR = cI.projectView.PROJECT_NR;
    // comment source
    comment.COMMENT_SOURCE_ID = 27; // 27 is default key for 'Collected at Meeting'
    // comment category
    comment.COMMENT_CATEGORY_ID = 1; // set a default for commentcategory
    return comment;
  }

  postComment(comment: StakeholderComment): Observable<any> {
    // check for STAKE_ID
    if (
      comment.STAKE_ID === 0 ||
      comment.STAKE_ID === undefined ||
      comment.STAKE_ID === null
    ) {
      throw new Error('STAKE_ID must be set');
    }
    const params: StakeholderCommentPost = {
      stake_id: comment.STAKE_ID,
      person_id: null
    };
    const url = endpoints.API_METHODS.STAKEHOLDER_COMMENT_POST;
    const options = {
      headers: this.httpCommonService.httpOptions.headers,
      params: this.httpCommonService.toHttpParams(params)
    };
    return this.http.post(url, comment, options).pipe(
      map((res) => res),
      tap((_) => {
        console.log(
          `Commment Data Posted -> ${JSON.parse(JSON.stringify(comment))}`
        );
      }),
      catchError(
        this.httpCommonService.handleError<any[]>('CommentService: postComment')
      )
    );
  }

  // Post to AWS SQS
  postPodiumCommentSQS(
    stakeGuid: string,
    comment: StakeholderComment
  ): Observable<any> {
    // check for STAKE_ID
    if (
      comment.STAKE_ID === 0 ||
      comment.STAKE_ID === undefined ||
      comment.STAKE_ID === null
    ) {
      throw new Error('STAKE_ID must be set');
    }
    // AWS SQS Post
    const url = `${endpoints.API_METHODS.AWS_POST_SQS_PODIUM_COMMENT}${stakeGuid}`;
    const options = {
      headers: this.httpCommonService.httpOptions.headers,
    };
    return this.http.post(url, comment, options).pipe(
      map((res) => res),
      tap((_) => {
        console.log(`Podium Commment Data Posted`);
      }),
      catchError(
        this.httpCommonService.handleError<any>('CommentService: postPodiumComment')
      )
    );
  }

  // Posts to PIMA API
  postPodiumCommentPima(
    stakeGuid: string,
    comment: StakeholderComment
  ): Observable<any> {
    // check for STAKE_ID
    if (
      comment.STAKE_ID === 0 ||
      comment.STAKE_ID === undefined ||
      comment.STAKE_ID === null
    ) {
      throw new Error('STAKE_ID must be set');
    }
    // PIMA API
    const url = endpoints.API_METHODS.STAKEHOLDER_PODIUM_COMMENT_POST;
    const params: StakeholderPodiumPost = {
      stake_guid: stakeGuid
    };
    const options = {
      headers: this.httpCommonService.httpOptions.headers,
      params: this.httpCommonService.toHttpParams(params)
    };
    return this.http.post(url, comment, options).pipe(
      map((res) => res),
      tap((_) => {
        console.log(`Podium Commment Data Posted`);
      }),
      catchError(
        this.httpCommonService.handleError<any>('CommentService: postPodiumComment')
      )
    );
  }

  async pomGetInitialMeetingState(peId): Promise<MqttOnlineMeeting> {
    // TODO: add similar moderator protected lambda that that does not return visible comments for stakeholders
    const options = {
      headers: this.httpCommonService.httpOptions.headers,
    };
    const requestUrl = `${endpoints.API_METHODS.AWS_GET_MEETING_INFO}${peId}`;
    const res: any = await this.http.get(requestUrl, options).toPromise();
    // intercept with test comments
    /*
    const res = {};
    res['comments'] = testingComments;
    */
    return res;
  }

  async pomGetInitialVisibleComments(peId) {
    try {
      const options = {
        headers: this.httpCommonService.httpOptions.headers,
      };
      const requestUrl = `${endpoints.API_METHODS.AWS_GET_VISIBLE_COMMENTS}${peId}`;
      const res: any = await this.http.get(requestUrl, options).toPromise();
      // update behavior subject
      if (res) {
        this.pomDisplayedComments.next(res);
      }
      return res;
    } catch (err) {
      console.error(err);
      return;
    }
  }

}
