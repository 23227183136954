import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as homeActions from 'app/state/app.actions'
import * as fromHome from 'app/state//app.state';
import * as fromReducers from 'app/state/app.reducers';

interface Language{
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  constructor(
    private store: Store<fromHome.State>,
    private translate: TranslateService
  ) { }

    // selectedLanguage: string = 'English'
    selectedLanguage: any = {value: 'en', viewValue: 'English'}

    languages: Language[] = [
      {value: 'en', viewValue: 'English'},
      {value: 'es-mx', viewValue: 'Spanish'},
    ];
  

  ngOnInit(): void {
    // this.selectedLanguage = {value: 'en', viewValue: 'English'}
    // this.store
    // .pipe(select(fromReducers.getLanguage))
    // .subscribe((langValue: string) => {
    //   // this.selectedLanguage = langValue;
    //   if (langValue==='en') this.selectedLanguage = {value: 'en', viewValue: 'English'}
    //   else if (langValue==='es-max') this.selectedLanguage = {value: 'es-mx', viewValue: 'Spanish'}
    //   this.translate.getTranslation(langValue).subscribe((res) => {
    //     if (res && res.hasOwnProperty('PODIUM')) {
    //       this.translatedJSON = res.PODIUM;
    //     }
    //   });
    // });
  }

  onLanguageSelectChange(lang: any) {
    this.translate.use(lang.value).subscribe(() => {
      // can now dispatch now that the updated language has loaded
      this.store.dispatch(new homeActions.SetLanguage(lang.value));
      console.log('selectedLang', this.selectedLanguage)
    });
  }

}
