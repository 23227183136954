import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

import { BasicDialogData, BasicDialogComponent } from '../dialogs/basic-dialog/basic-dialog.component';
import { ErrorDialogComponent } from '../dialogs/error-dialog/error-dialog.component';
import { ChangeLogComponent } from '../dialogs/change-log/change-log.component';
import { HelpDialogComponent, HelpDialogData } from '../dialogs/help-dialog/help-dialog.component';
import { PodiumDialogComponent, PodiumDialogData } from '../dialogs/podium-dialog/podium-dialog.component';
import { DisconnectModeratorDialogComponent } from '../dialogs/disconnect-moderator-dialog/disconnect-moderator-dialog.component';
import { DisconnectNetworkStatusDialogComponent } from '../dialogs/disconnect-network-status-dialog/disconnect-network-status-dialog.component';
import { DisconnectedDialogComponent } from '../dialogs/disconnected-dialog/disconnected-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogNoticeService {

  defaultWidth = 500;
  defaultHeight = 200;

  constructor(
    private dialog: MatDialog,
    private dom: DomSanitizer
  ) { }

  changelog(): MatDialogRef<ChangeLogComponent> {
    return this.dialog.open(ChangeLogComponent);
  }

  alert(config: BasicDialogData): MatDialogRef<BasicDialogComponent> {
    const dialogConfig = this.config(config);
    dialogConfig.data.title = config.title ? config.title : 'ALERT';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    return this.dialog.open(BasicDialogComponent, dialogConfig);
  }

  error(config: BasicDialogData): MatDialogRef<ErrorDialogComponent> {
    const dialogConfig = this.config(config);
    dialogConfig.data.title = config.title ? config.title : 'ERROR';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
    dialogRef.componentInstance.showActionButtons = typeof config.showActionButtons === 'boolean' ? config.showActionButtons : true;
    const htmlMessage = typeof config.htmlMessage === 'string' ? config.htmlMessage : '';
    dialogRef.componentInstance.htmlMessage = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  podium(config: PodiumDialogData): MatDialogRef<PodiumDialogComponent> {
    const dialogConfig = this.configPodiumData(config);
    dialogConfig.data.title = config.title ? config.title : 'Join the Podium';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    dialogConfig.autoFocus = false;
    return this.dialog.open(PodiumDialogComponent, dialogConfig);
  }

  help(config: HelpDialogData): MatDialogRef<HelpDialogComponent> {
    const dialogConfig = this.configHelpData(config);
    dialogConfig.data.title = config.title ? config.title : 'Help';
    dialogConfig.disableClose = false; // we don't want the user to close the dialog by clicking outside it..
    dialogConfig.autoFocus = false;
    return this.dialog.open(HelpDialogComponent, dialogConfig);
  }

  disconnectModerator(config: BasicDialogData): MatDialogRef<DisconnectModeratorDialogComponent> {
    const dialogConfig = this.config(config);
    dialogConfig.data.title = config.title ? config.title : 'MODERATOR ERROR';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialogRef = this.dialog.open(DisconnectModeratorDialogComponent, dialogConfig);
    const htmlMessage = typeof config.htmlMessage === 'string' ? config.htmlMessage : '';
    dialogRef.componentInstance.htmlMessage = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  networkDisconnect(config: BasicDialogData): MatDialogRef<DisconnectNetworkStatusDialogComponent> {
    const dialogConfig = this.config(config);
    dialogConfig.data.title = config.title ? config.title : 'NETWORK CONNECTION ERROR';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialogRef = this.dialog.open(DisconnectNetworkStatusDialogComponent, dialogConfig);
    const htmlMessage = typeof config.htmlMessage === 'string' ? config.htmlMessage : '';
    dialogRef.componentInstance.htmlMessage = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  disconnected(config: BasicDialogData): MatDialogRef<DisconnectedDialogComponent> {
    const dialogConfig = this.config(config);
    dialogConfig.data.title = config.title ? config.title : 'DISCONNECTED';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialogRef = this.dialog.open(DisconnectedDialogComponent, dialogConfig);
    const htmlMessage = typeof config.htmlMessage === 'string' ? config.htmlMessage : '';
    dialogRef.componentInstance.htmlMessage = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  private configHelpData(config: HelpDialogData): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    const dialogWidth = +config.width > 0 ? +config.width : this.defaultWidth;
    const dialogHeight = +config.height > 0 ? +config.height : this.defaultHeight;
    dialogConfig.data = {
      title: config.title,
      message: config.message,
      width: dialogWidth,
      height: dialogHeight,
      combinedEventInfo: config.combinedEventInfo,
    };
    return dialogConfig;
  }

  private configPodiumData(config: PodiumDialogData): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    const dialogWidth = +config.width > 0 ? +config.width : this.defaultWidth;
    const dialogHeight = +config.height > 0 ? +config.height : this.defaultHeight;
    dialogConfig.data = {
      title: config.title,
      message: config.message,
      width: dialogWidth,
      height: dialogHeight,
      combinedEventInfo: config.combinedEventInfo
    };
    return dialogConfig;
  }

  private config(config: BasicDialogData): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    const dialogWidth = +config.width > 0 ? +config.width : this.defaultWidth;
    const dialogHeight = +config.height > 0 ? +config.height : this.defaultHeight;
    dialogConfig.data = {
      title: config.title,
      message: config.message,
      width: dialogWidth,
      height: dialogHeight,
      showActionButtons: typeof config.showActionButtons === 'boolean' ? config.showActionButtons : true
    };
    return dialogConfig;
  }

}
