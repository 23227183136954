import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';

import { AuthService } from '@app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    debugger;
    if(next.queryParams["token"]) {
      this.authService.savePassedToken(next.queryParams["token"]);
    }

    const authenticated = this.authService.checkAuthenticated();
    // const moderatorPermission = await this.authService.hasModeratorPermission().toPromise();
    if (authenticated) {
      // even though authenticated, make sure user has moderator permission
      const hasPermission = await this.authService.hasModeratorPermission().toPromise();
      if (!hasPermission){
        this.router.navigate(['/login'], { queryParams: next.queryParams});
      }
      return hasPermission;
    } else {
      this.router.navigate(['/login'], { queryParams: next.queryParams});
      return false;
    }
  }
}
