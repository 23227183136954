import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  /*
    imports: [
        MatButtonModule, MatTooltipModule, MatChipsModule,
        MatMenuModule, MatCheckboxModule, MatTabsModule,
        MatInputModule, MatSelectModule, MatAutocompleteModule,
        MatIconModule, MatToolbarModule, MatCardModule,
        MatProgressSpinnerModule, MatTableModule, MatListModule,
        CdkTableModule, MatExpansionModule, MatSliderModule
    ],*/
  exports: [
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatListModule,
    CdkTableModule,
    MatExpansionModule,
    MatSliderModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatDividerModule
  ]
})
export class MaterialModule {}
