import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v1 as uuidv1 } from 'uuid';
import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import { environment } from '@environments/environment';
import {
  MqttTopics,
  MqttMessage,
  MqttModeratorMessage
} from '@app/models/mqtt';
import { PomComment } from '@app/models';
import { CommentService } from './comment.service';

@Injectable({
  providedIn: 'root'
})
export class MqttAmplifyService {
  constructor(private commentService: CommentService) {
    if (environment.isLocalHost) {
      console.warn('MqttAmplifyService doesn\'t trigger disconnect, not being used');
    }
  }

  peId: number; // project event id
  clientId: string; // MQTT client id
  mqttClient: any; // Client
  appendpeIdConnector = '_'; // for joining / splitting strings in client-disconnect lambda
  isStakeholderConnector = '&'; // for joining / splitting strings in client-disconnect lambda
  isStakeholder: boolean; // for generating a stakeholder id
  topicConnector = '/'; // for listening to wildcard topics in aws
  MQTT_TOPICS: MqttTopics = new MqttTopics();

  // Behavior Subjects
  subModMsg: BehaviorSubject<MqttMessage> = new BehaviorSubject(null);
  subModToggledJoinPodium: BehaviorSubject<MqttMessage> = new BehaviorSubject(
    null
  );
  subModToggledTimer: BehaviorSubject<MqttMessage> = new BehaviorSubject(
    null
  );
  subModPodiumMsg: BehaviorSubject<
    MqttModeratorMessage
  > = new BehaviorSubject(null);
  subStakeMsg: BehaviorSubject<MqttMessage> = new BehaviorSubject(null);
  subStakeConnections: BehaviorSubject<number> = new BehaviorSubject(null);

  connectAndSubscribe(peId: number, isStakeholder: boolean = false) {
    this.peId = peId;
    this.isStakeholder = isStakeholder;

    Amplify.configure({
      Auth: {
        identityPoolId: environment.mqttIdentityPoolId,
        region: environment.awsRegion,
        userPoolId: environment.mqttUserPoolId,
        userPoolWebClientId: environment.mqttAppClientId
      }
    });

    // create client id
    this.clientId = this.createMqttClientId(this.isStakeholder);

    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: environment.awsRegion,
        aws_pubsub_endpoint: `wss://${environment.mqttEndpoint}/mqtt`,
        clientId: this.clientId
      })
    );

    // subscribe
    this.subscribeToTopics(this.peId);
    // connect
    this.publishConnect();
  }

  // publishes
  pubModeratorTogglePodium(enabled: boolean) {
    PubSub.publish(
      this.MQTT_TOPICS.MODERATOR_TOGGLED_JOIN_THE_PODIUM,
      JSON.stringify({
        onlineMeetingId: this.peId,
        status: enabled
      })
    );
  }

  pubModeratorToggleTimer(enabled: boolean) {
    PubSub.publish(
      this.MQTT_TOPICS.MODERATOR_TOGGLED_IS_TIMER_ENABLED,
      JSON.stringify({
        onlineMeetingId: this.peId,
        status: enabled
      })
    );
  }

  pubModeratorToggledMsg(msg: MqttModeratorMessage) {
    PubSub.publish(
      this.MQTT_TOPICS.MODERATOR_TOGGLED_PODIUM_MESSAGE,
      JSON.stringify(msg)
    );
  }

  pubStakeholderComment(comment) {
    PubSub.publish(
      this.MQTT_TOPICS.STAKEHOLDER_TOPIC,
      JSON.stringify(comment)
    );
  }

  pubAddDynamoComment(pomComment: PomComment) {
    PubSub.publish(
      this.MQTT_TOPICS.ADD_DYNAMO_COMMENT,
      JSON.stringify({
        onlineMeetingId: this.peId,
        comment: pomComment
      })
    );
  }

  pubModeratorToggleCommentVis(commentRef: any) {
    PubSub.publish(
      this.MQTT_TOPICS.MODERATOR_TOGGLED_COMMENT_VISIBILITY,
      JSON.stringify({
        onlineMeetingId: this.peId,
        comment: commentRef
      })
    );
  }

  // here if needed
  getClientId(): string {
    return this.clientId;
  }

  getPeId(): number {
    return this.peId;
  }

  private publishConnect() {
    PubSub.publish(
      this.MQTT_TOPICS.CONNECT_TOPIC,
      JSON.stringify({
        onlineMeetingId: this.peId,
        connectedClient: { id: this.clientId }
      })
    );
  }

  private createMqttClientId(isStakeholder: boolean): string {
    return `${this.peId}${this.appendpeIdConnector}${uuidv1()}${
      // uuid generates randomized unique id created from the system clock plus random values
      isStakeholder ? `${this.isStakeholderConnector}stakeholder` : `` // peId, stakeholder and mqttEnv name needs to be appended to clientId for the disconnect event
    }&env=${environment.mqttEnvName}`;
  }

  private subscribeToTopics(peId: number) {
    // create topics
    this.createMeetingTopics(peId);
    // subscribe to topics
    Object.keys(this.MQTT_TOPICS).map((i) => {
      const topic = this.MQTT_TOPICS[i];
      PubSub.subscribe(topic).subscribe({
        next: (data) => {
          const msgData = JSON.parse(data.value);
          this.topicHandler(topic, msgData);
          if (environment.isLocalHost) {
            console.log('Message received', data);
          }
        },
        error: (error) => console.error(error)
      });
    });
  }

  private createMeetingTopics(peId: number) {
    Object.keys(this.MQTT_TOPICS).forEach((i) => {
      const topic = this.MQTT_TOPICS[i];
      if (topic !== this.MQTT_TOPICS.DISCONNECT_TOPIC) {
        const newTopic = `${peId}${this.topicConnector}${topic}-${environment.mqttEnvName}`;
        this.MQTT_TOPICS[i] = newTopic;
      } else {
        this.MQTT_TOPICS[i] = topic;
      }
    });
  }

  private topicHandler(topic: string, msg: any): boolean {
    // bail if we don't have a matching meeting topic
    if (this.checkIfDisconnectTopic(topic)) {
      // need to check this first because the disconnect topic is not appended the peId - it is built into the clientId because IoT handles disconnect events out of the box
      topic = this.MQTT_TOPICS.DISCONNECT_TOPIC;
    }
    const meetingMatch = this.meetingTopicMatch(topic);
    if (!meetingMatch && topic !== this.MQTT_TOPICS.DISCONNECT_TOPIC) {
      return meetingMatch;
    }
    switch (topic) {
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_JOIN_THE_PODIUM:
        this.handleModeratorToggleJoinPodium(topic, msg);
        break;
      case this.MQTT_TOPICS.STAKEHOLDER_TOPIC:
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_COMMENT_VISIBILITY:
        this.handleModeraterCommentToggled(msg);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_PODIUM_MESSAGE:
        this.handleModeratorTogglePodiumMsg(msg);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_IS_TIMER_ENABLED:
        this.handleModeratorToggleTimer(topic, msg);
        break;
      case this.MQTT_TOPICS.ADD_DYNAMO_COMMENT:
        this.handleAddDynamoComment(msg);
        break;
      case this.MQTT_TOPICS.CONNECT_TOPIC:
        this.handleConnect(msg);
        break;
      case this.MQTT_TOPICS.DISCONNECT_TOPIC:
        this.handleDisconnect(msg);
        break;
      case this.MQTT_TOPICS.LAMBDA_DELETED_DISCONNECTED_CLIENT:
        /*
        message emitted from lambda trigger after deletion
        not currently being used: a potential improvement to disconnect as currently
        above DISCONNECT_TOPIC runs when anyone disconnects from any meeting
        */
        break;
      default:
        console.error('Unhandled case', topic);
    }
    return meetingMatch;
  }

  private handleDisconnect(msgData: any) {
    // triggers lambda which also deletes from dynamo
    const disconnectingClientId = msgData.clientId;
    const disconnectingPeId = Number(disconnectingClientId.split('_').shift());
    if (disconnectingPeId === this.peId) {
      // only decrement counter if it's a stakeholder client
      console.log(`client disconnected - id: ${disconnectingClientId}`);
      if (disconnectingClientId.includes('stakeholder')) {
        this.decrementConnectedStakeholders();
      }
    }
  }

  private handleConnect(msgData: any) {
    const connectingClientId = msgData.connectedClient.id;
    console.log(`client connected - id: ${connectingClientId}`);
    // only increment counter if it's a stakeholder client and not self
    if (connectingClientId !== this.clientId && connectingClientId.includes('stakeholder')) {
      this.incrementConnectedStakeholders();
    }
  }

  private handleAddDynamoComment(msgData: any) {
    const dData = msgData as MqttMessage;
    this.subStakeMsg.next(dData);
  }

  private handleModeratorTogglePodiumMsg(msgData: any) {
    const pData = msgData as MqttModeratorMessage;
    this.subModPodiumMsg.next(pData);
  }

  private handleModeratorToggleJoinPodium(topic: string, msgData: any) {
    this.subModToggledJoinPodium.next({ topic: topic, payload: msgData.status });
  }

  private handleModeratorToggleTimer(topic: string, msgData: any) {
    this.subModToggledTimer.next({ topic, payload: msgData.timerStatus });
  }

  private handleModeraterCommentToggled(msgData: any): void {
    const commentObj: PomComment = msgData.comment;
    const existingCommentOnState = this.commentService.pomDisplayedComments
      .getValue()
      .find((c) => c.id === commentObj.id);
    if (existingCommentOnState) {
      existingCommentOnState.visible = commentObj.visible;
      this.commentService.pomDisplayedComments.next(
        this.commentService.pomDisplayedComments
          .getValue()
          .filter((c) => c.visible === true)
      );
    } else {
      this.commentService.pomDisplayedComments.next([
        ...this.commentService.pomDisplayedComments.getValue(),
        commentObj
      ]);
    }
  }

  private checkIfDisconnectTopic(topic) {
    // removes wildcard and assigns disconnected topic as the topic contains the id of the disconnected client in place of '#'
    if (topic.includes(this.MQTT_TOPICS.DISCONNECT_TOPIC.slice(0, -1))) {
      return true;
    }
  }

  private meetingTopicMatch(topic: string) {
    return topic.indexOf(`${this.peId}${this.topicConnector}`) > -1
      ? true
      : false;
  }

  public incrementConnectedStakeholders() {
    this.subStakeConnections.next(
      this.subStakeConnections.getValue() + 1
    );
  }

  public decrementConnectedStakeholders() {
    this.subStakeConnections.next(
      this.subStakeConnections.getValue() - 1
    );
  }
}
