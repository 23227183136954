import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommentValidationService } from '@app/services/comment-validation.service';
import { CommentService } from '@app/services/comment.service';

@Component({
  selector: 'app-comment-submission',
  templateUrl: './comment-submission.component.html',
  styleUrls: ['./comment-submission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentSubmissionComponent implements OnInit, OnDestroy {
  constructor(
    private commentValidationService: CommentValidationService,
    private commentService: CommentService,
    private cdr: ChangeDetectorRef,
    ) { }

  @Output() submissionComplete: EventEmitter<boolean> = new EventEmitter<false>(); // emit submit complete
  actionButtonText = '';
  submitComplete = false; // for showing a successful submission, ie 'Leave Another Comment' button
  submitError = ''; // for displaying error message
  submitMessage = ''; // for displaying success message
  submitting = false; // for showing a spinner during submit
  private subs: Subscription[] = [];

  ngOnInit(): void {
    this.actionButtonText = 'Submit';
    this.cdr.markForCheck();
    this.subs.push(
      this.commentValidationService.validationResult.subscribe(r => {
        this.actionButtonText = 'Submit'; // Reset
        this.submitError = ''; // reset
        this.submitMessage = ''; // reset
        if (r.complete && !r.errors) {
          this.submit();
        }
        // display an error message to notify user of input errors
        if (r.errors) {
          this.submitError = 'Please fix input errors';
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  validateResponses(): void {
    this.commentValidationService.validateAllResponses();
  }

  private submitCallbackHandler(res) {
    // do a check on STAKE_ID
    try {
      if (res.STAKE_ID !== 0) {
        this.submitMessage = 'Thank you for your feedback. If your comment requires a response, a project representative will get back to you';
        this.submitComplete = true;
        this.submissionComplete.emit(true);
        this.submitting = false;
        this.cdr.markForCheck();
       }
    } catch (err) {
      console.log(err);
      this.submitError = 'An error occurred during submission.'; // for displaying error message
      this.submitting = false;
      this.actionButtonText = 'Reset';
      this.cdr.markForCheck();
    }
  }

  // reset the submission
  resetSubmission() {
    this.submitComplete = false;
    this.submitting = false;
    this.submissionComplete.emit(false);
    this.submitMessage = '';
    this.submitError = '';
    this.commentValidationService.resetSubmission();
    this.cdr.markForCheck();
  }

  // called after validation is complete
  private submit(): void {
    console.log('Submitting...');
    this.submitting = true;
    const submission = this.commentService.stakeholderComment;
    this.commentService.postComment(submission).subscribe(res => {
      this.submitCallbackHandler(res);
    });
  }
}
