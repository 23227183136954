import {
  Component,
  Inject,
  ChangeDetectorRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import {FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { v1 as uuidv1 } from 'uuid';

import {
  BasicDialogComponent,
  BasicDialogData
} from '@app/dialogs/basic-dialog/basic-dialog.component';
import {
  ProjectView,
  PublicQuestion,
  StakeholderComment,
  MODULE_TYPES,
  PomComment
} from '@app/models';

import { MqttService, CommentService } from '@app/services';
import { PublicTopicsComponent } from '@app/questions/public-topics/public-topics.component';
import { PublicSliderComponent } from '@app/questions/public-slider/public-slider.component';
import { CombinedEventInfo } from '@app/models/combined-event-info';
import { DomSanitizerService } from '@app/services/dom-sanitizer.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface PodiumDialogData extends BasicDialogData {
  combinedEventInfo: CombinedEventInfo;
}

@Component({
  selector: 'app-podium-dialog',
  templateUrl: './podium-dialog.component.html',
  styleUrls: ['./podium-dialog.component.scss'],
})
export class PodiumDialogComponent extends BasicDialogComponent implements OnInit {
  @ViewChild(PublicTopicsComponent) publicTopics!: PublicTopicsComponent;
  @ViewChild(PublicSliderComponent) ratingSlider!: PublicSliderComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<PodiumDialogComponent>,
    private mqttService: MqttService,
    private commentService: CommentService,
    private domSanitizerService: DomSanitizerService,
    @Inject(MAT_DIALOG_DATA) public data: PodiumDialogData
  ) {
    super(dialogRef, data);
    this.combinedEventInfo = data.combinedEventInfo;
    this.projectView = this.combinedEventInfo.projectView;
    // set peId
    this.peId = this.combinedEventInfo.meetingDetails.eventDetails.PE_ID;
  }

  combinedEventInfo: CombinedEventInfo;
  deviceInfo = null;
  peId: number;
  podiumComment = '';
  podiumCommentMinChars = 4;
  podiumCommentMaxChars = 1000;
  podiumCommentPlaceholder = 'Enter your question or comment...';
  podiumTopics = [];
  podiumTopicsText = [];
  projectView: ProjectView;
  submitEnabled = false; // default to false until user enters text
  submitError = ''; // for displaying error message
  submitMessage = ''; // for displaying success message
  showSpeechToTextIcon = false; // chrome/mobile only feature
  questionError = ''; // question error
  questionTopics: PublicQuestion = null;
  questionSlider: PublicQuestion = null;
  isSubmitting = new BehaviorSubject<boolean>(false);

  // comment form control
  commentFormControl: FormControl;
  matcher = new MyErrorStateMatcher();

  ngOnInit() {
    const topicsQuestion = this.combinedEventInfo.publicQuestions.filter(
      (q) => q.ModuleName === MODULE_TYPES.TOPICS
    )[0];
    const sliderQuestion = this.combinedEventInfo.publicQuestions.filter(
      (q) => q.ModuleName === MODULE_TYPES.RATING
    )[0];
    if (topicsQuestion) {
      if (this.combinedEventInfo.projectView.SelectedCommentTopics.length > 0) {
        this.questionTopics = topicsQuestion;
      } else {
        this.questionError = 'The topics question has been enabled for this event, but topics have not been configured within the project.';
        this.submitEnabled = false;
      }
    }
    if (sliderQuestion) {
      this.questionSlider = sliderQuestion;
    }
    this.createFormControl();
  }

  createFormControl() {
    this.commentFormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(this.podiumCommentMinChars),
      Validators.maxLength(this.podiumCommentMaxChars)
    ]);
    this.commentFormControl.statusChanges.subscribe(status => {
      if (String(status).toLowerCase() === 'valid') {
        if (this.questionError.length < 1) {
          this.submitEnabled = true;
        }
      } else {
        this.submitEnabled = false;
      }
    });
  }

  reset() {
    this.isSubmitting.next(false);
    this.submitMessage = '';
    this.submitError = '';
  }

  recordSpeech() {
    const { webkitSpeechRecognition } = window as any;
    const recognition = new webkitSpeechRecognition();
    const podiumCommentTextBox = document.getElementById(
      'podiumCommentTextArea'
    );
    const speechToTextIcon = document.getElementById('speechToTextIcon');
    recognition.lang = 'en-US';
    recognition.onresult = (e) => {
      const textFromSpeech = e.results[0][0].transcript;
      this.podiumComment = textFromSpeech;
      podiumCommentTextBox['value'] = textFromSpeech;
      this.podiumCommentPlaceholder = '';
      podiumCommentTextBox.focus();
    };

    recognition.onaudioend = () => {
      speechToTextIcon.style.color = 'grey';
      recognition.stop();
    };

    recognition.start();
    speechToTextIcon.style.color = 'blue';
  }

  async acceptAndSubmit() {
    this.isSubmitting.next(true);
    await this.postPodiumComment();
  }

  private async postPodiumComment() {
    const sc: StakeholderComment = this.commentService.getCommentDefaults(
      this.combinedEventInfo
    );
    // get comment data
    sc.STAKE_COMMENT = this.domSanitizerService.sanitizeInput(this.podiumComment);
    // set topics selected topics
    if (this.publicTopics) {
      sc.CommentTopics = this.publicTopics.getSelectedTopics();
      this.podiumTopicsText = this.publicTopics.getSelectedTopicsText();
    }
    // set rating
    if (this.ratingSlider) {
      sc.STAKEHOLDER_SUPPORT_ID = this.ratingSlider.getStakeholderSupport();
    }
    // default rating if we don't have the rating question for the meeting
    if (!this.ratingSlider) {
      sc.STAKEHOLDER_SUPPORT_ID = 3; // default to 3, neutral
    }
    // stake guid
    const stakeGuid = this.combinedEventInfo.stakeGuid;
    try {
      const res = await this.commentService.postPodiumCommentPima(stakeGuid, sc).toPromise();
      this.submitCallbackHandler(res);
    } catch (err) {
      console.log(err);
      this.submitError = 'An error occurred during the comment submission.'; // for displaying error message
      this.isSubmitting.next(false);
      this.submitMessage = '';
    }
  }

  private submitCallbackHandler(res) {
    // do a check on STAKE_ID
    try {
      if (res.STAKE_ID !== 0) {
        this.sendMqttPodiumMsg();
        this.submitMessage =
          'Thank you for your feedback.  This dialog will close shortly...';
        this.submitEnabled = false;
        setTimeout((_) => {
          this.isSubmitting.next(false);
          this.dialogRef.close();
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      this.submitError = 'An error occurred during submission.'; // for displaying error message
      this.isSubmitting.next(false);
      this.submitMessage = '';
    }
  }

  private sendMqttPodiumMsg(): void {
    const comment = this.domSanitizerService.sanitizeInput(this.podiumComment);
    const stakeFirstName = this.combinedEventInfo.stakeholderView.FIRST_NAME;
    const stakeLastName = this.combinedEventInfo.stakeholderView.LAST_NAME;
    const stakeLastInital = `${stakeLastName.substring(0, 1)}.`;
    const stakeCity = this.combinedEventInfo.stakeholderView.MAIL1_CITY;
    const stakeState = this.combinedEventInfo.stakeholderView.MAIL1_STATE;
    // create comment - new Model
    const pomComment: PomComment = {
      displayName: `${stakeFirstName} ${stakeLastInital}`,
      comment: comment,
      city: stakeCity,
      timeStamp: Date.now(),
      firstName: `${stakeFirstName}`,
      lastName: `${stakeLastName}`,
      state: stakeState,
      id: uuidv1(),
      stakeId: this.combinedEventInfo.stakeholderView.STAKE_ID,
      visible: false,
      topics: this.podiumTopicsText
    };
    // publish comment
    this.mqttService.pubStakeholderComment(comment);
    // publish to lambda
    this.mqttService.pubAddDynamoComment(pomComment);
  }
}
