import { Component, Input, OnInit } from '@angular/core';

import { MeetingDetails, CombinedEventInfo } from '@app/models';

@Component({
    selector: 'app-meeting-info',
    templateUrl: './meeting-info.component.html',
    styleUrls: ['./meeting-info.component.scss']
})
export class MeetingInfoComponent implements OnInit {

    @Input() combinedEventInfo: CombinedEventInfo;
    meetingDetails: MeetingDetails;
    projectId: number;
    peId: number

    ngOnInit() {
        this.meetingDetails = this.combinedEventInfo.meetingDetails;
        this.projectId = this.combinedEventInfo.projectView.PROJECT_ID;
        this.peId = this.meetingDetails.eventDetails.PE_ID;
    }
}
