import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { HttpCommonService } from './http-common.service';
import { ENDPOINTS as endpoints } from './endpoint.service.config';

import { PublicQuestion, MODULE_TYPES, EventsView, PublicQuestionGet, PublicMeetingInfo } from '@app/models';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  constructor(
    private http: HttpClient,
    private httpCommonService: HttpCommonService
    ) {}

  getQuestions(pm: PublicMeetingInfo): Observable<PublicQuestion[]> {
    // { project_id: '@project_id', pe_id: '@pe_id'}
    return this.getProjectDetails(pm).pipe(mergeMap(pe => {
      if (pe.PE_ID && pe.PROJECT_ID) {
        const publicQuestionGet: PublicQuestionGet = {
          project_id: +pe.PROJECT_ID,
          pe_id: +pe.PE_ID
        };
        const options = {
          params: this.httpCommonService.toHttpParams(publicQuestionGet),
          headers: this.httpCommonService.httpOptions.headers
        };
        const url = endpoints.API_METHODS.PUBLIC_QUESTION_GET;
        return this.http.get<any>(url, options).pipe(
          // sort by order number
          // Filter out Expressions and Stakeholder Module
          map((res) =>
            res.filter(a => (a.EXPRESSION === null || a.EXPRESSION === undefined || a.EXPRESSION ==='') && a.ModuleName !== MODULE_TYPES.STAKEHOLDER)
          ),
          tap(_ => {
            console.log('Fetched Questions');
          }),
          catchError(this.httpCommonService.handleError<PublicQuestion[]>('QuestionsService: getQuestions'))
        );
      } else {
        return this.httpCommonService.handleError<any>('QuestionsService: getQuestions');
      }
    }));
  }

  private getProjectDetails(pm: PublicMeetingInfo): Observable<EventsView> {
    const options = {
      headers: this.httpCommonService.httpOptions.headers,
      params: this.httpCommonService.toHttpParams(pm)
    };
    const url = endpoints.API_METHODS.PROJECT_EVENT_GET;
    return this.http.get<any>(url, options).pipe(
      map(r => r),
      tap(_ => console.log('Fetched Project Details')),
      catchError(this.httpCommonService.handleError<EventsView>('QuestionsService: getProjectDetails'))
    );
  }

}
