import { Component, OnInit, Input } from '@angular/core';

import { PomComment } from '@app/models';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {

  constructor() { }

  @Input() pomCom: PomComment;
  @Input() index: number;

  ngOnInit(): void {
  }

}
