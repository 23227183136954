export enum ImageTypeId{
    COMMENT_IMAGE = 1,
    DETOUR_IMAGE = 2,
    PROJECT_DOCUMENT = 3,
    PROJECT_LOGO = 4,
    MAILER = 5
}

export interface DocumentInfo{
    COMMENT_FORM_IMAGE_ID: number;
    COMMENT_FORM_IMAGE_TYPE_: any;
    COMMENT_FORM_IMAGE_TYPE_ID: ImageTypeId;
    DESCRIPTION: string;
    IS_ACTIVE: boolean;
    IS_DEFAULT: boolean;
    PROJECT_: null
    PROJECT_ID: number
    PUBLIC_URL: string;
    S3_URL: string;
    S3_URL_Signed: string;
    TITLE: string;
}