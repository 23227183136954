import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import {
  MqttService,
  MasterInfoService,
  AppStateService,
  PollQuizService,
  PageTitleService
} from '@app/services';

import { MqttOnlineMeeting } from '@app/models/mqtt';
import { CombinedEventInfo } from '@app/models/combined-event-info';

@Component({
  selector: 'app-stakeholder-view',
  templateUrl: './stakeholder-view.component.html',
  styleUrls: ['./stakeholder-view.component.scss']
})
export class StakeholderViewComponent implements OnInit, OnDestroy {
  constructor(
    private mqttService: MqttService,
    private masterInfoService: MasterInfoService,
    private appStateService: AppStateService,
    private pollQuizService: PollQuizService,
    private pageTitleService: PageTitleService
  ) {}
  connectedStakeholders: BehaviorSubject<number> = new BehaviorSubject(null);

  // Event Information
  combinedEventInfo: CombinedEventInfo;

  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resourceUrl: string;
  isVideo: boolean;
  testMqttMsg: '';
  showQuestions = false;
  peId: number;
  podiumEnabled = false;
  genCommentEnabled = false;
  showResourceLink = false;
  meetingState: MqttOnlineMeeting;
  timeoutId;
  subs: Subscription[] = [];
  isAnonymous = false;
  showLangSelector = false;
  clientSettings = null;

  async ngOnInit() {
    // subscriptions
    this.subs.push(
      // event info
      this.masterInfoService
        .getAllProjectEventData(true)
        .subscribe(async (res) => {
          this.combinedEventInfo = res;
          this.clientSettings = res.clientSettings;
          // set page title
          this.pageTitleService.setPageTitle(this.combinedEventInfo.meetingDetails.eventDetails.PE_NAME);
          // set resource url, easier to reference
          this.resourceUrl = this.combinedEventInfo.resourceUrl;
          // this.resourceUrl = 'https://www.youtube.com/embed/KzH0VY9UIpA'; // hard coded for demo
          this.peId = this.combinedEventInfo.meetingDetails.eventDetails.PE_ID;
          // check for anon
          this.isAnonymous = this.combinedEventInfo.stakeholderView.IS_ANONYMOUS;
          // connect and subscribe
          const ready = await this.mqttService.connectAndSubscribe(this.peId, true); // for counter to determine if stakeholder
          if (ready) {
            this.meetingState = await this.getInitialState(this.peId);
            this.handleInitialState(this.meetingState);
            this.appStateService.setInitialConnectedStakeholders();
            this.dataLoaded.next(true);
          }
        }),
      this.mqttService.subModToggledJoinPodium.subscribe((msg) => {
        if (msg) {
          this.podiumEnabled = this.appStateService.isPodiumEnabledState(msg);
        }
      }),
      this.mqttService.subModToggledGenComment.subscribe((msg) => {
        if (msg) {
          this.genCommentEnabled = msg.payload;
        }
      }),
      this.mqttService.subStakeConnections.subscribe((val) => {
        this.connectedStakeholders.next(val);
      })
    );
  }

  private async getInitialState(peId) {
    const state = await this.appStateService.getInitialState(peId);
    return state;
  }

  private handleInitialState(state: MqttOnlineMeeting){
    if (state){
      // podium enabled
      this.appStateService.isNullOrUndefined(state, 'isPodiumEnabled') ? null : this.podiumEnabled = state.isPodiumEnabled;
      // gen comments enabled
      this.appStateService.isNullOrUndefined(state, 'isGenCommentEnabled') ? null : this.genCommentEnabled = state.isGenCommentEnabled;
      // Slido poll panel enabled
      this.appStateService.isNullOrUndefined(state, 'isPollQuizEnabled') ? null : this.pollQuizService.setTogglePollVisibility(state.isPollQuizEnabled );
      // visible poll / quiz
      this.appStateService.isNullOrUndefined(state, 'visiblePollQuiz') ? null : this.pollQuizService.setVisibleSlidoPoll(state.visiblePollQuiz);
    }
  }

  toggleShowResourceLink() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.showResourceLink = true;
    this.timeoutId = setTimeout(() => {
      this.showResourceLink = false;
    }, 5000);
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  showQuestionsView(): void {
    this.showQuestions = true;
  }

  hideQuestionsView(): void {
    this.showQuestions = false;
  }

}
