import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { CommentService } from '@app/services';
import { PomComment } from '@app/models';

@Component({
  selector: 'app-left-pane',
  templateUrl: './left-pane.component.html',
  styleUrls: ['./left-pane.component.scss']
})
export class LeftPaneComponent implements OnInit, OnDestroy {
  @Input() stakeholderMessages: BehaviorSubject<object[] | null> = null;
  @Input() isVideoFile: boolean;
  @Input() resourceUrl: string;
  @Input() peId: number;
  subs: Subscription[] = [];
  visibleComments: PomComment[] = [];
  init = true; // used to get initial comments
  constructor(private commentService: CommentService) {}

  async ngOnInit() {
    this.stakeholderMessages.subscribe((comments: PomComment[]) => {
      if (comments && this.init) {
        this.visibleComments = comments.filter((c: any) => c.visible); // initial comments
        this.init = false;
      }
    });

    this.subs.push(
      this.commentService.pomDisplayedComments.subscribe((comments) => {
        if (comments && !this.init) {
          this.visibleComments = comments
            .filter((c: any) => c.visible)
            .reverse(); // subsequent updates from MQTT
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
