// import { createAction, props } from '@ngrx/store';

// export const login = createAction(
//   '[loginModule] log user Action',
//   props<{ username: string; password: string }>()
// );

// export const loginSuccess = createAction(
//   '[loginModule] log user Success Action'
// );

// export const loginFail = createAction(
//   '[loginModule] log user Fail Action',
//   props<{ message: string }>()
// );

import { Action } from '@ngrx/store';

export enum HomeActionTypes {
  SET_LANGUAGE = '[HOME] SET_LANGUAGE',
}
export class SetLanguage implements Action {
  readonly type = HomeActionTypes.SET_LANGUAGE;
  constructor(public payload: string) {}
}

export type HomeActions =
  | SetLanguage