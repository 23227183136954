import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { PomComment } from '@app/models';
import { MqttModeratorMessage } from '@app/models/mqtt';
import { MqttService } from '@app/services';
import { CombinedEventInfo } from '@app/models/combined-event-info';

@Component({
  selector: 'app-right-pane',
  templateUrl: './right-pane.component.html',
  styleUrls: ['./right-pane.component.scss']
})
export class RightPaneComponent implements OnInit, OnDestroy {
  @Input() stakeholderMessages: BehaviorSubject<object[]>;
  @Input() connectedStakeholders: BehaviorSubject<number>;
  @Input() combinedEventInfo: CombinedEventInfo;
  selectedTimerOption = '3';
  timerEnabled = false;
  timerIsTicking = false;
  genCommentIsEnabled = false;
  podiumIsEnabled = false;
  podiumMsgIsEnabled = false;
  podiumToggleTxt = 'Enable Join the Podium'
  minuteSelectorIsEnabled = false;
  moderatorMsg = 'The podium will open shortly...';
  previewMessage: PomComment = null;
  timeLeft = 180; // default to 3 mins if timer is enabled
  interval;
  subs: Subscription[] = [];
  slidoEnabled = false;

  constructor(
    private mqttService: MqttService
  ) {}

  ngOnInit(): void {
    const clientSettings = this.combinedEventInfo.clientSettings;
    this.slidoEnabled = clientSettings.slidoEnabled;
    this.podiumToggleTxt = `Enable ${clientSettings.clientUiSettings.podiumBtnText}`;
    this.subs.push(
      this.mqttService.subModToggledJoinPodium.subscribe(
        (res) => {
          if (res && res.payload !== null) {
            this.podiumIsEnabled =
              res.payload === 'true' || res.payload === true;
          }
        }
      ),
      // timer
      this.mqttService.subModToggledTimer.subscribe(
        (res: { payload: any }) => {
          if (res && res.payload !== null) {
            this.timerEnabled = res.payload === 'true' || res.payload === true;
          }
        }
      ),
      // gen comment
      this.mqttService.subModToggledGenComment.subscribe(
        (res: { payload: any }) => {
          if (res && res.payload !== null) {
            this.genCommentIsEnabled = res.payload === 'true' || res.payload === true;
          }
        }
      ),
      // podium message
      this.mqttService.subModPodiumMsg.subscribe((res) => {
        if (
          res &&
          res.isModMsgEnabled !== null &&
          res.moderatorMsg !== null
        ) {
          this.moderatorMsg = res.moderatorMsg;
          this.podiumMsgIsEnabled = res.isModMsgEnabled;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  setPreviewMsg(msgObj: PomComment) {
    this.previewMessage = msgObj;
  }

  highlightPreviewedComment(commentElement) {
    const commentElements = document.querySelectorAll('.comment');
    commentElements.forEach((el: HTMLElement) => {
      el.classList.remove('highlighted-comment');
    });
    commentElement.classList.add('highlighted-comment');
  }

  getCommentVisibility(visible: boolean) {
    if (visible) {
      return 'visibility';
    } else {
      return 'visibility_off';
    }
  }

  toggleCommentVisibility(commentObj: PomComment) {
    const commentRef: any = this.stakeholderMessages.value.find(
      (c: any) => c.id === commentObj.id
    );
    commentRef.visible = !commentObj.visible;
    this.mqttService.pubModeratorToggleCommentVis(commentRef);
  }

  podiumEnabledToggled() {
    const isEnabled = this.podiumIsEnabled ? true : false;
    this.mqttService.pubModeratorTogglePodium(isEnabled);
  }

  genCommentEnabledToggled() {
    const isEnabled = this.genCommentIsEnabled ? true : false;
    this.mqttService.pubModeratorToggleGenComment(isEnabled);
  }

  podiumMsgToggled(event) {
    const msg: MqttModeratorMessage = {
      onlineMeetingId: this.mqttService.getPeId(),
      moderatorMsg: this.moderatorMsg,
      isModMsgEnabled: event.checked
    };
    if (this.moderatorMsg.length > 0) {
      this.mqttService.pubModeratorToggledMsg(msg);
    }
  }

  moderatorMsgChange(event) {
    // automatically switch off the podium message if a user changes it
    // REMOVED 9/22 IN FAVOR OF SEPARATE CONTROLS
    // if (this.podiumIsEnabled) {
    //   this.podiumMsgToggled({ checked: false });
    // }
  }

  onTimerOptionChange(val: string) {
    this.timeLeft = Number(val) * 60;
  }

  timerToggled() {
    const enabled = this.timerEnabled ? true : false;
    this.mqttService.pubModeratorToggleTimer(enabled);
    this.timeLeft = Number(this.selectedTimerOption) * 60;
    if (!this.timerEnabled) {
      this.timerIsTicking = false;
      clearInterval(this.interval);
    } else {
      this.timerEnabled = true;
    }
  }

  pauseTimer() {
    this.timerIsTicking = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  startTimer() {
    if (this.timeLeft && !this.timerIsTicking) {
      this.timerIsTicking = true;
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.timeLeft = 0;
          clearInterval(this.interval);
          this.timerIsTicking = false;
        }
      }, 1000);
    } else if (!this.timeLeft && !this.timerIsTicking) {
      // reset timer to selected minute value if user clicks play button with 0 seconds remaining
      this.stopAndResetTimer();
    }
    // do nothing if play button is clicked multiple times in a row
  }

  stopAndResetTimer() {
    this.timerIsTicking = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.timeLeft = Number(this.selectedTimerOption) * 60;
  }

  minuteSelectorToggled() {
    this.minuteSelectorIsEnabled = !this.minuteSelectorIsEnabled;
  }
}
