import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager'

import { UrlParamService, MasterInfoService } from '@app/services';
import { AppSettingsService } from './settings/app-settings.service';
import { ClientSettings } from './settings/app-settings';
import { CombinedEventInfo } from '@app/models/combined-event-info';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private gtmService: GoogleTagManagerService,
    private urlParamService: UrlParamService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private masterInfoService: MasterInfoService,
    ) {
    // since browser back buttons disrupt the websocket connection, add popups to persuade user not to go back.
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage =
        'Are you sure you want to close the application?';
      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    });

    if (window.history && history.pushState) {
      history.pushState(null, document.title, location.href);
      window.addEventListener('popstate', function (event) {
        const leavePage = confirm(
          'Are you sure you want to go back? Changes will be lost.'
        );
        if (leavePage) {
          history.back();
        } else {
          history.pushState(null, document.title, location.href);
        }
      });
    }
  }
  private clientSettings: ClientSettings;
  combinedEventInfo: CombinedEventInfo;
  subs: Subscription[] = [];

  ngOnInit(): void {
    this.subs.push(
      this.appSettingsService.getSettings().subscribe(settings => {
        this.clientSettings = settings;
        // this.clientSettings.googleTagManager ? this.initGtm() : null;
      }),
      this.masterInfoService
        // call project event, no stakeholder...trying to get stakeholder with throw errors for the moderator / login view
        .getProjectEventDataNoStake()
        .subscribe(async (res) => {
          this.combinedEventInfo = res;
          if (this.clientSettings.googleTagManager && res) {
            this.initGtm()
          }
        }),
      )
  }

  // google tag manager
  private initGtm(){
    const eventGuid = this.urlParamService.getEventGuid();
    const stakeGuid = this.urlParamService.getStakeGuid();
    // set the ids
    this.gtmService.googleTagManagerId = this.clientSettings.googleTagManager.id;
    this.gtmService.config.id = this.clientSettings.googleTagManager.id;
    // push tags for route events
    // this.router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'pageview',
          // pageName: item.url,
          pageName: this.router.url,
          eventGuid: eventGuid,
          stakeGuid: stakeGuid || '',
          eventTitle: this.combinedEventInfo.meetingDetails.eventDetails.PE_NAME || '',
          projectId: this.combinedEventInfo.meetingDetails.eventDetails.PROJECT_ID || ''
        };
        this.gtmService.pushTag(gtmTag);
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
