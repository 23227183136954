import { CommentTopic } from './project-view';
import {KeyValue} from './key-value';

export class StakeholderInput {
    constructor() {
        this.topics = [];
    }
    topics: CommentTopic[];
    comment: string;
    ratingsValue: number;
    ratings: KeyValue[];
    responseType = 4;
    responses: KeyValue[];
}
