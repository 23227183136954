import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppSettings, ClientSettings } from "./app-settings";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private settings = new AppSettings();
  constructor() { }
  
  // using Observable, in case we want to switch to HTTP request
  getSettings(): Observable<ClientSettings> {
    const entity = environment.clientEntity ? environment.clientEntity : null;
    if (entity){
      const clientSettings = this.settings.clientSettings.filter(c => c.clientEntity === environment.clientEntity);
      // console.log('🚀 ~ file: app-settings.service.ts ~ line 19 ~ AppSettingsService ~ getSettings ~ clientSettings', clientSettings);
      if (clientSettings.length === 1) {
        return of<ClientSettings>(clientSettings[0]);
      } else {
        return of<ClientSettings>(this.settings.defaultSettings);
      }
    } else {
      return of<ClientSettings>(this.settings.defaultSettings);
    }
  }
  
}
