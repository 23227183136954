
export class EventsView {
    PE_ID: number;
    PROJECT_ID: number;
    PE_NAME: string;
    INVITEDCOUNT?: number;
    SIGNEDINCOUNT?: number;
    ATTENDEDCOUNT?: number;
    ATTENDEDANDINVITEDCOUNT?: number;
    ATTENDEDANDNOTINVITEDCOUNT?: number;
    ADDITIONALATTENDEDCOUNT?: number;
    EVENT_TYPE_ID: number;
    EVENT_DATE: Date;
    EVENT_START_HOUR: number;
    EVENT_START_MINUTE: number;
    EVENT_END_HOUR: number;
    EVENT_END_MINUTE: number;
    EVENT_SITE_NAME: string;
    REQUESTOR_NAME: string;
    REQUEST_DATE?: Date;
    ASSIGNED_STAFF: string;
    HQ: string;
    DISTRICT: string;
    EVENT_SITE_ADDRESS: string;
    EVENT_SITE_CITY: string;
    EVENT_SITE_ZIP_CODE: string;
    EVENT_TYPE: string;
    ACTIONS?: number;
    DAYS_UNTIL_DUE?: number;
    EVENT_PRIORITY_ID: number;
    EVENT_PRIORITY: string;
    EVENT_MANAGER_NAME: string;
    EVENT_MONTH: string;
    EVENT_YEAR?: number;
    PROJECT_DESC: string;
    PE_GUID: string;
    SITE_CAPACITY?: number;
    SITE_MEDIA_CAPABILITIES;
    PE_LAT?: number;
    PE_LON?: number;
    TOOLTIP_INVITED: string;
    TOOLTIP_SIGNEDINCOUNT: string;
    TOOLTIP_ATTENDED: string;
    TOOLTIP_ATTENDEDANDINVITED: string;
    TOOLTIP_ATTENDEDANDNOTINVITED: string;
    PE_S3_PATH: string;
    ONLINE_MEETING_GUID?: string;
    ONLINE_RESOURCE: string;
    VIDEO_PATH: string;
}
