import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { DomSanitizerService } from '@app/services/dom-sanitizer.service';
@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent implements OnInit, AfterViewInit {
  constructor(
    private domSanitizerService: DomSanitizerService
    ) {}

  @Input() isVideoFile: boolean;
  @Input() resourceUrl: string;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  safeUrl: SafeResourceUrl;

  async ngOnInit() {
    if (this.resourceUrl.indexOf('?') > -1) {
      this.safeUrl = this.domSanitizerService.getSafeUrl(
        `${this.resourceUrl}&playsinline=1`
      );
    } else {
      this.safeUrl = this.domSanitizerService.getSafeUrl(
        `${this.resourceUrl}?playsinline=1`
      );
    }
  }

  conditionallyAdjustCss() {
    /* IOWA-276 we have to do this via JS because we only want this adjustment to happen for
      meetings with podium enabled.
    */
    const embedContainerElement: any = document.querySelector('.embed-content');
    const resource: any = document.querySelector('#resource');
    if (window.innerWidth < 950) {
      const isPodiumEnabled = window.location.href.includes('stakeholder') 
      if (isPodiumEnabled) {
        embedContainerElement['style']['min-height']  = '400px';
        resource['style']['min-height']  = '400px';
      }
    }
  }

  ngAfterViewInit(){
    this.conditionallyAdjustCss();
    // this.videoLoadHandler();
  }

  /* WARNING: this broke the loading of video, needs further testing */
  private videoLoadHandler(){
    this.videoPlayer.nativeElement.addEventListener('loadeddata', (e) => {
      //Video should now be loaded but we can add a second check
      if(this.videoPlayer.nativeElement.readyState >= 3){
        this.videoPlayer.nativeElement.className = 'video-player';
      } else {
        this.videoPlayer.nativeElement.className = 'video-hidden';
      }
    });
  }
  
}

