export enum ClientEntities {
    Template = 0,
    Iowa = 1,
    KDOT = 2,
    TxDOT = 3,
    MassDOT = 4,
    EBR = 5,
    ESCR = 6,
    CTDOT = 7,
    NCTCOG = 8,
    VPIOutreach = 9, // AKA NJDOT, currently shared account for NJDOT and MoDOT
    WisDOT = 10,
    //Macomb = 11, DECOMISSIONED
    MaineDOT = 12,
    NTRC = 13,
    VTABSV = 14,
    SANDAG = 15,
    PennDOT = 16,
    MDOT = 17,
    MTA = 18,
    CalTrans = 19,
    IllinoisDOT = 20,
    MBTA = 21,
    INDOT = 22,
    MoDOT = 23,
    Denver = 24,
    Colorado = 25,
    VTRANS = 26,
    LaDOTD = 27,
    SBCTA = 28,
    NJTA = 29,
    ODOT = 30,
    TDOT = 31,
    Metro = 32,
    TemplateS123 = 99
}