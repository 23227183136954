import {EventsView} from './events-view';
import {StakeholderView} from './stakeholder-view';
import {ProjectView} from './project-view';
import {KeyValue} from './key-value';

export class MeetingDetails {
    constructor() {
        this.sources = [];
        this.categories = [];
        this.isError = false;
        this.error = '';
    }

    eventDetails: EventsView;
    projectDetails: ProjectView;
    projectGeometry: any;
    stakeholder: StakeholderView;
    sources: KeyValue[];
    categories: KeyValue[];
    isError: boolean;
    error: string;
}
