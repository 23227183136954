import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';

@Component({
  selector: 'app-disconnect-moderator-dialog',
  templateUrl: './disconnect-moderator-dialog.component.html',
  styleUrls: ['./disconnect-moderator-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisconnectModeratorDialogComponent extends BasicDialogComponent {

  htmlMessage: string;

  constructor(
    public dialogRef: MatDialogRef<DisconnectModeratorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
  ) {
    super(dialogRef, data);
  }

  forceDisconnect() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
