import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { PodiumDialogData } from '@app/dialogs/podium-dialog/podium-dialog.component';
import { MqttService } from '@app/services';
import { DialogNoticeService } from '@app/services';
import { AppStateService } from '@app/services';
import { MqttMessage, MqttOnlineMeeting } from '@app/models/mqtt';
import { CombinedEventInfo } from '@app/models/combined-event-info';
import * as homeActions from '@app/state/app.actions'
import * as fromHome from '@app/state/app.state';
import * as fromReducers from '@app/state/app.reducers';
import { ClientSettings } from '@app/settings/app-settings';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-podium',
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss'],
})
export class PodiumComponent implements OnInit {

  constructor(
    private dialogNoticeService: DialogNoticeService,
    private mqttService: MqttService,
    private appStateService: AppStateService,
    private store: Store<fromHome.State>,
    private translate: TranslateService
    ) { }

  @Input() combinedEventInfo: CombinedEventInfo;
  @Input() clientSettings: ClientSettings;
  @Input() meetingState: MqttOnlineMeeting;

  podiumEnabled = false;
  isAnonymous = false;
  genCommentIsEnabled = false;
  selectedLanguage: any = {value: 'en', viewValue: 'English'}

  // UI Text
  paragraph1 = `When enabled, click Join the Podium to submit a question or comment to the project team.  
  Your virtual podium comment will be entered into the official project record, may be read aloud or displayed on screen, 
  and will be responded to either live or post-event by the project team.`;
  btnText = 'Join the Podium';

  private subs: Subscription[] = [];
  private peId: number;

  async ngOnInit() {
    this.setInitialState();
    this.setUiText();
    this.subs.push(
      // gen comment
      this.mqttService.subModToggledGenComment.subscribe(
        (res: { payload: any }) => {
          if (res && res.payload !== null) {
            this.genCommentIsEnabled = res.payload === 'true' || res.payload === true;
          }
        }
      ),
      this.mqttService.subModToggledJoinPodium.subscribe((podium) => {
        if (podium) {
          this.podiumEnabled = this.appStateService.isPodiumEnabledState(podium);
        } else {
          const mqttMsg: MqttMessage = {
            onlineMeetingId: this.peId,
            payload: false
          };
          this.appStateService.isPodiumEnabledState(mqttMsg); // to trigger video 100% height on initial load
        }
      })
    );
    /* 
    this.store
    .pipe(select(fromReducers.getLanguage))
    .subscribe((langValue: string) => {
      // this.selectedLanguage = langValue;
      if (langValue==='en') this.selectedLanguage = {value: 'en', viewValue: 'English'}
      else if (langValue==='es-max') this.selectedLanguage = {value: 'es-mx', viewValue: 'Spanish'}
      this.translate.getTranslation(langValue).subscribe((res) => {
        if (res && res.hasOwnProperty('PODIUM')) {
          this.translatedJSON = res.PODIUM;
        }
      });
    });
    */
  }

  showPodiumDialog() {
    const startMsg = `You have opted to ${this.btnText}. Your comment or questions may be read
    aloud during the meeting.`;
    // const msg = this.genCommentIsEnabled ? `${startMsg}  To submit a 'general comment' or question privately,
    // please use the General Comment link on the side panel.` : `${startMsg}`;
    const podiumConfig: PodiumDialogData = {
      title: this.btnText,
      message: startMsg,
      combinedEventInfo: this.combinedEventInfo,
      width: 600,
      height: 600
    };
    this.dialogNoticeService.podium(podiumConfig);
  }

  private setInitialState(){
    this.peId = this.combinedEventInfo.meetingDetails.eventDetails.PE_ID;
    this.isAnonymous = this.combinedEventInfo.stakeholderView.IS_ANONYMOUS;
    // defaults will be used if this is null
    if (this.meetingState) {
      // podium enabled
      this.appStateService.isNullOrUndefined(this.meetingState, 'isPodiumEnabled') ? null : this.podiumEnabled = this.meetingState.isPodiumEnabled;
      // gen comments enabled
      this.appStateService.isNullOrUndefined(this.meetingState, 'isGenCommentEnabled') ? null : this.genCommentIsEnabled = this.meetingState.isGenCommentEnabled;
    }
  }


  private setUiText(){
    try {
      this.btnText = this.clientSettings.clientUiSettings.podiumBtnText;
    } catch {
      environment.isLocalHost ? 
      console.log('🚀 ~ file: podium.component.ts ~ line 106 ~ Using default button text') : null;
    }
    try {
      this.paragraph1 = this.clientSettings.clientUiSettings.podiumDescText;
    } catch {
      environment.isLocalHost ? 
      console.log('🚀 ~ file: podium.component.ts ~ line 112 ~ Using default paragraph text') : null;
    }
  }

}
