import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { KeyValue } from '@app/models';
import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})
export class ResponseRequestService {
    constructor(
        private http: HttpClient,
        private httpCommonService: HttpCommonService,
    ) {}

    results: KeyValue[];

    getResponseTypesAsObservable(): Observable<KeyValue[]> {
        const url = endpoints.API_METHODS.RESPONSE_REQUEST_TYPE_LU_GET;
        const options = {
            headers: this.httpCommonService.httpOptions.headers
        };
        return this.http.get<KeyValue[]>(url, options).pipe(
            map(res => this.parseResponseTypes(res)),
            tap(_ => console.log('Fetched Response Types Lookup')),
            catchError(this.httpCommonService.handleError<KeyValue[]>('ResponseRequestService: getResponseTypesAsObservable'))
        );
    }

    async getResponseTypes(): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.RESPONSE_REQUEST_TYPE_LU_GET;
            this.http.get(url)
                .toPromise().then(res => {
                    const list = [];
                    if (!res) {
                        reject('API Call Failed.');
                    }

                    for (let i = 0; i < (res as any[]).length; i++) {
                        const temp = new KeyValue(res[i].RESPONSE_REQUEST_TYPE_ID, res[i].RESPONSE_REQUEST_TYPE);
                        list.push(temp);
                    }
                    this.results = list;
                    resolve(this.results);
                }).catch(error => {
                    console.log(error);
                    reject('API Failed.');
            });
          });
          return promise;
    }

    private parseResponseTypes(res): KeyValue[] {
        const list = [];
        for (let i = 0; i < (res as any[]).length; i++) {
            const temp = new KeyValue(res[i].RESPONSE_REQUEST_TYPE_ID, res[i].RESPONSE_REQUEST_TYPE);
            list.push(temp);
        }
        this.results = list;
        return this.results;
    }

}
