import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PollQuiz } from '@app/models/poll-quiz';
import { PollQuizService } from '@app/services/poll-quiz.service';
import { MqttService } from '@app/services';
@Component({
  selector: 'app-poll-control',
  templateUrl: './poll-control.component.html',
  styleUrls: ['./poll-control.component.scss']
})
export class PollControlComponent implements OnInit, OnDestroy {

  constructor(
    private pollQuizService: PollQuizService,
    private mqttService: MqttService
  ) { }

  polls$ = new BehaviorSubject<PollQuiz[]>(null);
  pollIsEnabled = false;
  private subs: Subscription[] = [];

  ngOnInit(): void {
    this.getSlidoPolls();
    this.subs.push(
      this.pollQuizService.slidoPolls$.subscribe(polls => {
        if (polls){
          this.polls$.next(polls);
        }
      }),
      this.pollQuizService.toggledPollVisible$.subscribe(tpv =>{
        this.pollIsEnabled = tpv;
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getTooltip(poll: PollQuiz){
    return poll.visible ? 'Hide Audience Poll' : 'Show Audience Poll';
  }


  getSlidoPolls(): void{
    this.pollQuizService.getSlidoPolls().subscribe(polls => {
      if (polls){
        this.polls$.next(polls);
      }
    });
  }

  toggleVisibility(poll: PollQuiz) {
    poll.visible = !poll.visible;
    this.mqttService.pubModeratorVisiblePollQuiz(poll);
  }

  getVisibility(visible: boolean) {
    if (visible) {
      return 'visibility';
    } else {
      return 'visibility_off';
    }
  }

  pollEnabledToggled(){
    const isEnabled = this.pollIsEnabled ? true : false;
    this.mqttService.pubModeratorTogglePollQuiz(isEnabled);
  }

}
