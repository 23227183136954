import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { PublicBaseComponent } from '../public-base/public-base.component';
import { ProjectView , CommentTopic} from '@app/models';
import { CommentValidationService, CommentService } from '@app/services';

@Component({
  selector: 'app-public-topics',
  templateUrl: './public-topics.component.html',
  styleUrls: ['./public-topics.component.scss'],
  providers: []
})
export class PublicTopicsComponent extends PublicBaseComponent implements OnInit {
  isMobile = false; // to conditionally add mobile classes in html
  constructor(
    private commentValidationService: CommentValidationService,
    private formBuilder: FormBuilder,
    private commentService: CommentService

    ) {
    super();
  }

  @Input() projectView: ProjectView;
  form: FormGroup;
  topicData: CommentTopic[];

  ngOnInit(): void {
    if (window.screen.width <= 950) {
      this.isMobile = true; 
    }
    this.dataLoaded.next(true);
    // check for more than one option, throw error to console
    if (this.question.ModuleOptions.length > 1) {
      console.error('Public Topics - Got > 1 Module Option');
    }
    this.maxSelection = +this.question.ModuleOptions[0].VALUE;
    this.createForm();
    this.subs.push(
      this.commentValidationService.runValidation.subscribe(v => {
      // run validation
      if (v) {
        this.validateInputs();
      }
    }),
    this.commentValidationService.shouldSubmissionReset.subscribe(r => {
      // reset
      if (r) {
        this.resetSubmission();
      }
    })
    );
  }

  // override reset
  resetSubmission() {
    this.form.controls.topics.reset();
    this.commentValidationService.setValidation({topics: false});
  }

  // override bind input to Stakeholder Comment
  setStakeholderComment() {
    const topics = this.getSelectedTopics();
    this.commentService.stakeholderComment.CommentTopics = topics;
  }

  // override
  validateInputs(): void {
    this.setStakeholderComment();
    this.validateAllFormFields(this.form);
    const inputValid = this.form.valid;
    this.commentValidationService.setValidation({topics: inputValid});
  }

  getSelectedTopics(): number[] {
    const topics: number[] = [];
    this.form.value.topics.map((selected, i) => {
      if (selected) {
        topics.push(this.topicData[i].COMMENT_TOPIC_ID);
      }
    });
    return topics;
  }

  getSelectedTopicsText(): string[] {
    const topics: string[] = [];
    this.form.value.topics.map((selected, i) => {
      if (selected) {
        topics.push(this.topicData[i].COMMENT_TOPIC);
      }
    });
    return topics;
  }

  private createForm() {
    this.topicData = this.projectView.SelectedCommentTopics;
    // config required and max values validators
    const topicOptions = this.question.IS_REQUIRED ?
    this.formBuilder.array([], [this.maxSelectedCheckboxes(this.maxSelection), this.minSelectedCheckboxes(1)]) :
    this.formBuilder.array([], [this.maxSelectedCheckboxes(this.maxSelection)]);
    // create form
    this.form = this.formBuilder.group({
      topics: topicOptions
    });
    this.addControls();
  }

  private addControls() {
    this.topicData.forEach(_ => {
      const control = new FormControl();
      (this.form.controls.topics as FormArray).push(control);
    });
  }

}
