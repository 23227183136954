// import { createReducer, on, Action } from '@ngrx/store';
// import { initialAppState, IApp } from './app.state';
// import { login, loginSuccess, loginFail } from './app.actions';

// export const userFeatureKey = 'AppState';

// export const reducer = createReducer(
//   initialAppState as IApp,
//   on(login, (state) => ({
//     ...state,
//   })),
//   on(loginSuccess, (state) => ({
//     ...state,
//     authenticationMessage: null,
//   })),
//   on(loginFail, (state, { message }) => ({
//     ...state,
//     authenticationMessage: message,
//   }))
// );

// export function AppReducer(state: IApp, action: Action): IApp {
//   return reducer(state as IApp, action as Action);
// }

import * as fromRoot from './app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeActionTypes, HomeActions } from './app.actions';



const initialState: fromRoot.HomeState = {
  language: null,
}

const getHomeFeatureState = createFeatureSelector<fromRoot.HomeState>('home');

export const getLanguage = createSelector(
  getHomeFeatureState,
  (state) => state.language
);


export function reducer(state = initialState, action: HomeActions): fromRoot.HomeState {
  switch (action.type) {
    case HomeActionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
      default:
        return state;
    }
  }
  