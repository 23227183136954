import { Component, Inject, Input } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CombinedEventInfo } from '@app/models/combined-event-info';

import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';

export interface HelpDialogData extends BasicDialogData {
  combinedEventInfo: CombinedEventInfo;
}

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent extends BasicDialogComponent {

  htmlContent: string;
  combinedEventInfo: CombinedEventInfo;

  constructor(
    public dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HelpDialogData
  ) {
    super(dialogRef, data);
    this.combinedEventInfo = data.combinedEventInfo;
  }

}
