import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { ModeratorViewComponent } from '../app/online-hearings/moderator/moderator-view/moderator-view.component';
import { StakeholderViewComponent } from '../app/online-hearings/stakeholder/stakeholder-view/stakeholder-view.component';
import { PageUnauthorizedComponent } from './guards/page-unauthorized.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './shared/login/login.component';

const routes: Routes = [
  {
    path: 'moderator',
    component: ModeratorViewComponent,
    canActivate: [AuthGuard]
  },
  { path: 'stakeholder', component: StakeholderViewComponent },
  { path: 'unauthorized', component: PageUnauthorizedComponent },
  { path: 'login', component: LoginComponent },
  { path: '', component: IndexComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
