import { Component, OnInit, ViewChild, ViewChildren, QueryList, ViewContainerRef } from '@angular/core';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { PublicBaseComponent } from '../public-base/public-base.component';
import { StakeholderService } from '@app/services/stakeholder.service';
import { CommentValidationService } from '@app/services/comment-validation.service';
import { CommentService } from '@app/services/comment.service';

@Component({
  selector: 'app-public-slider',
  templateUrl: './public-slider.component.html',
  styleUrls: ['./public-slider.component.scss'],
  providers: [StakeholderService]
})
export class PublicSliderComponent extends PublicBaseComponent implements OnInit {
  constructor(
    private liveAnnouncer: LiveAnnouncer,
    private stakeholderService: StakeholderService,
    private commentValidationService: CommentValidationService,
    private commentService: CommentService
  ) {
    super();
    // bind 'this' so we can access ratings
    this.formatLabel = this.formatLabel.bind(this);
  }

  @ViewChild('ratingSlider', { static: false }) ratingSlider: MatSlider = null;
  @ViewChildren('ratingSlider', {read: ViewContainerRef}) viewContainerRefs: QueryList<any>;

  private ratings: any;
  private selectedProjectRating = -3; // default to neutral
  ngOnInit(): void {
    this.subs.push(
      this.stakeholderService.getSupportAsObservable().subscribe(s => {
        this.ratings = s;
        this.dataLoaded.next(true);
      }),
      this.commentValidationService.runValidation.subscribe(v => {
        // run validation
        if (v) {
          this.validateInputs();
        }
      }),
      this.commentValidationService.shouldSubmissionReset.subscribe(r => {
        // reset
        if (r) {
          this.resetSubmission();
        }
      })
    );
  }

  // override
  resetSubmission() {
    /*
    if (this.ratingSlider !== null || this.ratingSlider !== undefined) {
      this.ratingSlider.value = -3;
      this.configSlider();
    }*/
    this.commentValidationService.setValidation({rating: false});
  }

  sliderChange(event: MatSliderChange) {
    this.selectedProjectRating = event.value;
    // console.log(event);
    const label = this.formatLabel(event.value);
    // accessibility announcer
    this.liveAnnouncer.announce(label);
  }

  onRatingDropdownChange(event) {
    this.selectedProjectRating = event.value;
  }
  
  // override - bind input to Stakeholder Comment
  setStakeholderComment() {
    if (this.ratingSlider) {
      this.commentService.stakeholderComment.STAKEHOLDER_SUPPORT_ID = this.ratingSlider.value * -1;
    } else {
      // default if slider is not ready,ie after a reset
      this.commentService.stakeholderComment.STAKEHOLDER_SUPPORT_ID = 3;
    }
  }

  getStakeholderSupport(): number {
    if (this.ratingSlider) {
      return this.ratingSlider.value * -1;
    } else {
      // default if slider is not ready, ie after a reset
      return 3;
    }
  }

  formatLabel = (value: number) => {
    const a = this.ratings.filter(x => x.key === value)[0];
    return a.value;
  }

  // override
  validateInputs(): void {
    this.setStakeholderComment();
    // slider rating is always true, we don't have any custom validation rules
    this.commentValidationService.setValidation({rating: true});
  }

  /*
  private configSlider() {
    const n = this.ratingSlider._elementRef.nativeElement;
    const labelTextEl = n.getElementsByClassName('mat-slider-thumb-label-text')[0];
    const setRatingText = () => {
        const a = this.ratings.filter(x => x.key === this.ratingSlider.value)[0];
        labelTextEl.innerHTML = a.value;
    };
    setRatingText();
    this.ratingSlider.input.subscribe(() => setRatingText());
  }
  */

}
