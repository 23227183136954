import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommentService, MqttService } from '@app/services';
import { PomComment } from '@app/models';


@Component({
  selector: 'app-podium-comment-feed',
  templateUrl: './podium-comment-feed.component.html',
  styleUrls: ['./podium-comment-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PodiumCommentFeedComponent implements OnInit, OnDestroy {

  constructor(
    private cdr: ChangeDetectorRef,
    private commentService: CommentService,
    private mqttService: MqttService,
  ) { }

  @Input() peId: number;

  subs: Subscription[] = [];
  visibleComments: PomComment[] = [];
  commentsVisible = false;
  initalLoad = true; // used to track the first load vs. pomDisplayedComments.subscribe
  isExpanded = true;
  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
  async ngOnInit() {
    await this.getInitialVisibleComments(this.peId);
    // subscriptions
    this.subs.push(
      this.commentService.pomDisplayedComments.subscribe((comments) => {
        // filter only visible comments after inital load
        if (comments) {
          this.visibleComments = comments.filter(p => p.visible);
          this.visibleComments.reverse();
          this.cdr.markForCheck();
        }
        // used to ignore the first pass onInit()
        this.initalLoad = false;
      }),
      // only show comments if the podium has been enabled
      this.mqttService.subModToggledJoinPodium.subscribe((podium) => {
        if (podium && podium.payload === true) {
          this.commentsVisible = true;
        }
        if (podium && podium.payload === false) {
          this.commentsVisible = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // gets initial visible comments
  private async getInitialVisibleComments(peId) {
    if (peId) {
      const res: any = await this.commentService.pomGetInitialVisibleComments(peId);
      if (res) {
        this.visibleComments = res;
        this.visibleComments.reverse();
        this.cdr.markForCheck();
      }
    }
  }

}
