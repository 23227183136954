import { Injectable, EventEmitter  } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { User, UserManager } from 'oidc-client';
import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { HttpCommonService } from './http-common.service';
import { TokenManagerService } from '@app/services/token-manager.service';
import { environment } from '../../../src/environments/environment';
import { TokenResponse } from '@app/models/token-response';
import { UserModel } from '@app/models/user-model';

export interface VerifyModeratorPost {
  person_id: string;
  cookie_val: string;
}

export interface StakeholderCommentGet {
  peGuid: string;
}

const settings: any = {
  authority: 'https://localhost:7026/',
  client_id: 'MassDOT.Local.1.Meetings',
  client_secret: '388D45BA-A36B-4984-FA59-B187D329C207',
  redirect_uri: 'https://localhost:4200/auth.html',
  post_logout_redirect_uri: 'https://localhost:4200/',
  response_type: 'token',
  scope: 'openid profile api',
  silent_redirect_uri: 'https://localhost:4200/silent-renew.html',
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 4,
  filterProtocolClaims: true,
  loadUserInfo: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userManager: UserManager;
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();
  currentUser: User;
  loggedIn = false;
  public isAuthenticated = new BehaviorSubject<boolean>(false);

  authHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private httpCommonService: HttpCommonService,
    private tokenManagerService: TokenManagerService
  ) { 
    this.userManager = new UserManager(settings);

    this.userManager.getUser()
    .then((user) => {
      if (user) {
        this.loggedIn = true;
        this.currentUser = user;
        this.userLoadededEvent.emit(user);
      }
      else {
        this.loggedIn = false;
      }
    })
    .catch((err) => {
      this.loggedIn = false;
    });

  this.userManager.events.addUserLoaded((user) => {
    this.currentUser = user;
    this.loggedIn = !(user === undefined);
      if (!environment.production) {
        console.log('authService addUserLoaded', user);
      }
    });
  }

  checkAuthenticated(): boolean {
    const tokenData = this.tokenManagerService.retrieveToken();
    const tokenValid = tokenData?.access_token ? true : false;
    this.isAuthenticated.next(tokenValid);
    return tokenValid;
  }

  savePassedToken(token: string) {
    this.tokenManagerService.savePassedToken(token);
  }

  login(user: string, pass: string): Observable<any> {
    const url = endpoints.API_METHODS.LOGIN;
    const loginInfo = `grant_type=password&username=${user}&password=${pass}&scope=openid profile api&client_id=${environment.clientId}&client_secret=${environment.clientSecret}`;
    const options = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Accept': 'application/json',
        })
    };
    // should return a token
    return this.http.post(url, loginInfo, options).pipe(
      map((res) => res),
      tap((res) => {
        var result = res as TokenResponse
        if (result) {
          var jwtUser = this.decodeToken(result.id_token);
          this.tokenManagerService.store(result);
          this.tokenManagerService.storeIdentity(jwtUser);
          this.isAuthenticated.next(true);
        }
      }),
      catchError(
        this.httpCommonService.handleError<any>('AuthService: login', null, false)
      )
    );
  }

  hasModeratorPermission(): Observable<boolean> {
    // Grab user details from Identity UserInfo endpoint
    const url = endpoints.API_METHODS.USER_GET;
    const tokenData = this.tokenManagerService.retrieveToken();
    if (tokenData && tokenData.access_token) {
      const options = {
        headers: new HttpHeaders(
          {
            'Authorization': tokenData.token_type + ' ' + tokenData.access_token,
          })
      };
      return this.http.get(url, options).pipe(
        map(r => {
          // UserModel Object
          var result = r as UserModel;

          try {
            return result.permissions.indexOf("IS_ONLINE_MEETING") > -1;
          } catch {
            return false;
          }
        }),
        catchError(
          this.httpCommonService.handleError<any>('AuthService: hasModeratorPermission', null, false)
        )
      );
    } else {
      return new Observable<boolean>(sub => {
        sub.next(false);
        sub.complete();
      });
    }
  }

  private decodeToken(token: string = '') {
    if (token === null || token === '') { return { 'upn': '' }; }
    const parts = token.split('.');
    if (parts.length !== 3) {

        throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
        throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  private urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            // tslint:disable-next-line:no-string-throw
            throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }
}
