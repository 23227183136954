import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';

@Component({
  selector: 'app-disconnected-dialog',
  templateUrl: './disconnected-dialog.component.html',
  styleUrls: ['./disconnected-dialog.component.scss']
})
export class DisconnectedDialogComponent extends BasicDialogComponent {

  htmlMessage: string;
  showActionButtons = false;

  constructor(
    public dialogRef: MatDialogRef<DisconnectedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
  ) {
    super(dialogRef, data);
  }

}
