export const environment = {
  clientEntity: 12,
  production: true,
  siteUrl: 'https://my.mainedotpima.com',
  apiUrl: 'https://api.mainedotpima.com/api',
  authUrl: 'https://accounts.mainedotpima.com',
  mediaUrl: 'https://uploads.mainedotpima.com',
  awsRegion: 'us-east-1',
  gatewayUrl: 'https://4vha04hcej.execute-api.us-east-1.amazonaws.com/prod/',
  gatewayApiKey: 'AE4Cbxadz86vQMvFTKaCO20ILsSUsMa64Us4w6eV',
  mqttAppClientId: '5u9m990hv74p92ocgehhisvf1a',
  mqttIdentityPoolId: 'us-east-1:719ee63a-c61b-4aee-82a8-425484d79c5f',
  mqttEndpoint: 'aq76w1bm2qf0v-ats.iot.us-east-1.amazonaws.com',
  mqttUserPoolId: 'us-east-1_UKcUNRIR7',
  mqttEnvName: 'prod',
  isLocalHost: window.location.href.indexOf('localhost') > -1,
  clientId: 'MaineDOT.Production.1.Meetings',
  clientSecret: '388D45BA-A36B-4984-FA59-B187D329C207',
  };
