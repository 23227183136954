import { PollQuiz, PollQuizType } from '@app/models/poll-quiz';

export const NTRC_SLIDO_POLLS: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '3361b800-ca23-4cca-b5db-9eb84278271e',
        embedUrl: 'https://app.sli.do/event/ryrk9rr7/embed/polls/3361b800-ca23-4cca-b5db-9eb84278271e',
        iFrameHeight: '400px',
        title: 'Poll: Where Do You Live',
        desc: 'Which of the 4 regions do you live in?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '7038921d-1cdd-4e35-bb8b-7566966ac754',
        embedUrl: 'https://app.sli.do/event/ryrk9rr7/embed/polls/7038921d-1cdd-4e35-bb8b-7566966ac754',
        iFrameHeight: '400px',
        title: 'Poll: Rail Service Use',
        desc: 'Which regional rail services do you currently use?',
        visible: false
    }
]

export const NTRC_SLIDO_POLLS2: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '40f6f7ba-28de-4da6-b45b-be4107737acb',
        embedUrl: 'https://app.sli.do/event/8puhjlzk/embed/polls/40f6f7ba-28de-4da6-b45b-be4107737acb',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '1c149603-7f4a-406e-bc78-ea410a29be67',
        embedUrl: 'https://app.sli.do/event/8puhjlzk/embed/polls/1c149603-7f4a-406e-bc78-ea410a29be67',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    }
]

export const NTRC_SLIDO_POLLS3: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: 'a11a7538-578f-43f8-873c-0337f64fb8a7',
        embedUrl: 'https://app.sli.do/event/rdyn1ahl/embed/polls/a11a7538-578f-43f8-873c-0337f64fb8a7',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '91553795-266c-44e1-b2dc-f9c81a3cb734',
        embedUrl: 'https://app.sli.do/event/rdyn1ahl/embed/polls/91553795-266c-44e1-b2dc-f9c81a3cb734',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '44c8971e-e6b4-47c1-a1ca-a171daa28002',
        embedUrl: 'https://app.sli.do/event/rdyn1ahl/embed/polls/44c8971e-e6b4-47c1-a1ca-a171daa28002',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]

export const NTRC_SLIDO_06_17_2021: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '3f7987af-f1a5-4e6f-a2d1-5e243762ffc2',
        embedUrl: 'https://app.sli.do/event/matadz3w/embed/polls/3f7987af-f1a5-4e6f-a2d1-5e243762ffc2',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '918a9697-cf54-446b-8293-5595f8e5723f',
        embedUrl: 'https://app.sli.do/event/matadz3w/embed/polls/918a9697-cf54-446b-8293-5595f8e5723f',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: 'b7a4e4f3-c962-4bd1-bced-0327367f24f7',
        embedUrl: 'https://app.sli.do/event/matadz3w/embed/polls/b7a4e4f3-c962-4bd1-bced-0327367f24f7',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]

export const NTRC_SLIDO_06_22_2021: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '6255e0ec-36fa-4522-9553-82a83a789ee9',
        embedUrl: 'https://app.sli.do/event/c3maldh2/embed/polls/6255e0ec-36fa-4522-9553-82a83a789ee9',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '9cd56a20-9eea-4f16-9b81-3b214714dd1d',
        embedUrl: 'https://app.sli.do/event/c3maldh2/embed/polls/9cd56a20-9eea-4f16-9b81-3b214714dd1d',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '6c874626-362c-4de2-8844-a553a1fc628d',
        embedUrl: 'https://app.sli.do/event/c3maldh2/embed/polls/6c874626-362c-4de2-8844-a553a1fc628d',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]

// This meeting cancelled, left just in case
export const NTRC_SLIDO_06_24_2021: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '16bb7921-a160-4de8-8bd4-53f0b4866d1d',
        embedUrl: 'https://app.sli.do/event/sepfwmi6/embed/polls/16bb7921-a160-4de8-8bd4-53f0b4866d1d',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '5cac1d97-d752-4734-aa0e-ae24a71f00ca',
        embedUrl: 'https://app.sli.do/event/sepfwmi6/embed/polls/5cac1d97-d752-4734-aa0e-ae24a71f00ca',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '32343fca-df17-4d8c-8c2c-03988adffaea',
        embedUrl: 'https://app.sli.do/event/sepfwmi6/embed/polls/32343fca-df17-4d8c-8c2c-03988adffaea',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]

export const NTRC_SLIDO_06_28_2021: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: 'd6077971-6920-42f1-8851-19b42058ef0d',
        embedUrl: 'https://app.sli.do/event/ism0ju3r/embed/polls/d6077971-6920-42f1-8851-19b42058ef0d',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '8c14d56e-0386-40c6-9bb3-31864ea341f3',
        embedUrl: 'https://app.sli.do/event/ism0ju3r/embed/polls/8c14d56e-0386-40c6-9bb3-31864ea341f3',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '4ebadb5f-2d7a-4111-a04e-11f5d9f3f45a',
        embedUrl: 'https://app.sli.do/event/ism0ju3r/embed/polls/4ebadb5f-2d7a-4111-a04e-11f5d9f3f45a',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]

export const NTRC_SLIDO_06_29_2021: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '946f135a-f1f0-411c-a1c4-0ece93cae92f',
        embedUrl: 'https://app.sli.do/event/bybejuyl/embed/polls/946f135a-f1f0-411c-a1c4-0ece93cae92f',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: 'd0aefa34-541e-4201-a5a6-59abf491c068',
        embedUrl: 'https://app.sli.do/event/bybejuyl/embed/polls/d0aefa34-541e-4201-a5a6-59abf491c068',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: 'd812fb7a-c906-4914-8174-30a633000483',
        embedUrl: 'https://app.sli.do/event/bybejuyl/embed/polls/d812fb7a-c906-4914-8174-30a633000483',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]

export const NTRC_SLIDO_07_15_2021: PollQuiz[] = [
    {
        type: PollQuizType.SlidoPoll,
        hash: '00fe855a-8a88-4087-b157-fdb907595004',
        embedUrl: 'https://app.sli.do/event/ykqsfli8/embed/polls/00fe855a-8a88-4087-b157-fdb907595004',
        iFrameHeight: '200px',
        title: 'Poll: Travel Challenge',
        desc: 'What is your biggest travel challenge?',
        visible: true
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '1094d643-2f49-42e2-acc1-ac876d8dd463',
        embedUrl: 'https://app.sli.do/event/ykqsfli8/embed/polls/1094d643-2f49-42e2-acc1-ac876d8dd463',
        iFrameHeight: '400px',
        title: 'Poll: Where Would You Go?',
        desc: 'If you could take a train easily and safely to anywhere in the Megaregion, where would you go?',
        visible: false
    },{
        type: PollQuizType.SlidoQuiz,
        hash: '6b19e6e2-73cb-4606-9000-c1e8cc0948e4',
        embedUrl: 'https://app.sli.do/event/ykqsfli8/embed/polls/6b19e6e2-73cb-4606-9000-c1e8cc0948e4',
        iFrameHeight: '400px',
        title: 'Poll: Important Service Goals',
        desc: 'Tell us your three most important service goals.',
        visible: false
    }
]
