import { Component, OnInit, Input } from '@angular/core';

import { PublicBaseComponent } from '../public-base/public-base.component';
import { CommentValidationService, CommentService } from '@app/services';
import { MeetingDetails, ProjectView } from '@app/models';

@Component({
  selector: 'app-public-map',
  templateUrl: './public-map.component.html',
  styleUrls: ['./public-map.component.scss']
})
export class PublicMapComponent extends PublicBaseComponent implements OnInit {
  constructor(
    private commentValidationService: CommentValidationService,
    private commentService: CommentService,
  ) {
    super();
  }

  @Input() projectView:  ProjectView;
  @Input() meetingDetails:  MeetingDetails;

  mapX = null;
  mapY = null;

  ngOnInit(): void {
    // inital required check
    if (this.question.IS_REQUIRED && this.mapX === 0) {
      this.errorMessage = 'required';
    }
    this.dataLoaded.next(true);
    this.subs.push(
      this.commentValidationService.runValidation.subscribe(v => {
        // run validation
        if (v) {
          this.validateInputs();
        }
      }),
      this.commentValidationService.shouldSubmissionReset.subscribe(r => {
        // reset
        if (r) {
          this.resetSubmission();
        }
      })
    );
  }

  // override
  resetSubmission() {
    this.mapX = null;
    this.mapY = null;
    this.commentValidationService.setValidation({map: false});
  }

  // bind input to Stakeholder Comment
  setStakeholderComment() {
      this.commentService.stakeholderComment.COMMENT_LAT = this.mapY;
      this.commentService.stakeholderComment.COMMENT_LON = this.mapX;
  }

  handleYChanged(num: number) {
    this.mapY = num;
    this.errorMessage = '';
  }

  handleXChanged(num) {
    this.mapX = num;
    this.errorMessage = '';
  }

  // override
  validateInputs(): void {
    this.setStakeholderComment();
    if (this.question.IS_REQUIRED && this.mapX === 0) {
      this.commentValidationService.setValidation({map: false});
      this.errorMessage = 'required';
    } else {
      this.commentValidationService.setValidation({map: true});
    }
  }

}
