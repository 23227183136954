import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpCommonService } from '@app/services';
import { EventsView } from '@app/models';
import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { DocumentInfo, ImageTypeId } from '@app/models/documents';


@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    private httpCommonService: HttpCommonService
    ) { }


  getProjectDocuments(projectId: number): Observable<DocumentInfo[]>  {
    const url = `${endpoints.API_METHODS.PROJECT_DOCUMENT_GET}`;
    const params = {
      project_id: projectId,
      comment_form_image_type_id: ImageTypeId.PROJECT_DOCUMENT
    }
    const options = {
        params: this.httpCommonService.toHttpParams(params),
        headers: this.httpCommonService.httpOptions.headers
    };
    return this.http.get<DocumentInfo[]>(url, options).pipe(
        tap(
          data => {console.log('Fetched Project Documents', data),
          error => console.error(error)
        }),
        catchError(this.httpCommonService.handleError<DocumentInfo[]>('DocumentsService: getProjectDocuments'))
    );
  }

  getEventDocuments(peId: number): Observable<DocumentInfo[]> {
    const url = `${endpoints.API_METHODS.EVENT_DOCUMENT_GET}`;
    const params = {
      pe_id: peId,
      comment_form_image_type_id: ImageTypeId.PROJECT_DOCUMENT
    }
    const options = {
        params: this.httpCommonService.toHttpParams(params),
        headers: this.httpCommonService.httpOptions.headers
    };
    return this.http.get<DocumentInfo[]>(url, options).pipe(
        tap(
          data => {console.log('Fetched Event Documents', data),
          error => console.error(error)
        }),
        catchError(this.httpCommonService.handleError<DocumentInfo[]>('DocumentsService: getEventDocuments'))
    );
  }


}
