import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { StakeholderService, UrlParamService, ProjectEventService } from '@app/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-anonymous-stakeholder',
  templateUrl: './anonymous-stakeholder.component.html',
  styleUrls: ['./anonymous-stakeholder.component.scss']
})
export class AnonymousStakeholderComponent implements OnInit, OnDestroy {
  constructor(
    private projectEventService: ProjectEventService,
    private stakeholderService: StakeholderService,
    private urlParamService: UrlParamService
    ) { }

  @Input()
  title = '';
  @Input()
  message = '';

  @Input()
  addFrameClass = true;

  subs: Subscription[] = [];
  isAnonymous: BehaviorSubject<boolean> = new BehaviorSubject(false);
  registrationLink = '';

  ngOnInit(): void {
    const peGuid = this.urlParamService.getEventGuid();
    // subscriptions
    this.subs.push(
      this.stakeholderService.isStakeHolderAnonymous.subscribe(anon => {
        if (anon) {
          this.isAnonymous.next(anon);
        }
      }),
      this.projectEventService.getProjectEventAsObservable({id: peGuid}).subscribe(pe => {
        if (pe) {
          const projectId = pe.PROJECT_ID;
          this.registrationLink = `${environment.siteUrl}/public/event-registration/search?project_id=${projectId}&pe_guid=${peGuid}`;
        } else {
          console.error('Unable to fetch project event info', pe);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}
