import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { v1 as uuidv1 } from 'uuid';

const AWS = require('aws-sdk');
const AWSIoTData = require('aws-iot-device-sdk');

import { CommentService, DialogNoticeService } from '@app/services';
import { environment } from '@environments/environment';
import { MqttTopics, MqttMessage, MqttModeratorMessage, Moderator } from '@app/models/mqtt';
import { PomComment } from '@app/models';
import { PollQuiz } from '@app/models/poll-quiz';
import { PollQuizService } from './poll-quiz.service';

@Injectable({
  providedIn: 'root'
})
export class MqttService {
  constructor(
    private commentService: CommentService,
    private dialogNoticeService: DialogNoticeService,
    private pollQuizService: PollQuizService
    ) {}

  private awsConfig: any;
  private peId: number; // project event id
  private clientId: string; // MQTT client id
  private appendpeIdConnector = '_'; // for joining / splitting strings in client-disconnect lambda
  private isStakeholderConnector = '&'; // for joining / splitting strings in client-disconnect lambda
  private topicConnector = '/'; // for listening to wildcard topics in aws
  private MQTT_TOPICS: MqttTopics = new MqttTopics();
  private mqttClient: any; // Client
  private hasNetworkDisconnectDialogAppeared = false;


  subModForceDisconnect = new BehaviorSubject<MqttMessage>(null);
  subModMsg = new BehaviorSubject<MqttMessage>(null);
  subModToggledGenComment = new BehaviorSubject<MqttMessage>(null);
  subModToggledJoinPodium = new BehaviorSubject<MqttMessage>(null);
  subModToggledTimer = new BehaviorSubject<MqttMessage>(null);
  subModPodiumMsg = new BehaviorSubject<MqttModeratorMessage>(null);
  subStakeMsg = new BehaviorSubject<MqttMessage>(null);
  subStakeConnections = new BehaviorSubject<number>(null);
  subPingTest = new BehaviorSubject<boolean | null>(true);
  // Moderator Poll / Quiz Broadcast handled by PollQuizService vs directly in this service

  async connectAndSubscribe(peId: number, isStakeholder: boolean = false): Promise<boolean> {
      return new Promise(resolve => {
      this.peId = peId;
      // create topics - required for filtering the topics per meeting
      this.createMeetingTopics(peId);
      // create mqtt client id
      this.clientId = this.createMqttClientId(isStakeholder);

      if (environment.isLocalHost) {
        console.log('connectAndSubscribe()', {peId: this.peId, clientId: this.clientId});
      }

      // config AWS
      this.configAws();

      // configure cognito creds
      this.configCognito();

      // AWS.config.getCredentials((err) => {
      AWS.config.credentials.get((err) => {
        if (err) {
          console.log(AWS.config.credentials);
          throw err;
        } else {
          this.initMqttClient();
          resolve(true);
        }
      });
    });

  }

  checkIfDisconnectTopic(topic) {
    // removes wildcard and assigns disconnected topic as the topic contains the id of the disconnected client in place of '#'
    if (topic.includes(this.MQTT_TOPICS.DISCONNECT_TOPIC.slice(0, -1))) {
      return true;
    }
  }

  // here if needed
  getClientId(): string {
    return this.clientId;
  }

  getPeId(): number {
    return this.peId;
  }

  disconnectMqttClient() {
    this.mqttClient.end();
  }

  // publishes

  pubModeratorForceClose(moderators: Moderator[]) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        disconnectModerators: moderators
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.FORCE_MODERATOR_DISCONNECT,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorVisiblePollQuiz(poll: PollQuiz) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        poll: poll
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_VISIBLE_POLL_QUIZ,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorTogglePollQuiz(enabled: boolean) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        payload: enabled
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_TOGGLED_POLL_QUIZ,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorTogglePodium(enabled: boolean) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        payload: enabled
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_TOGGLED_JOIN_THE_PODIUM,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorToggleGenComment(enabled: boolean) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        payload: enabled
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_TOGGLED_GEN_COMMENT_ENABLED,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorToggleTimer(enabled: boolean) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        payload: enabled
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_TOGGLED_IS_TIMER_ENABLED,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorToggledMsg(msg: MqttModeratorMessage) {
    if (this.clientReady()) {
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_TOGGLED_PODIUM_MESSAGE,
        JSON.stringify(msg)
      );
    }
  }

  pubStakeholderComment(comment: string) {
    if (this.clientReady()) {
      this.mqttClient.publish(
        this.MQTT_TOPICS.STAKEHOLDER_TOPIC,
        JSON.stringify(comment)
      );
    }
  }

  pubAddDynamoComment(pomComment: PomComment) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        comment: pomComment
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.ADD_DYNAMO_COMMENT,
        JSON.stringify(msg)
      );
    }
  }

  pubModeratorToggleCommentVis(commentRef: any) {
    if (this.clientReady()) {
      const msg: MqttMessage = {
        onlineMeetingId: this.peId,
        comment: commentRef
      };
      this.mqttClient.publish(
        this.MQTT_TOPICS.MODERATOR_TOGGLED_COMMENT_VISIBILITY,
        JSON.stringify(msg)
      );
    }
  }

 pubPingIOTForConnectionStatus() {
    if (this.clientReady()) {
      this.mqttClient.publish(
        this.MQTT_TOPICS.PING_TEST_REQ
        );
    }
  }

  private createMqttClientId(isStakeholder: boolean): string {
    return `${this.peId}${this.appendpeIdConnector}${uuidv1()}${
      // uuid generates randomized unique id created from the system clock plus random values
      isStakeholder ? `${this.isStakeholderConnector}stakeholder` : `` // peId, stakeholder and mqttEnv name needs to be appended to clientId for the disconnect event
    }&env=${environment.mqttEnvName}`;
  }

  private initMqttClient() {
    // create client
    this.mqttClient = AWSIoTData.device({
      region: this.awsConfig.region,
      host: this.awsConfig.mqttEndpoint,
      clientId: this.clientId, // our generated mqtt client id
      protocol: 'wss',
      maximumReconnectTimeMs: 8000,
      debug: false,
      accessKeyId: AWS.config.credentials.accessKeyId,
      secretKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken
    });
    this.mqttClient.on('connect', () => {
      // subscribe to topics
      Object.keys(this.MQTT_TOPICS).map((i) =>
        this.mqttClient.subscribe(this.MQTT_TOPICS[i])
      );

      // publish user connect
      this.mqttClient.publish(
        this.MQTT_TOPICS.CONNECT_TOPIC,
        JSON.stringify({
          onlineMeetingId: this.peId,
          connectedClient: { id: this.clientId }
        })
      );
    });

    this.mqttClient.on('error', (err) => {
      console.error('mqttClient error:', err);
      this.subPingTest.next(false);
      if (!this.hasNetworkDisconnectDialogAppeared) {
        this.dialogNoticeService.networkDisconnect({
          title: 'NETWORK CONNECTION ERROR',
          message: '',
          htmlMessage: `You are no longer connected to the meeting due to network connection issues. Please ensure you are connected to the internet and click the refresh button on your browser.`,
          showActionButtons: false
        });
        this.hasNetworkDisconnectDialogAppeared = true;
      }
    });

    this.mqttClient.on('message', async (topic: string, payload) => {
      const msg: any = new TextDecoder('utf-8').decode(payload);
      const msgData: any = payload.length > 0 ? JSON.parse(msg) : null;
      // topic handler
      if (msgData) {
        await this.topicHandler(topic, msgData);
      } else {
        await this.topicHandler(topic);
      }
    });
  }

  private configAws() {
    // aws config
    this.awsConfig = {
      identityPoolId: environment.mqttIdentityPoolId,
      mqttEndpoint: environment.mqttEndpoint,
      region: environment.awsRegion,
      clientId: environment.mqttAppClientId, // this is the app id registered with Identity Pool
      userPoolId: environment.mqttUserPoolId
    };
  }

  private configCognito() {
    AWS.config.region = this.awsConfig.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.awsConfig.identityPoolId
    });
  }

  private async topicHandler(topic: string, msgData?: any): Promise<boolean> {
    if (this.checkIfDisconnectTopic(topic)) {
      // need to check this first because the disconnect topic is not appended the peId - it is built into the clientId because IoT handles disconnect events out of the box
      topic = this.MQTT_TOPICS.DISCONNECT_TOPIC;
    }

    // bail if we don't have a matching meeting topic
    const meetingMatch = this.meetingTopicMatch(topic);
    if (!meetingMatch && (topic !== this.MQTT_TOPICS.DISCONNECT_TOPIC && topic !== this.MQTT_TOPICS.PING_TEST_RESP)) {
      return meetingMatch;
    }

    switch (topic) {
      case this.MQTT_TOPICS.FORCE_MODERATOR_DISCONNECT:
        this.handleModeratorsForceDisconnect(msgData);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_JOIN_THE_PODIUM:
        this.handleModeratorToggleJoinPodium(msgData);
        break;
      case this.MQTT_TOPICS.STAKEHOLDER_TOPIC:
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_COMMENT_VISIBILITY:
        this.handleModeraterCommentToggled(msgData);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_PODIUM_MESSAGE:
        this.handleModeratorTogglePodiumMsg(msgData);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_IS_TIMER_ENABLED:
        this.handleModeratorToggleTimer(msgData);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_GEN_COMMENT_ENABLED:
          this.handleModeratorToggleGenComment(msgData);
          break;
      case this.MQTT_TOPICS.ADD_DYNAMO_COMMENT:
        this.handleAddDynamoComment(msgData);
        break;
      case this.MQTT_TOPICS.CONNECT_TOPIC:
        this.handleConnect(msgData);
        break;
      case this.MQTT_TOPICS.DISCONNECT_TOPIC:
        this.handleDisconnect(msgData);
        break;
      case this.MQTT_TOPICS.PING_TEST_RESP:
        if (this.subPingTest.value !== false) {
          this.handlePingTest(true);
        }
        break;
      case this.MQTT_TOPICS.MODERATOR_VISIBLE_POLL_QUIZ:
        this.handleModeratorVisiblePollQuiz(msgData);
        break;
      case this.MQTT_TOPICS.MODERATOR_TOGGLED_POLL_QUIZ:
          this.handleModeratorTogglePollQuiz(msgData);
          break;
      case this.MQTT_TOPICS.LAMBDA_DELETED_DISCONNECTED_CLIENT:
        /*
        message emitted from lambda trigger after deletion
        not currently being used: a potential improvement to disconnect as currently
        above DISCONNECT_TOPIC runs when anyone disconnects from any meeting
        */
        break;
      default:
        console.error('Unhandled case', topic);
    }
    return new Promise(resolve => {
      resolve(meetingMatch);
    });
  }

  private meetingTopicMatch(topic: string) {
    return topic.indexOf(`${this.peId}${this.topicConnector}`) > -1
      ? true
      : false;
  }

  private createMeetingTopics(peId: number) {
    // topics that don't requre an environment
    // ping test does not need an environment string appended to it - same for all envs
    const globalTopics = [
      this.MQTT_TOPICS.DISCONNECT_TOPIC,
      this.MQTT_TOPICS.PING_TEST_REQ,
      this.MQTT_TOPICS.PING_TEST_RESP
    ];
    Object.keys(this.MQTT_TOPICS).forEach((i) => {
      const topic = this.MQTT_TOPICS[i];
      if (globalTopics.indexOf(topic) > -1 ) {
        this.MQTT_TOPICS[i] = topic;
      } else {
        const newTopic = `${peId}${this.topicConnector}${topic}-${environment.mqttEnvName}`;
        this.MQTT_TOPICS[i] = newTopic;
      }
    });
  }

  private handlePingTest(success: boolean) {
    if (success) {
      this.subPingTest.next(success);
    }
  }

  private async handleModeratorsForceDisconnect(msgData: MqttMessage) {
    if (typeof msgData.disconnectModerators === 'object') {
      this.subModForceDisconnect.next(msgData);
      // call end function
      msgData.disconnectModerators.forEach(m => {
        if (this.clientId === m.id) {
          this.mqttClient.end();
        }
      });
      // reset dynamo table handled by lambda trigger
    }
  }


  private handleDisconnect(msgData: any) {
    // triggers lambda which also deletes from dynamo
    const disconnectingClientId = msgData.clientId;
    const disconnectingPeId = Number(disconnectingClientId.split('_').shift());
    if (disconnectingPeId === this.peId) {
      // only decrement counter if it's a stakeholder client
      if (environment.isLocalHost) {
        console.log(`client disconnected - id: ${disconnectingClientId}`);
      }
      if (disconnectingClientId.includes('stakeholder')) {
        this.decrementConnectedStakeholders();
      }
    }
  }

  private handleConnect(msgData: any) {
    const connectingClientId = msgData.connectedClient.id;
    if (environment.isLocalHost) {
      console.log(`client connected - id: ${connectingClientId}`);
    }
    // only increment counter if it's a stakeholder client and not self
    if (connectingClientId !== this.clientId && connectingClientId.includes('stakeholder')) {
      this.incrementConnectedStakeholders();
    }
  }

  private handleAddDynamoComment(msgData: any) {
    const dData = msgData as MqttMessage;
    this.subStakeMsg.next(dData);
  }

  private handleModeratorTogglePodiumMsg(msgData: any) {
    console.log('msgData: ', msgData);
    const pData = msgData as MqttModeratorMessage;
    console.log('pData: ', pData);
    this.subModPodiumMsg.next(pData);
  }

  private handleModeratorToggleJoinPodium(msgData: any) {
    const pData = msgData as MqttMessage;
    this.subModToggledJoinPodium.next(pData);
  }

  private handleModeratorVisiblePollQuiz(msgData: any) {
    const pData = msgData as MqttMessage;
    this.pollQuizService.setVisibleSlidoPoll(pData.poll);
  }

  private handleModeratorTogglePollQuiz(msgData: any) {
    const pData = msgData as MqttMessage;
    this.pollQuizService.setTogglePollVisibility(pData.payload);
  }

  private handleModeratorToggleTimer(msgData: any) {
    const pData = msgData as MqttMessage;
    this.subModToggledTimer.next(pData);
  }

  private handleModeratorToggleGenComment(msgData: any) {
    const pData = msgData as MqttMessage;
    this.subModToggledGenComment.next(pData);
  }

  private handleModeraterCommentToggled(msgData: any): void {
    const commentObj: PomComment = msgData.comment;
    const existingCommentOnState = this.commentService.pomDisplayedComments
      .getValue()
      .find((c) => c.id === commentObj.id);
    if (existingCommentOnState) {
      existingCommentOnState.visible = commentObj.visible;
      this.commentService.pomDisplayedComments.next(
        this.commentService.pomDisplayedComments
          .getValue()
          .filter((c) => c.visible === true)
      );
    } else {
      this.commentService.pomDisplayedComments.next([
        ...this.commentService.pomDisplayedComments.getValue(),
        commentObj
      ]);
    }
  }

  private clientReady(): boolean {
    if (!this.mqttClient) {
      console.error(`mqttClient Not Ready: cannot publish`);
      return false;
    }
    return true;
  }

  public incrementConnectedStakeholders() {
    this.subStakeConnections.next(
      this.subStakeConnections.getValue() + 1
    );
  }

  public decrementConnectedStakeholders() {
    this.subStakeConnections.next(
      this.subStakeConnections.getValue() - 1
    );
  }
}
