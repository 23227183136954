import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

import { PublicBaseComponent } from '../public-base/public-base.component';
import { CommentService, CommentValidationService, ResponseRequestService } from '@app/services';
import { KeyValue, ModuleOptions, StakeholderComment } from '@app/models';

@Component({
  selector: 'app-public-response',
  templateUrl: './public-response.component.html',
  styleUrls: ['./public-response.component.scss'],
})
export class PublicResponseComponent extends PublicBaseComponent implements OnInit, OnDestroy {

  constructor(
    private responseRequestService: ResponseRequestService,
    private commentValidationService: CommentValidationService,
    private formBuilder: FormBuilder,
    private commentService: CommentService,
  ) {
    super();
  }

  private responseData: KeyValue[];
  private responseSelection: number; // two way data binding
  private moduleOptions: ModuleOptions[];
  private isCheckBox = false;
  private checkBoxLabel = '';
  @ViewChild('emailCheckBox') emailCheckBox: MatCheckbox;
  form: FormGroup;

  ngOnInit(): void {
    this.subs.push(
      this.responseRequestService.getResponseTypesAsObservable().subscribe(rt => {
        this.responseData = rt as KeyValue[];
        this.moduleOptions = this.question.ModuleOptions;
        this.createForm();
        this.dataLoaded.next(true);
      }),
      this.commentValidationService.runValidation.subscribe(v => {
        // run validation
        if (v) {
          this.validateInputs();
        }
      }),
      this.commentValidationService.shouldSubmissionReset.subscribe(r => {
        // reset
        if (r) {
          this.resetSubmission();
        }
      })
    );
  }

  // override
  resetSubmission() {
    // reset response selection
    this.responseSelection = undefined;
    // this.setDefaultResponse();
    // reset checkbox
    if (this.isCheckBox) {
      this.emailCheckBox.checked = false;
    }
    this.commentValidationService.setValidation({response: false});
  }

  // override
  validateInputs(): void {
    this.setStakeholderComment();
    this.commentValidationService.setValidation({response: true});
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  // override bind input to Stakeholder Comment
  setStakeholderComment(): void {
    // check this.responseData for the options
    if (!this.isCheckBox) {
      // value is set via two-way data binding
      const type = this.responseSelection === undefined ? 4 : this.responseSelection;
      this.commentService.stakeholderComment.RESPONSE_REQUEST_TYPE_ID = type;
      return;
    }
    if (this.isCheckBox && this.emailCheckBox.checked) {
      // 2 is respond by email
      this.commentService.stakeholderComment.RESPONSE_REQUEST_TYPE_ID = 2;
      return;
    }
    // default set in StakeholderComment Class
    /*
    else {
      // 4 is do not send me a response
      this.commentService.stakeholderComment.RESPONSE_REQUEST_TYPE_ID = 4;
    }*/
    return;
  }


  private getDefaultResponse(): KeyValue {
    const sc = new StakeholderComment();
    return this.responseData.filter(v => v.key === sc.RESPONSE_REQUEST_TYPE_ID)[0];
  }

  private setDefaultResponse() {
    const i = this.getDefaultResponse();
  }

  private createForm() {
    const responseOptions = this.question.IS_REQUIRED ?
    this.formBuilder.array([], Validators.required) :
    this.formBuilder.array([]);
    const moduleOptions = this.formBuilder.array([]);
    // create form
    this.form = this.formBuilder.group({
      responses: responseOptions,
      moduleOptions: moduleOptions
    });
    this.addControls();
    this.setDefaultResponse();
  }

  private addControls() {
    // setup a checkbox if it's being used
    const checkBox = this.moduleOptions.filter(o => o.EXPRESSION === 'IS_RESPONSE_EMAIL' && o.VALUE === 'true' && o.IsEnabled);
    const checkBoxLabel = this.moduleOptions.filter(o => o.EXPRESSION === 'RESPONSE_EMAIL_LABEL' && o.IsEnabled);
    this.isCheckBox = checkBox.length > 0 ? true : false;
    this.checkBoxLabel = checkBoxLabel.length > 0 ? checkBoxLabel[0].VALUE : '';
    // create select options if we aren't using a checkbox
    if (!this.isCheckBox) {
      this.responseData.forEach(_ => {
        const control = new FormControl();
        (this.form.controls.responses as FormArray).push(control);
      });
    }
  }

}
