import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommentService} from './comment.service';

export interface ValidationResult {
  errors: boolean;
  complete: boolean;
}

export interface CommentValid {
  captcha?: boolean;
  choices?: boolean;
  comment?: boolean;
  map?: boolean;
  response?: boolean;
  rating?: boolean;
  stakeholder?: boolean;
  topics?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CommentValidationService {
  constructor(
    private commentService: CommentService
  ) {
  }

  private questionCount = 0;
  private inputValidation: CommentValid[] = [];

  // broadcast call to run validation - tells components to send in there input values
  private _runValidation = new BehaviorSubject<boolean>(false);
  runValidation = this._runValidation.asObservable();

  // broadcast validation result
  private _validationResult = new BehaviorSubject<ValidationResult>({errors: false, complete: false});
  validationResult = this._validationResult.asObservable();

  // broadcast reset submission - tells components to reset
  private _shouldSubmissionReset = new BehaviorSubject<boolean>(false);
  shouldSubmissionReset = this._shouldSubmissionReset.asObservable();


  resetSubmission(): void {
    // reset validation object - do this first
    // any new components will need to be added here
    this.inputValidation = []; // reset
    this._validationResult.next({errors: false, complete: false}); // reset validation result otherwise multiple submissions will run
    this._shouldSubmissionReset.next(true); // broadcast call to reset
  }

  validateAllResponses(): void {
    this._runValidation.next(true);
    // console.log('Current StakeHolderComment -->');
    // console.log(this.commentService.stakeholderComment);
  }

  // this will reset the validation
  setInitialQuestionCount(count): void {
    this.questionCount = count;
  }

  // individual features call this during runValidation.subscribe()
  // check validation is called after every set..until eventually things are ready, ie all true
  setValidation(input: CommentValid): void {
    if (this.inputValidation.length > 0) {
      if (Object.keys(input).length > 1) {
        throw new Error(`${input}: Only set one \'CommentValid\' type at a time`);
      } else {
        // determine the type
        const type = Object.keys(input)[0];
        // find the index of an existing item and update
        const index = this.getIndexByType(this.inputValidation, type);
        if (index > -1) {
          this.inputValidation[index] = input;
        } else {
          this.inputValidation.push(input);
        }
      }
    } else {
      this.inputValidation.push(input);
    }
    this.checkValidation();
  }

  // checks all the input validation items to see if they are true
  private checkValidation() {
      if (this.inputValidation.length === this.questionCount && !this._validationResult.value.complete) {
        console.log('Checking that all inputs are valid');
        // check if we have all the comment input added for validation
        const values = this.inputValidation.map(obj => obj[Object.keys(obj)[0]]);
        const isNotValid = values.filter( v => v === false); // check for errors
        // update behvavior subject
        if (isNotValid.length > 0) {
          // validation is not ready, ie has errors
          this._validationResult.next({errors: true, complete: false});
        } else {
          // broadcast that validation is complete
          this._validationResult.next({errors: false, complete: true});
        }
      } else {
        this._validationResult.next({errors: false, complete: false});
      }
  }

  private getIndexByType(arr: any[], type: string): number {
    let result = -1;
    for (let i = 0; i < arr.length; i++) {
      for (const key in arr[i]) {
        if (key === type) {
          result = i;
        }
      }
    }
    return result;
  }

}
