export class StakeholderComment {
    PROJECT_ID = 0;
    PE_ID = 0;
    PROJECT_NR = null;
    COMMENT_LAT = null;
    COMMENT_LON = null;
    COMMENT_CATEGORY_ID = 0;
    CommentTopics: number[];
    CommentChoices: number[];
    COMMENT_SOURCE_ID = 0;
    IS_RESPONSE_NEEDS_APPROVAL = 0;
    NOTES = '';
    RESPONSE_REQUEST_TYPE_ID = 4; // Default to 4, Do Not Send me a response, required for submission
    STAKE_COMMENT = '';
    STAKE_ID = 0;
    STAKEHOLDER_SUPPORT_ID = 3; // Default to 3, which is neutral
    TONE = ""; // REQUIRED TO POST - unused field that still has a key in PIMA
    constructor() {
        this.CommentTopics = [];
        this.CommentChoices = [];
    }
}

export interface PomComment {
    id: string;
    stakeId: number;
    comment: string;
    city: string;
    displayName: string;
    firstName: string;
    lastName: string;
    state: string;
    topics: string[];
    timeStamp: number;
    visible?: boolean;
    name?: string; // left from old model, avoids compiler error
}

// Old Comment Model - left for ref
/*
export interface PomComment {
    id: string;
    name: string;
    comment: string;
    visible?: boolean;
}
*/
