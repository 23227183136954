import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { MqttService, AppStateService } from '@app/services';
import { CombinedEventInfo } from '@app/models/combined-event-info';

@Component({
  selector: 'app-moderator-msg',
  templateUrl: './moderator-msg.component.html',
  styleUrls: ['./moderator-msg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModeratorMsgComponent implements OnInit, OnDestroy {

  constructor(
    private appStateService: AppStateService,
    private cdr: ChangeDetectorRef,
    private mqttService: MqttService
    ) { }
  @Input() combinedEventInfo: CombinedEventInfo;
  subs: Subscription[] = [];
  showMessage: BehaviorSubject<boolean> = new BehaviorSubject(null);
  message: BehaviorSubject<string> = new BehaviorSubject('');

  async ngOnInit() {
    await this.getInitialState();
    // subscriptions
    this.subs.push(
      // Moderator Podium Message
      this.mqttService.subModPodiumMsg.subscribe((m) => {
        if (m) {
          let text = m.moderatorMsg
          text = text.replace(
              /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
              '<a href="$1">$1</a>'
          );
          this.message.next(text);
          // this.message.next(m.moderatorMsg);
          this.showMessage.next(m.isModMsgEnabled);
        }
      })
      // End Subs
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private async getInitialState(): Promise<any> {
    // get initial state
    const peId = this.combinedEventInfo.meetingDetails.eventDetails.PE_ID;
    const res = await this.appStateService.getInitialState(peId);
    if (res && res.moderatorMsg && res.isModMsgEnabled) {
      let text = res.moderatorMsg
      text = text.replace(
          /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
          '<a href="$1">$1</a>'
      );
      this.message.next(text);
      // this.message.next(res.moderatorMsg);
      this.showMessage.next(true);
    }
    return res;
  }

}
