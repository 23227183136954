import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { StakeholderView, CommentTopic, KeyValue, StakeholderComment } from '@app/models';
import { HttpCommonService } from './http-common.service';

export interface StakeholderTopics {
    topics: CommentTopic[];
    sources: KeyValue[];
    categories: KeyValue[];
}

@Injectable({
    providedIn: 'root'
})
export class StakeholderService {
    constructor(
        private http: HttpClient,
        private httpCommonService: HttpCommonService
    ) {}

    stakeholder: StakeholderView;
    categories: KeyValue[];
    topics: CommentTopic[];
    sources: KeyValue[];
    supportLevel: KeyValue[];
    isStakeHolderAnonymous: BehaviorSubject<boolean> = new BehaviorSubject(null);


    getStakeholderAsObservable(stakeHolderGuid: string): Observable<StakeholderView> {
        const url = endpoints.API_METHODS.STAKEHOLDER_GET;
        const options = {
            headers: this.httpCommonService.httpOptions.headers,
            params: this.httpCommonService.toHttpParams({stakeGuid: stakeHolderGuid})
        };
        return this.http.get<StakeholderView>(url, options).pipe(
            map(res => res),
            tap(t => {
                console.log('Fetched Stakeholder');
                const stakeHolder = t as StakeholderView;
                if (stakeHolder.STAKE_ID === 0) {
                    throw new Error('Stakeholder is not found.');
                }
                const anon = this.isAnonymous(stakeHolder);
                stakeHolder.IS_ANONYMOUS = anon;
            }),
            catchError(this.httpCommonService.handleError<StakeholderView>('StakeholderService: getStakeholderAsObservable'))
        );
    }

    async getStakeholder(params: any): Promise<any> {
        const options = {
            params: this.httpCommonService.toHttpParams(params)
        };
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.STAKEHOLDER_GET;
            this.http.get(url, options)
                  .toPromise().then(res => {
                    if ((res as StakeholderView).STAKE_ID !== 0) {
                        this.stakeholder = (res as StakeholderView);
                        resolve(this.stakeholder);
                    } else {
                        reject('Stakeholder is not found.');
                    }
            }).catch(error => {
                console.log(error);
                reject('Stakeholder is not found.');
            });
        });
        return promise;
    }

    getTopicsAsObservable(): Observable<StakeholderTopics> {
        const url = endpoints.API_METHODS.STAKEHOLDER_COMMENT_GET;
        const options = {
            headers: this.httpCommonService.httpOptions.headers
        };
        return this.http.get<StakeholderTopics>(url, options).pipe(
            map(res => this.parseTopics(res)),
            tap(_ => console.log('Fetched Stakeholder Topics')),
            catchError(this.httpCommonService.handleError<StakeholderTopics>('StakeholderService: getTopicsAsObservable'))
        );
    }

    async getTopics(): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.STAKEHOLDER_COMMENT_GET;
            this.http.get(url)
            .toPromise().then(res => {
                // Save Event Info
                const list = [], list2 = [], list3 = [];
                if (!res) {
                    reject('API call Failed.');
                }

                for (let i = 0; i < res['commentTopics'].length; i++) {
                    if (res['commentTopics'][i].COMMENT_TOPIC_ID !== null) {
                        const temp: CommentTopic = {
                            COMMENT_TOPIC_ID: res['commentTopics'][i].COMMENT_TOPIC_ID,
                            COMMENT_TOPIC: res['commentTopics'][i].COMMENT_TOPIC,
                            IS_CHECKED: false
                        };
                        list.push(temp);
                    }
                }
                this.topics = list;

                for (let i = 0; i < res['commentSources'].length; i++) {
                    if (res['commentSources'][i].COMMENT_TOPIC_ID !== null) {
                        const temp2 = new KeyValue(res['commentSources'][i].COMMENT_SOURCE_ID, res['commentSources'][i].COMMENT_SOURCE);
                        list2.push(temp2);
                    }
                }
                this.sources = list2;

                for (let i = 0; i < res['commentCategories'].length; i++) {
                    if (res['commentCategories'][i].COMMENT_TOPIC_ID !== null) {
                        const temp3 = new KeyValue(res['commentCategories'][i].COMMENT_CATEGORY_ID, res['commentCategories'][i].COMMENT_CATEGORY);
                        list3.push(temp3);
                    }
                }
                this.categories = list3;

              resolve({topics: this.topics, sources: this.sources, categories: this.categories});
              });
        }).catch(error => {
          console.log(error);
          });
        return promise;
    }

    getSupportAsObservable(): Observable<KeyValue[]> {
        const url = endpoints.API_METHODS.STAKEHOLDER_SUPPORT_LU_GET;
        const options = {
            headers: this.httpCommonService.httpOptions.headers
        };
        return this.http.get<KeyValue[]>(url, options).pipe(
            map(res => this.parseSupport(res)),
            tap(_ => console.log('Fetched Stakeholder Support Lookup')),
            catchError(this.httpCommonService.handleError<KeyValue[]>('StakeholderService: getSupportAsObservable'))
        );
    }

    async getSupport(): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.STAKEHOLDER_SUPPORT_LU_GET;
            this.http.get(url)
            .toPromise().then(res => {
                // Save Event Info
                const list =  [];
                for (let i = 0; i < (res as any[]).length; i++) {
                    const temp = new KeyValue(res[i].STAKEHOLDER_SUPPORT_ID * -1, res[i].STAKEHOLDER_SUPPORT);
                    list.push(temp);
                }
                this.supportLevel = list;
              resolve(this.supportLevel);
              });
        }).catch(error => {
          console.log(error);
          });

          return promise;
    }

    postComment(st: StakeholderComment, params: any): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.STAKEHOLDER_COMMENT_POST;
            const options = {
                params: this.httpCommonService.toHttpParams(params)
            };
            this.http.post(url, st, options)
            .toPromise().then(res => {
                if (res) {
                    resolve(true);
                } else {
                    reject('Unknown Error.');
                }
              }).catch(error => {
                    console.log(error);
                    reject(error);
          });
        });
        return promise;
    }

    // parse support LU response
    private parseSupport(res): KeyValue[] {
        // Save Event Info
        const list =  [];
        for (let i = 0; i < (res as any[]).length; i++) {
            const temp = new KeyValue(res[i].STAKEHOLDER_SUPPORT_ID * -1, res[i].STAKEHOLDER_SUPPORT);
            list.push(temp);
        }
        this.supportLevel = list;
        return this.supportLevel;
    }

    // parse topic response
    private parseTopics(res): StakeholderTopics {
        // Save Event Info
        const list = [], list2 = [], list3 = [];
        for (let i = 0; i < res['commentTopics'].length; i++) {
            if (res['commentTopics'][i].COMMENT_TOPIC_ID !== null) {
                const temp: CommentTopic = {
                    COMMENT_TOPIC_ID: +res['commentTopics'][i].COMMENT_TOPIC_ID,
                    COMMENT_TOPIC: res['commentTopics'][i].COMMENT_TOPIC,
                    IS_CHECKED: false
                };
                list.push(temp);
            }
        }
        this.topics = list;

        for (let i = 0; i < res['commentSources'].length; i++) {
            if (res['commentSources'][i].COMMENT_TOPIC_ID !== null) {
                const temp2 = new KeyValue(res['commentSources'][i].COMMENT_SOURCE_ID, res['commentSources'][i].COMMENT_SOURCE);
                list2.push(temp2);
            }
        }
        this.sources = list2;

        for (let i = 0; i < res['commentCategories'].length; i++) {
            if (res['commentCategories'][i].COMMENT_TOPIC_ID !== null) {
                const temp3 = new KeyValue(res['commentCategories'][i].COMMENT_CATEGORY_ID, res['commentCategories'][i].COMMENT_CATEGORY);
                list3.push(temp3);
            }
        }
        this.categories = list3;
        const result: StakeholderTopics = {
            topics: this.topics,
            sources: this.sources,
            categories: this.categories
        };
        return result;
    }

    private isAnonymous(stakeHolder: StakeholderView): boolean {
        let anon = false;
        if (stakeHolder.FIRST_NAME.toLowerCase() === 'anonymous' && stakeHolder.MAIL1_ZIP_CODE === '00000') {
            anon = true;
        }
        this.isStakeHolderAnonymous.next(anon);
        return anon;
    }

}
