import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) { }

  showSpinner = false;
  message = '';
  hidePassword = true;

  ngOnInit(): void {
    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  async onSubmit() {
    this.showSpinner = true;
    this.loginInvalid = false;
    this.message = '';
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      try {
        const username = this.form.get('username').value;
        const password = this.form.get('password').value;
        // login
        this.authService.login(username, password).toPromise().then(res => {
          //if we have access token then we are good
          if (res) {
            // check for moderator permission
            this.authService.hasModeratorPermission().subscribe(p => {
              if (p) {
                this.showSpinner = false;
                this.message = 'Success!';
                setTimeout(() => {
                  this.router.navigate(['/moderator'], { queryParamsHandling: 'preserve' });
                }, 500);
              } else {
                this.message = 'User doesn\'t have moderator permissions';
                this.showSpinner = false;
              }
            });
          } else {
            this.loginInvalid = true;
            this.showSpinner = false;
          }
        });
      } catch (err) {
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

  togglePasswordVisible() {
    this.hidePassword = !this.hidePassword;
  }

}
