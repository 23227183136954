import { PomComment } from '@app/models';
import { PollQuiz } from './poll-quiz';

// NOTE: these comments need to match the 'IOT Topic Parameters in SAM template.yaml
// see cf-pima-lambda repo -> https://bitbucket.org/hntbtsc/cf-pima-lambda/src/master/

export class MqttTopics {
    CONNECT_TOPIC = 'pom-client-connect';
    DISCONNECT_TOPIC = '$aws/events/presence/disconnected/#'; // emits disconnect event for any (wildcard) clientId,
    FORCE_MODERATOR_DISCONNECT = 'pom-force-moderator-disconnect';
    LAMBDA_DELETED_DISCONNECTED_CLIENT = 'pom-client-disconnect';
    STAKEHOLDER_TOPIC = 'pom-stakeholder';
    MODERATOR_TOGGLED_COMMENT_VISIBILITY = 'pom-moderator-toggled-comment-visibility';
    MODERATOR_TOGGLED_GEN_COMMENT_ENABLED = 'pom-moderator-toggled-gen-comment-enabled';
    MODERATOR_TOGGLED_JOIN_THE_PODIUM = 'pom-moderator-toggled-join-the-podium';
    MODERATOR_TOGGLED_POLL_QUIZ = 'pom-moderator-toggled-poll-quiz';
    MODERATOR_TOGGLED_PODIUM_MESSAGE = 'pom-moderator-toggled-podium-message';
    MODERATOR_TOGGLED_IS_TIMER_ENABLED = 'pom-moderator-toggled-is-timer-enabled';
    MODERATOR_VISIBLE_POLL_QUIZ = 'pom-moderator-visible-poll-quiz';
    ADD_DYNAMO_COMMENT = 'pom-new-comment';
    PING_TEST_REQ = 'MQTT PINGREQ'; // topic name must be this to avoid AWS charges
    PING_TEST_RESP = 'MQTT PINGRESP'; // topic name must be this to avoid AWS charges
    constructor() {}
}

export interface Moderator {
    id: string;
}

export interface MqttMessage {
    clientId?: string;
    disconnectModerators?: Moderator[];
    comment?: PomComment;
    poll?: PollQuiz;
    id?: any;
    name?: any;
    payload?: any;
    onlineMeetingId?: number;
    topic?: string;
}

export interface MqttModeratorMessage {
    onlineMeetingId: number;
    isModMsgEnabled: boolean;
    moderatorMsg: string;
}

// see cf-pima-lambda repo -> https://bitbucket.org/hntbtsc/cf-pima-lambda/src/master/
// meeting object is also set in client-connect-put/index.js
export interface MqttOnlineMeeting {
    onlineMeetingId: number;
    status: string;
    comments: PomComment[];
    connectedClients: any[];
    connectedModerators: any[];
    isPodiumEnabled: boolean;
    isModMsgEnabled: boolean;
    isTimerEnabled: boolean;
    isGenCommentEnabled: boolean;
    moderatorMsg: string;
    isPollQuizEnabled?: boolean;
    visiblePollQuiz?: PollQuiz;
}
