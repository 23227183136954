import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import {
  DialogNoticeService,
  MqttService,
  CommentService,
  MasterInfoService,
  PageTitleService,
  AppStateService
} from '@app/services';

import { PomComment } from '@app/models';
import { Moderator, MqttOnlineMeeting } from '@app/models/mqtt';
import { CombinedEventInfo } from '@app/models/combined-event-info';
import { PollQuizService } from '@app/services/poll-quiz.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-moderator-view',
  templateUrl: './moderator-view.component.html',
  styleUrls: ['./moderator-view.component.scss']
})
export class ModeratorViewComponent implements OnInit, OnDestroy {
  constructor(
    private appStateService: AppStateService,
    private mqttService: MqttService,
    private commentService: CommentService,
    private masterInfoService: MasterInfoService,
    private dialogNoticeService: DialogNoticeService,
    private pollQuizService: PollQuizService,
    private pageTitleService: PageTitleService
  ) {}
  // Event Information
  combinedEventInfo: CombinedEventInfo;
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cancelLoadingMsg = '';
  resourceUrl: string;
  subs: Subscription[] = [];
  stakeholderMessages: BehaviorSubject<PomComment[]> = new BehaviorSubject([]);
  peId: number;
  connectedStakeholders: BehaviorSubject<number> = new BehaviorSubject(0);

  ngOnInit(): void {
    this.subs.push(
      // stakeholder message
      this.mqttService.subStakeMsg.subscribe((msg) => {
        if (msg) {
          this.stakeholderMessages.next([
            ...this.stakeholderMessages.getValue(),
            msg.comment
          ]);
        }
      }),
      this.mqttService.subStakeConnections.subscribe((val) => {
        this.connectedStakeholders.next(val);
      }),
      // force disconnect
      this.mqttService.subModForceDisconnect.subscribe(msg => {
        if (msg) {
          const clientId = this.mqttService.getClientId();
          const ids = msg.disconnectModerators.map(d => d.id);
          if (ids.indexOf(clientId) > -1) {
            this.cancelLoadingMsg = 'Disconnected.  If want to disconnect the moderator, please refresh your browser and choose the \"Disconnect Current Moderator/s\" option.';
            this.dialogNoticeService.disconnected({
              title: 'DISCONNECTED',
              message: 'Your connection has been closed by another moderator.'
            });
          }
        }
      }),
      // event info
      this.masterInfoService.getProjectEventDataNoStake().subscribe(async (res) => {
        this.combinedEventInfo = res;
        // set resource url, easier to reference
        this.resourceUrl = this.combinedEventInfo.resourceUrl;
        this.peId = this.combinedEventInfo.meetingDetails.eventDetails.PE_ID;
        // set page title
        environment.isLocalHost ? 
        this.pageTitleService.setPageTitle(`${this.combinedEventInfo.meetingDetails.eventDetails.PE_NAME} (${this.peId})`) :
        this.pageTitleService.setPageTitle(this.combinedEventInfo.meetingDetails.eventDetails.PE_NAME)
        await this.getInitialState(this.peId);
        this.dataLoaded.next(true);
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private async getInitialState(peId): Promise<boolean> {
    await this.mqttService.connectAndSubscribe(this.peId);
    const res = await this.commentService.pomGetInitialMeetingState(
      peId
    );
    this.handleInitialState(res, peId);
    // check for existing connected moderators
    const otherModerators = this.otherConnectedModerators(res);
    if (otherModerators) {
      return !otherModerators;
    } else {
      return !otherModerators;
    }
  }

  private handleInitialState(res: MqttOnlineMeeting, peId) {
    if (res && peId){
      // comments
      this.appStateService.isNullOrUndefined(res, 'comments') ? this.stakeholderMessages.next(null) : this.stakeholderMessages.next(res.comments);

      // connected clients
      this.appStateService.isNullOrUndefined(res, 'connectedClients') ? null : this.mqttService.subStakeConnections.next(
        res.connectedClients.length
      );

      // podium toggle
      this.appStateService.isNullOrUndefined(res, 'isPodiumEnabled') ? null : this.mqttService.subModToggledJoinPodium.next({
        payload: res.isPodiumEnabled
      });

      // moderator message
      this.appStateService.isNullOrUndefined(res, 'isModMsgEnabled') && 
      this.appStateService.isNullOrUndefined(res, 'moderatorMsg') ? null : this.mqttService.subModPodiumMsg.next({
        onlineMeetingId: peId,
        moderatorMsg: res.moderatorMsg,
        isModMsgEnabled: res.isModMsgEnabled
      });

      // timer
      this.appStateService.isNullOrUndefined(res, 'isTimerEnabled') ? null : this.mqttService.subModToggledTimer.next({
        payload: res.isTimerEnabled
      });

      // gen comments
      this.appStateService.isNullOrUndefined(res, 'isGenCommentEnabled') ? null : this.mqttService.subModToggledGenComment.next({
        payload: res.isGenCommentEnabled
      });

      // poll/quiz enabled
      this.appStateService.isNullOrUndefined(res, 'isPollQuizEnabled') ? null : this.pollQuizService.setTogglePollVisibility(res.isPollQuizEnabled);

      // visible poll/quiz
      this.appStateService.isNullOrUndefined(res, 'visiblePollQuiz') ? null : this.pollQuizService.setVisibleSlidoPoll(res.visiblePollQuiz);
    }
  }

  private otherConnectedModerators(meeting: MqttOnlineMeeting): boolean {
    if (meeting && meeting.connectedModerators && meeting.connectedModerators.length > 0) {
      const dialogRef = this.dialogNoticeService.disconnectModerator({
        title: 'MODERATOR ERROR',
        message: '',
        htmlMessage: `We are <b>showing ${meeting.connectedModerators.length}</b> moderator already connected.
        We only allow a single moderator (including browser tabs).`,
        showActionButtons: false
      });
      dialogRef.afterClosed().subscribe(async(answer) => {
        // force moderator disconnect
        if (answer) {
          this.forceDisconnect(meeting.connectedModerators);
        } else {
          // disconnect client
          this.mqttService.disconnectMqttClient();
          // cancel loading - shows a 'halt message'
          this.cancelLoadingMsg = 'Loading halted.  If want to disconnect the moderator, please refresh your browser and choose the \"Disconnect Current Moderator/s\" option.';
        }
      });
      return true;
    } else {
      return false;
    }
  }

  private forceDisconnect(connectedModerators: Moderator[]) {
    this.mqttService.pubModeratorForceClose(connectedModerators);
    // reload site
    location.reload();
  }

}
