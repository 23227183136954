import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { ProjectView } from '@app/models';
import { ENDPOINTS as endpoints } from './endpoint.service.config';
import { ProjectEventService } from './project-event.service';
import { HttpCommonService } from './http-common.service';


@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    constructor(
        private http: HttpClient,
        private httpCommonService: HttpCommonService,
        private projectEventService: ProjectEventService
    ) {}

    geometry: any;
    project: ProjectView;

    getProjectAsObservable(params: any): Observable<ProjectView> {
        return this.projectEventService.getProjectEventAsObservable(params)
        .pipe(mergeMap(projectEvent => {
            if (projectEvent.PROJECT_ID) {
                const param_id = {
                    id: projectEvent.PROJECT_ID
                };
                const url = endpoints.API_METHODS.PROJECT_GET;
                const options = {
                    params: this.httpCommonService.toHttpParams(param_id),
                    headers: this.httpCommonService.httpOptions.headers
                };
                return this.http.get<any>(url, options).pipe(
                    map(res => res),
                    tap(t => {
                        console.log('Fetched Project');
                        const parsed = this.parseProject(t);
                        return parsed;
                    }),
                    catchError(this.httpCommonService.handleError<any>('ProjectService: getProjectObservable'))
                );
            } else {
                this.httpCommonService.handleError<any>(`ProjectService: getProjectObservable - missing project id`);
            }
        }));
    }

    private parseProject(res: ProjectView): ProjectView {
        const result = res;
        // set checked on Topics
        result.SelectedCommentTopics.map(t => {
            t.IS_CHECKED = false;
        });
        // set checked on Choices
        result.SelectedCommentChoices.map(t => {
            t.IS_CHECKED = false;
        });
        return result;
    }

    async getProject(params: any): Promise<any> {
        const options = {
            params: this.httpCommonService.toHttpParams(params)
        };
        const promise = new Promise((resolve, reject) => {
            const apiURL = endpoints.API_METHODS.PROJECT_GET;
            this.http.get(apiURL, options)
                .toPromise().then(res => {
                    this.project = (res as ProjectView);
                    resolve(this.project);
                }).catch(error => {
                    console.log(error);
                    reject('API call failed. Video Not Found.');
                });
          });
          return promise;
    }

    getGeometry(params: any): Promise<any> {
        const options = {
            params: this.httpCommonService.toHttpParams(params)
        };
        const promise = new Promise((resolve, reject) => {
            const url = endpoints.API_METHODS.GEOMETRY_GET;
            this.http.get(url, options)
                .toPromise().then(res => {
                    this.geometry = res;
                    resolve(this.geometry);
                }).catch(error => {
                    console.log(error);
                    reject('API call failed. Geometry not found.');
                });
          });
        return promise;
    }

}
