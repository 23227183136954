import { Component, OnInit, OnDestroy } from '@angular/core';
import { MqttService } from '@app/services';

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {
  connected = false;
  intervalId: ReturnType<typeof setTimeout>;
  constructor(private mqttService: MqttService,
    ) {}

  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.mqttService.pubPingIOTForConnectionStatus();
    }, 30000); // minimum 30 second interval is free for this aws topic
    this.mqttService.subPingTest.subscribe(res => {
      if (res) {
        this.connected = res;
      } else {
        this.connected = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
