import { Component, ElementRef, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription} from 'rxjs';
import * as _ from 'underscore';
import { loadModules } from 'esri-loader';
import esri = __esri; // Esri TypeScript Types

import { ProjectView } from '@app/models';
import { environment } from '@environments/environment';
import { CommentValidationService } from '@app/services/comment-validation.service';
import { DialogNoticeService } from '@app/services/dialog-notice.service';

const isDesMoinesIcm = () => {
    return window.location.href.indexOf('2b44bd2b-c921-4784-a6b4-d8fda52a785d') > -1;
};

@Component({
    selector: 'esri-map',
    templateUrl: './esri-map.component.html',
    styleUrls: ['./esri-map.component.scss'],
})

export class EsriMapComponent implements OnInit, OnDestroy {
    @Input() geometry: any;
    @Input() projectView: ProjectView;
    @Output() mapYChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() mapXChange: EventEmitter<number> = new EventEmitter<number>();
    subs: Subscription[] = [];
    mapY: number = null;
    mapX: number = null;
    map: esri.WebMap = null;
    view: esri.MapView = null;
    pinLayer: esri.GraphicsLayer = null;
    projectLayer: esri.GraphicsLayer = null;
    defaultCenter = [-87.63, 41.85]; // default map center (Chicago) if we don't have a Web Map Id
    defaultZoom = 10; // default map zoon if we don't have a Web Map Id
    defaultPinMarkerColor = [0, 255, 0]; // default pin marker color
    isWebMap = false;
    isIowaDot = window.location.href.indexOf('iowadotpi.com') > -1; // this is hacky, we should switch to entity specific settings files
    iowaDefaultCenter = [-93.518, 41.95]; // default map center (Iowa) if we don't have a Web Map Id
    iowaDefaultZoom = 6;

    constructor(
        private el: ElementRef,
        private commentValidationService: CommentValidationService,
        private dialogNoticeService: DialogNoticeService,
    ) {}

    async ngOnInit() {
        this.subs.push(
            this.commentValidationService.shouldSubmissionReset.subscribe(r => {
                // reset
                if (r) {
                this.resetSubmission();
                }
            }),
        );
        this.initMap();
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    public zoomProject() {
        this.zoomToProject();
    }

    async initMap() {
        const view = await this.createMapView();
        // bind the view
        this.view = view;
        // callback when view is ready
        this.view.when(async() => {
            await this.setupLayers();
            if (!this.isWebMap && this.isIowaDot) {
                await this.zoomToProject();
            }
            view.on('resize', evt => {
                // keep focus on pin
                this.mapResizeHandler();
                // this.centerAndZoomFeature();
            });
            view.on('click', evt => {
                view.hitTest(evt).then(() => {
                    this.viewClickHandler(evt);
                });
            });
        });
    }

    private resetSubmission() {
        this.mapYChange.emit(0);
        this.mapXChange.emit(0);
        /*
        if (this.pinLayer !== null || this.pinLayer !== undefined) {
            this.pinLayer.removeAll();
        }*/
    }

    private mapResizeHandler() {
        const geom = this.pinLayer.graphics.getItemAt(0);
        if (geom) {
            this.view.goTo(geom);
        }
    }

    private viewClickHandler(evt) {
        this.mapY = evt.mapPoint.y;
        this.mapX = evt.mapPoint.x;

        this.mapYChange.emit(this.mapY);
        this.mapXChange.emit(this.mapX);

        if (this.pinLayer) {
            this.updatePinLayer(evt.mapPoint);
        }
    }

    private async updatePinLayer(mapPoint: esri.Point) {
        // Load the modules for the ArcGIS API for JavaScript
        const [Graphic, SimpleMarkerSymbol] = await loadModules([
            'esri/Graphic', 'esri/symbols/SimpleMarkerSymbol'
        ]);

        // Create a symbol for drawing the point
        const markerSymbol = new SimpleMarkerSymbol({
            color: this.defaultPinMarkerColor
        });

        // Create a graphic and add the geometry and symbol to it
        const pointGraphic = new Graphic({
            geometry: mapPoint,
            symbol: markerSymbol
        });
        // update graphics layer
        this.pinLayer.removeAll();
        this.pinLayer.add(pointGraphic);
    }

    private async createMapView(): Promise<esri.MapView> {
        // Load the modules for the ArcGIS API for JavaScript
        const [Map, MapView, WebMap] = await loadModules(['esri/Map', 'esri/views/MapView', 'esri/WebMap'
        ]);
        // check for / handle a web map id
        let view;
        if (this.projectView.MAP_ID) {
            this.map = new WebMap({
                portalItem: {
                    id: this.projectView.MAP_ID
                  }
            });
            this.map.when(f => {
                console.log('webmap loaded successfully');
            }).catch(e => {
                this.dialogNoticeService.error({
                    title: 'MAP LOAD ERROR',
                    message: `${e.message}.  ${e.details.error.message}.`
                });
                this.displayMapError(this.el.nativeElement);
                console.error(e);
            });
            view = new MapView({
                map: this.map,
                container: this.el.nativeElement,
                slider: true
            });
            this.isWebMap = true;
        } else {
            this.map = new Map({
                basemap: 'topo',
                autoResize: true,
                zoom: 7,
            });
            view = new MapView({
                map: this.map,
                container: this.el.nativeElement,
                zoom: this.isIowaDot ? this.iowaDefaultZoom : this.defaultZoom,
                center: this.isIowaDot ? this.iowaDefaultCenter : this.defaultCenter,
                slider: true,
                // This ensures that when going fullscreen
                // The top left corner of the view extent
                // stays aligned with the top left corner
                // of the view's container
                // resizeAlign: "top-left"
            });
        }
        return view;
    }

    private displayMapError(mapElement: HTMLElement) {
        const id = 'map-error-container';
        const mapErrorSpan = document.getElementById(id);
        if (!mapErrorSpan) {
            const errorSpan = document.createElement('span');
            errorSpan.innerHTML = 'MAP LOAD ERROR';
            errorSpan.style.color = '#ED0E06';
            errorSpan.style.fontWeight = 'bold';
            errorSpan.style.width = '100%';
            errorSpan.style.margin = 'auto';
            mapElement.appendChild(errorSpan);
        }
    }

    private async setupLayers() {
        const [GraphicsLayer, FeatureLayer] = await loadModules(['esri/layers/GraphicsLayer', 'esri/layers/FeatureLayer']);
        // instantiate layers
        // let selectionLayer = new GraphicsLayer({title: 'selection'});
        this.projectLayer = new GraphicsLayer({title: 'project layer'});
        this.pinLayer = new GraphicsLayer({title: 'marker'});
        // this.map.add(selectionLayer);
        this.map.add(this.projectLayer);
        this.map.add(this.pinLayer);

        if (isDesMoinesIcm()) {
            this.map.add(
                new FeatureLayer({
                    url: 'https://gis05s.hdrgateway.com/arcgis/rest/services/Nebraska/DM_ICM_ProjectArea/MapServer/0'
                })
            );
        }
    }

    private async zoomToProject() {
        if (this.geometry) {
            await this.centerAndZoomFeature();
        }
    }

    private async centerAndZoomFeature() {
        if (this.geometry.length > 0) {
            await this.geometry.map(async(feature) => {
                const geom = feature.geometry;
                geom['spatialReference'] = feature.spatialReference;
                const geomType = feature.geometryType;
                // old geometry api call returned Esri FeatureSet, new PIMA endpoint just returns geometry and attributes
                switch (geomType) {
                    case 'esriGeometryPolyline':
                        await this.addPolyline(geom);
                        break;
                    case 'esriGeometryPolygon':
                        this.addPolygon(geom);
                        break;
                    case 'point':
                        await this.addPoint(geom);
                        break;
                    default:
                        console.error('Unhandled geometry type');
                        break;
                }
            });
            this.view.goTo(this.projectLayer.graphics);
            this.view.zoom = isDesMoinesIcm() ? 9 : 12;
        }
    }

    private async addPolyline(geometry) {
        const [Polyline, SimpleLineSymbol, Graphic] = await loadModules([
            'esri/geometry/Polyline',
            'esri/symbols/SimpleLineSymbol',
            'esri/Graphic'
        ]);
        const line = new Polyline({
            hasZ: false,
            hasM: false,
            paths: geometry.paths,
            spatialReference: { wkid: geometry.spatialReference.wkid }
        });

        const lineSymbol = new SimpleLineSymbol({
            color: [255, 0, 0],
            width: 3,
        });

        const graphic = new Graphic({
            geometry: line,
            symbol: lineSymbol
        });
        this.projectLayerAddGraphic(graphic);
    }

    private async addPolygon(geometry) {
        const [Graphic] = await loadModules([
            'esri/Graphic'
        ]);
        const polygon = {
            geometry: {
                'paths': geometry.paths,
                'spatialReference': { 'wkid': geometry.spatialReference.wkid }
            },
            'symbol': {
                'color': [0, 0, 0, 64], 'outline': {
                    'color': [0, 0, 0, 255],
                    'width': 1, 'type': 'esriSLS', 'style': 'esriSLSSolid'
                },
                'type': 'esriSFS', 'style': 'esriSFSSolid'
            }
        };
        const graphic = new Graphic(polygon);
        this.projectLayerAddGraphic(graphic);
    }

    private async addPoint(geometry) {
        const [Graphic, Point, PictureMarkerSymbol, SpatialReference] = await loadModules([
            'esri/Graphic',
            'esri/geometry/Point',
            'esri/symbols/PictureMarkerSymbol',
            'esri/geometry/SpatialReference'
        ]);
        const point = new Point(geometry.x, geometry.y, new SpatialReference({ wkid: geometry.spatialReference.wkid }));
        const projectpin = new PictureMarkerSymbol({
            'url': environment.siteUrl + '/Content/images/map-pin-md.png',
            'height': 45,
            'width': 28,
            'yoffset': 22.5,
        });
        const graphic = new Graphic(point, projectpin);
        this.projectLayerAddGraphic(graphic);
    }

    private projectLayerAddGraphic(graphic) {
        if (!isDesMoinesIcm()) {
            if (graphic) {
                this.projectLayer.add(graphic);
            }
        }
    }

    
}
