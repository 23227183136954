import { OnInit, Component, ErrorHandler, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';


import { 
    ProjectEventService,
    StakeholderService,
    ResponseRequestService,
    ProjectService,
    DialogNoticeService,
     MasterInfoService } from '@app/services';
import { environment } from '@environments/environment';
import { StakeholderInput, MeetingDetails, PublicMeetingInfo } from '@app/models';
import { CombinedEventInfo } from '@app/models/combined-event-info';
import { PageTitleService } from '@app/services/page-title.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [ProjectEventService, StakeholderService, ResponseRequestService, ProjectService],
})
export class IndexComponent implements OnInit, OnDestroy, ErrorHandler {
  constructor(
      private dialogNoticeService: DialogNoticeService,
      private masterInfoService: MasterInfoService,
      private pageTitleService: PageTitleService
  ) {}

  // Event Information
  meetingDetails: MeetingDetails = new MeetingDetails();
  // Input Fields
  selections: StakeholderInput;
  // current Guid for passing to other components
  publicMeetingInfo: PublicMeetingInfo;
  // Video Link
  videoURL: any;
  isVideo: boolean;
  // flag for production
  isProduction = environment.production;
  // UI
  showAccordion = false;
  // Event Information
  combinedEventInfo: CombinedEventInfo;
  // Service data loading
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subs: Subscription[] = [];
  resourceUrl: string;
  peId: number;

  onSelectedIndexChange(index) {
      if (index === 1 && !this.meetingDetails.isError) {
          this.showAccordion = true;
      }
  }

  async ngOnInit() {
    // this.dialogTests();
    // subscriptions
    this.subs.push(
      // event info
      this.masterInfoService.getAllProjectEventData(true).subscribe((res) => {
        this.combinedEventInfo = res;
        console.log('🚀 ~ file: index.component.ts ~ line 64 ~ IndexComponent ~ this.masterInfoService.getAllProjectEventData ~ this.combinedEventInfo', this.combinedEventInfo);
        // set resource url, easier to reference
        this.resourceUrl = this.combinedEventInfo.resourceUrl;
        // set meeting details, easier to ref
        this.meetingDetails = this.combinedEventInfo.meetingDetails;
        // set PE ID
        this.peId = this.combinedEventInfo.meetingDetails.eventDetails.PE_ID;
        // set page title
        this.pageTitleService.setPageTitle(this.meetingDetails.eventDetails.PE_NAME);
        this.dataLoaded.next(true);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  /* - LEFT FOR REFERENCE
  async ngOnInit() {

      let pm = null;
      const eventGuid = this.urlParamService.getEventGuid(); // this.getParameterByName('online_meeting_guid');
      const stakeGuid = this.urlParamService.getStakeGuid(); // this.getParameterByName('stake_guid');
      const pm2 = {stakeGuid: stakeGuid };
      const pm3 = { id: null };
      const pm4 = { pins: null };

      if (stakeGuid === null || stakeGuid === '') {
          this.meetingDetails.isError = true;
          this.meetingDetails.error = 'The link is invalid. The stakeholder information is not referenced in the link.';
      }

      if (eventGuid === null || eventGuid === '') {
          this.meetingDetails.isError = true;
          this.meetingDetails.error = 'The presentation is invalid. The presentation information is not referenced in the link.';
      }

      if (!this.meetingDetails.isError) {
          pm = {peGuid: eventGuid};

          // set public meeting info aka guid, gets passed to questions component
          this.publicMeetingInfo = pm;

          // Get Video URL
          const promise = await this.projectEventService.getPresentation(pm)
          .then(async() => {
              const url = this.projectEventService.results;
              this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
              this.meetingDetails.eventDetails = this.projectEventService.projectEvent;
              console.log('PE_ID: ' + this.meetingDetails.eventDetails.PE_ID);
              pm3.id = this.meetingDetails.eventDetails.PROJECT_ID;
              return await this.projectService.getProject(pm3);
          }, rejected => {
              this.meetingDetails.error = 'The Project Event does not exist.  Please check your link and try again.';
          })
          .then(values => {
                  this.meetingDetails.projectDetails = this.projectService.project;
                  pm4.pins = this.meetingDetails.projectDetails.PIN;
                  // return this.projectService.getGeometry(pm4);
          })
          // .then(values => {
          //    this.meetingDetails.projectGeometry = this.projectService.geometry;
          // })
          .catch(error => {
              console.log(error);
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          });

          // Get User Information
          const promise2 = await this.stakeholderService.getStakeholder(pm2).then(x => {
              this.meetingDetails.stakeholder = this.stakeholderService.stakeholder;
          }, rejected => {
              this.meetingDetails.error = 'The stakeholder does not exist.  Please check your link and try again.';
          }).catch(error => {
              console.log(error);
              this.meetingDetails.error = 'The stakeholder does not exist.  Please check your link and try again.';
          });
          // Get User Input controls
          const promise3 = await this.stakeholderService.getTopics().then(x => {
              if (!this.selections) {
                  this.selections = new StakeholderInput();
              }
              this.selections.topics = this.stakeholderService.topics;
              this.meetingDetails.sources = this.stakeholderService.sources;
              this.meetingDetails.categories = this.stakeholderService.categories;
          }, rejected => {
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          }).catch(error => {
              console.log(error);
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          });
          // Get level of support options
          const promise4 = await this.stakeholderService.getSupport().then(x => {
              if (!this.selections) {
                  this.selections = new StakeholderInput();
              }
              this.selections.ratings = this.stakeholderService.supportLevel;
          }, rejected => {
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          }).catch(error => {
              console.log(error);
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          });
          // Get response types
          const promise5 = await this.responseRequestService.getResponseTypes().then(x => {
              if (!this.selections) {
                  this.selections = new StakeholderInput();
              }
              this.selections.responses = this.responseRequestService.results;
          }, rejected => {
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          }).catch(error => {
              console.log(error);
              this.meetingDetails.error = 'The was an issue loading the data, please refresh the page.';
          });

          await Promise.all([promise, promise2, promise3, promise4, promise5]).then(values => {
              if (this.meetingDetails.error.length > 0) {
                  this.meetingDetails.isError = true;
              }
          });
      }
  }*/

  displayVersionInfo() {
      this.dialogNoticeService.changelog();
  }

  handleError(error: Error) {
      console.error(`App Error:  ${error}`);
  }

  private dialogTests() {
    const longMessage = 'Alert Message.......it\'s really really long............................................................................';
    this.dialogNoticeService.alert({
        title: 'Alert Test',
        message: longMessage
    });
    this.dialogNoticeService.disconnectModerator({
        title: 'Disconnect Moderator Test',
        message: longMessage
    });
    this.dialogNoticeService.disconnected({
        title: 'Disconnected Test',
        message: longMessage
    });
    this.dialogNoticeService.error({
        title: 'Error Test',
        message: longMessage
    });
    this.dialogNoticeService.help({
        title: 'Help Test',
        htmlMessage: '',
        message: longMessage,
        combinedEventInfo: null
    });
    this.dialogNoticeService.networkDisconnect({
        title: 'Network Disconnect Test',
        message: longMessage,
    });
  }


}

