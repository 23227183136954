import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

// services
import { ProjectEventService } from '@app/services/project-event.service';
import { StakeholderService } from '@app/services/stakeholder.service';
import { QuestionsService } from '@app/services/questions.service';
import { UrlParamService } from '@app/services/url-param.service';
import { ProjectService } from '@app/services/project.service';
import { DialogNoticeService } from '@app/services/dialog-notice.service';
import { HttpCommonService } from './http-common.service';
import { AppSettingsService } from '@app/settings/app-settings.service';

// models
import { MeetingDetails, CombinedEventInfo } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class MasterInfoService {
  constructor(
    private dialogNoticeService: DialogNoticeService,
    private urlParamService: UrlParamService,
    private projectEventService: ProjectEventService,
    private projectService: ProjectService,
    private questionsService: QuestionsService,
    private stakeholderService: StakeholderService,
    private http: HttpClient,
    private httpCommonService: HttpCommonService,
    private appSettingsService: AppSettingsService
  ) { }

  private combinedServiceInfo: CombinedEventInfo = {
    meetingDetails: new MeetingDetails(),
    eventGuid: null
  };

 getProjectEventId(): Observable<number> {
  return new Observable<number>(sub => {
    const resourcesValid = this.resourcesValid(false);
    if (resourcesValid) {
      // Combine Results
      const serviceInfo = combineLatest([
        // Event Info
        this.projectEventService.getProjectEventAsObservable(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => {throw err; })),
      ]);
      serviceInfo.subscribe(v => {
        const [eventInfo] = v;
        sub.next(eventInfo.PE_ID);
        sub.complete();
      });
    } else {
      sub.error(this.combinedServiceInfo.error);
    }

  });
 }

  getAllProjectEventData(stakeRequired = false): Observable<CombinedEventInfo> {
    return new Observable<CombinedEventInfo>(sub => {
      // start processing requests
      const resourcesValid = this.resourcesValid(stakeRequired);
      if (resourcesValid) {
        // Combine Results
        const serviceInfo = combineLatest([
          // Event Info
          this.projectEventService.getProjectEventAsObservable(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => {throw err; })),
          // questions
          this.questionsService.getQuestions(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => {throw err; })),
          // Project Info
          this.projectService.getProjectAsObservable(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => { throw err; })),
          // Stakeholder Info
          this.stakeholderService.getStakeholderAsObservable(this.combinedServiceInfo.stakeGuid).pipe(catchError(err => {
            throw err;
          })),
          // app settings
          this.appSettingsService.getSettings().pipe(catchError(err => {throw err; })),
        ]);
        serviceInfo.subscribe(async(v) => {
          const [eventInfo, publicQuestions, projectView, stakeholderView, clientSettings] = v;
          // bind results
          // event details
          this.combinedServiceInfo.meetingDetails.eventDetails = eventInfo;
          // set resource Url
          if (eventInfo.ONLINE_RESOURCE) {
            this.combinedServiceInfo.resourceUrl = eventInfo.ONLINE_RESOURCE;
            this.combinedServiceInfo.isVideoFile = false;
          }
          if (eventInfo.VIDEO_PATH) {
            this.combinedServiceInfo.resourceUrl = eventInfo.VIDEO_PATH;
            this.combinedServiceInfo.isVideoFile = true;
          }
          /* - Disabled for now, CORS issue
          this.validateResourceUrl(this.combinedServiceInfo.resourceUrl).subscribe(res =>{
            if (!res){
              sub.error('Failed to load resource');
            }
          });*/
          // project
          this.combinedServiceInfo.projectView = projectView;
          // questions
          this.combinedServiceInfo.publicQuestions = publicQuestions;
          // stakeholder
          this.combinedServiceInfo.stakeholderView = stakeholderView;
          // client settings
          this.combinedServiceInfo.clientSettings = clientSettings;
          // get geometry using PIN..pretty specific to IOWA DOT
          if (projectView.PIN && clientSettings.projectGeometryEnabled) {
            const geom = await this.projectService.getGeometry({pin: projectView.PIN});
            if (geom) {
              this.combinedServiceInfo.meetingDetails.projectGeometry = geom;
            }
          }
          sub.next(this.combinedServiceInfo);
          sub.complete();
        });
      } else {
        sub.error(this.combinedServiceInfo.error);
      }
    });
  }

  // get project event data without stakeholder
  getProjectEventDataNoStake(): Observable<CombinedEventInfo> {
    return new Observable<CombinedEventInfo>(sub => {
      // start processing requests
      const resourcesValid = this.resourcesValid(false);
      if (resourcesValid) {
        // Combine Results
        const serviceInfo = combineLatest([
          // Event Info
          this.projectEventService.getProjectEventAsObservable(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => {throw err; })),
          // questions
          this.questionsService.getQuestions(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => {throw err; })),
          // Project Info
          this.projectService.getProjectAsObservable(this.combinedServiceInfo.publicMeetingInfo).pipe(catchError(err => { throw err; })),
          // app settings
          this.appSettingsService.getSettings().pipe(catchError(err => {throw err; }))
        ]);
        serviceInfo.subscribe(v => {
          const [eventInfo, publicQuestions, projectView, clientSettings] = v;
          // bind results
          // event details
          this.combinedServiceInfo.meetingDetails.eventDetails = eventInfo;
          // set resource Url
          if (eventInfo.ONLINE_RESOURCE) {
            this.combinedServiceInfo.resourceUrl = eventInfo.ONLINE_RESOURCE;
            this.combinedServiceInfo.isVideoFile = false;
          }
          if (eventInfo.VIDEO_PATH) {
            this.combinedServiceInfo.resourceUrl = eventInfo.VIDEO_PATH;
            this.combinedServiceInfo.isVideoFile = true;
          }
          // project
          this.combinedServiceInfo.projectView = projectView;
          // questions
          this.combinedServiceInfo.publicQuestions = publicQuestions;
          // client settings
          this.combinedServiceInfo.clientSettings = clientSettings;
          sub.next(this.combinedServiceInfo);
          sub.complete();
        });
      } else {
        sub.error(this.combinedServiceInfo.error);
      }
    });
  }

  validateResourceUrl(url): Observable<any>{
    return this.http.get<any>(url).pipe(
      map(r => r),
      tap(_ => console.log('Validated resource')),
      catchError(this.httpCommonService.handleError<any>
        ('MasterInfoService: validateResourceUrl',
        null,
        true,
        'Failed to load video/resource url')
      )
    );
  }

  // returns false if has an error
  resourcesValid(stakeRequired: boolean): boolean {
    // start processing requests
    const eventGuid = this.urlParamService.getEventGuid();
    const stakeGuid = this.urlParamService.getStakeGuid();
    // default to false
    this.combinedServiceInfo.hasError = false;
    // error checking
    if (eventGuid === null || eventGuid === '') {
      this.combinedServiceInfo.hasError = true;
      this.combinedServiceInfo.error = 'The presentation is invalid. The presentation information is not referenced in the link.';
    }
    // check for stakeGuid if required
    if ((stakeGuid === null || stakeGuid === '') && stakeRequired) {
      this.combinedServiceInfo.hasError = true;
      this.combinedServiceInfo.error = 'The presentation is invalid. The stakeholder information is not referenced in the link.';
    }
    if (!this.combinedServiceInfo.hasError) {
      this.combinedServiceInfo.stakeGuid = stakeGuid;
      this.combinedServiceInfo.eventGuid = eventGuid;
      this.combinedServiceInfo.publicMeetingInfo = { id: eventGuid};
      // if (eventGuid) {
      //   // uploaded video file
      //   this.combinedServiceInfo.publicMeetingInfo = {onlineMeetingGuid: guid };
      //   this.combinedServiceInfo.isVideoFile = true;
      // }  else if (resourceGuid) {
      //   // online resource ie link
      //   this.combinedServiceInfo.publicMeetingInfo = {onlineResourceGuid: resourceGuid };
      //   this.combinedServiceInfo.isVideoFile = false;
      // }
    }
    if (this.combinedServiceInfo.hasError) {
      this.dialogNoticeService.error(
        {
          title: 'LOADING ERROR',
          message: this.combinedServiceInfo.error
        });
    }
    // returns false for an error
    return !this.combinedServiceInfo.hasError;
  }

}
