import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DomSanitizerService {

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  getSafeUrl(url: string): SafeResourceUrl {
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    return safeUrl;
  }

  sanitizeInput(value: string){
    let dom = new DOMParser().parseFromString(value, 'text/html');
    const text = dom.body.textContent || dom.body.innerHTML || "";
    return text.trim();
  }

}
