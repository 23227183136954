import { ClientEntities } from "./entities";

export class AppSettings {
    constructor(){};
    readonly defaultSettings: ClientSettings = {
        anonymousPodium: false,
        slidoEnabled: false,
        projectGeometryEnabled: false,
        clientUiSettings: {
            podiumBtnText: 'Join the Podium',
            podiumDescText: `When enabled, click Join the Podium to submit a question or comment to the project team.  
            Your virtual podium comment will be entered into the official project record, may be read aloud or displayed on screen, 
            and will be responded to either live or post-event by the project team.`
        }
    };
    readonly clientSettings: ClientSettings[] = [
        // NTRC
        {
            anonymousPodium: true,
            clientEntity: ClientEntities.NTRC,
            projectGeometryEnabled: false,
            slidoEnabled: true,
            googleTagManager: { id: 'GTM-NZQ3B2S'},
            clientUiSettings: {
                podiumBtnText: 'Join the Conversation',
                podiumDescText: 'Time permitting, questions will be answered during our live Q&A.'
            }
        },
        // IOWA
        {
            anonymousPodium: false,
            clientEntity: ClientEntities.Iowa,
            projectGeometryEnabled: true,
            slidoEnabled: false,
            clientUiSettings: this.defaultSettings.clientUiSettings
        },
    ];
}

export interface GoogleTagManager {
    id: string;
}
export interface ClientUiSettings{
    podiumBtnText: string;
    podiumDescText: string;
}

export interface ClientSettings {
    anonymousPodium:boolean;
    clientEntity?: ClientEntities;
    clientUiSettings: ClientUiSettings;
    googleTagManager?: GoogleTagManager;
    projectGeometryEnabled: boolean;
    slidoEnabled: boolean;
}
