import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { CHANGELOG } from '../../../changelog';
import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeLogComponent extends BasicDialogComponent  {
  constructor(
    public dialogRef: MatDialogRef<ChangeLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
  ) {
    super(dialogRef, data);
  }
  changelog = CHANGELOG;



}
