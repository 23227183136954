import { Injectable } from '@angular/core';
import { DisconnectNetworkStatusDialogComponent } from '@app/dialogs/disconnect-network-status-dialog/disconnect-network-status-dialog.component';
import { TokenResponse } from '@app/models/token-response';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  constructor() { }
  private readonly tokenKey = 'token_key';
  private readonly identityKey = 'id_key';

  store(tokenData: TokenResponse) {
    localStorage.setItem(this.tokenKey, JSON.stringify(tokenData));
  }

  storeIdentity(userData: any) {
    localStorage.setItem(this.identityKey, JSON.stringify(userData));
  }

  savePassedToken(token: string) {
    var d = new Date();
    d.setDate(d.getDate() + 1);
    const x: TokenResponse = {
      access_token: token,
      expires_in: d.getTime(),
      id_token: '',
      scope: '',
      token_type: 'Bearer',
    };

    this.store(x);
  }

  isTokenValid(): boolean {
    const t = this.retrieveToken();
    if (t) {
      return true;
    } else {
      return false;
    }
  }

  retrieveToken(): TokenResponse {
    try {
        const storedToken = this.retrieve();
        return storedToken;
    } catch (err) {
      return null;
    }
  }

  retrieveIdentity(): any {
    try {
        const storedToken = JSON.parse(localStorage.getItem(this.identityKey));
        return storedToken;
    } catch (err) {
      return null;
    }
  }

  private retrieve(): TokenResponse {
    const storedToken: TokenResponse = JSON.parse(localStorage.getItem(this.tokenKey)) as TokenResponse;
    if (!storedToken) {
      throw new Error('no token found');
    }
    return storedToken;
  }

}
