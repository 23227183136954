import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DocumentsService } from '@app/services';
import { DocumentInfo } from '@app/models/documents';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

  constructor(
    private documentsService: DocumentsService
  ) { }

  @Input() projectId: number = null;
  @Input() peId: number = null;
  projectDocuments$ = new BehaviorSubject<DocumentInfo[]>(null);
  eventDocuments$ = new BehaviorSubject<DocumentInfo[]>(null);
  fetching$ = new BehaviorSubject<boolean>(false);

  private subs: Subscription[] = [];

  ngOnInit(): void {
    if (!this.projectId){
      console.error('Failed to get project id');
      return;
    }
    if (!this.peId){
      console.error('Failed to get event id (pe_id)');
      return;
    }
    // Combine Results
    this.fetching$.next(true);
    const docInfo = combineLatest([
      // Project Docs
      this.documentsService.getProjectDocuments(this.projectId).pipe(
        catchError(err => {
          this.fetching$.next(false);
          throw err; 
        })
      ),
      // Event Docs
      this.documentsService.getEventDocuments(this.peId).pipe(
        catchError(err => {
          this.fetching$.next(false);
          throw err; 
        })
      )
    ]);
    this.subs.push(docInfo.subscribe(v => {
        const [projectDocs, eventDocs] = v;
        const visibleProjectDocs = projectDocs.filter(vd => vd.IS_DEFAULT);
        const visibleEventDocs = eventDocs.filter(vd => vd.IS_DEFAULT);
        this.projectDocuments$.next(visibleProjectDocs);
        this.eventDocuments$.next(visibleEventDocs);
        this.fetching$.next(false);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  menuItemClick(doc: DocumentInfo){
    window.open(doc.S3_URL_Signed, '_blank');
  }

}
